@import 'color/colors';

.progressBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  h4 {
    margin: 4px;
  }
  .tracker {
    width: 300px;
    // width: 50%;
    display: flex;
    align-items: center;
    height: 20px;
    margin: 8px 8px 8px 0;
    background: lighten($cerulean--light-1, 30%);
    border-radius: 4px;
    transition: all smooth 0.5s;
    .progressInTracker {
      height: 100%;
      min-width: 35px;
      background: $cerulean;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .progressInnerTrackerPercentage {
      color: $white;
      // position: absolute;
      margin-left: -34px;
    }
  }
}
