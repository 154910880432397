@import 'color/colors';

.register {
  width: 524px;
  margin: 40px auto 0;
  background-color: $white;
  border-radius: 4px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $shadow__table;
  @media (max-width: 768px) {
    margin-top: 20px;
    width: 95vw;
  }
}

.register__logoWrapper {
  margin-bottom: 8px;
  text-align: center;
  text-align: center;
}
.register__logo {
  max-width: 320px;
  min-width: 220px;
  max-height: 120px;
  transition: 0.05s all linear;
  cursor: pointer;
  &--savvi {
    max-width: 280px;
  }
  &--fundLaunch {
    max-width: 350px;
  }
}

.register__form {
  max-width: 540px;
  margin: 0 32px 10px;

  .form__heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
    span {
      margin-top: 8px;
      @include text--h4;
      line-height: 12px;
      svg {
        color: $low;
        margin-right: 4px;
        display: inline-block;
      }
    }
  }

  .grid--flex {
    justify-content: space-between;
  }

  .styled-checkbox__row label {
    @include text--body1;
  }

  a:hover {
    text-decoration: underline;
    color: $link--light;
  }

  .styled-checkbox .inputSubLabel {
    margin-left: 24px;
  }
  @media (max-width: 768px) {
    margin: 10px auto;
  }
}

.register__actions {
  display: flex;
  justify-content: space-between;
  .oldbtn {
    margin: 10px 0;
  }
}

.register__header {
  text-align: center;
  font-weight: 400;
  color: #434c5f;
  margin-bottom: 40px;
}
