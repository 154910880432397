@import 'color/colors';

.resourceList {
  display: grid;
  grid-template-columns: repeat(5, auto);
  padding: 16px;
  margin-bottom: 4px;
  border: thin solid $border;
  border-radius: 10px;
  max-width: 650px;
  &__container {
    width: 100%;
    max-width: 920px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    .dataTable__wrapper {
      border: thin solid $border;
      border-radius: 4px;
      margin-bottom: 0;
      min-width: 720px;
      width: 100%;
      // background-color: $bg--page;
    }
    .dataTable {
      box-shadow: none;
    }
    .dataTable__empty {
      margin-left: 28px;
    }
    .dataTable__row {
      grid-template-columns:
        minmax(140px, 200px) minmax(10px, 1.5fr) minmax(140px, 1.5fr)
        minmax(190px, 2fr);
    }
    &--error {
      .address-group__label {
        color: $red;
      }
    }
    @media (min-width: 1280px) {
      overflow: visible;
    }
  }
  &__question {
    @include text--label;
    position: relative;
    margin: 4px 0 8px;
  }
}
