@import 'color/colors';

.whatsNew {
  display: flex;
  flex-direction: column;
  &__toggle {
    sup {
      color: $low;
      position: absolute;
      top: -8px;
      right: 0px;
      font-size: 10px;
    }
    &:disabled {
      border: none;
    }
  }

  &__exclamation {
    color: $mint;
    margin-right: 4px;
  }
  h1 {
    display: flex;
    justify-content: space-between;
    @include text--h1;
    padding: 8px 28px;
    margin: 0;
    svg {
      color: inherit;
      cursor: pointer;
      &:hover {
        color: $link--light;
      }
      &:active {
        color: $link--dark;
      }
      &:focus {
        color: $link--dark;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }
  &__item {
    display: flex;
    flex-direction: column;
    border-top: thin solid $border;
    padding: 8px;
    &.verify {
      flex-direction: row;
      svg {
        color: $med;
        font-size: 18px;
        margin: 6px 0 0 20px;
        margin-left: 20px;
      }
      .whatsNew__itemBody {
        margin-left: 8px;
      }
    }
    &.hide {
      border-top: none;
      padding: 0;
      height: 0;
      overflow: hidden;
    }
    &Body {
      margin: 4px 20px;
      h4 {
        display: flex;
        justify-content: space-between;
        @include text--h4;
        margin: 2px 0 4px;
      }
      h2 {
        @include text--h2;
        font-size: 14px;
        color: $dark-85;
        a {
          text-decoration: underline;
          cursor: pointer;
          margin: 2px;
        }
      }
      p {
        @include text--body2;
        margin: 4px 0;
        a {
          text-decoration: underline;
          cursor: pointer;
          color: $link;
          margin: 2px;
        }
      }
      .myBtn {
        margin: 8px 24px 8px 0;
        display: flex;
        text-decoration: none;
        max-width: 220px;
      }
    }
    &Image {
      margin-top: 4px;
      align-self: center;
      img {
        max-height: 460px;
        min-height: 240px;
        max-width: 540px;
      }
    }
  }
  &__emptyLabel {
    @include text--h2;
  }
  &__link {
    border: none;
    align-self: center;
    outline: none;
    background: none;
    color: $link;
    font-weight: 500;
    font-size: 14px;
    margin: 8px 0 16px;
    cursor: pointer;
    svg {
      margin-left: 4px;
      margin-top: 5px;
      font-weight: bold;
    }
    &:hover {
      color: $link--dark;
      text-decoration: underline;
    }
  }
}
