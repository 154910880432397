@use 'sass:math';

@import 'color/colors';

$color__back: #252224;
$color__back--high: lighten($color__back, 60%);
$color__text: $text--dark-1;
$color__text--high: lighten($color__text, 40%);

$checkbox__size: 20px;
$switch__size: $checkbox__size;

$checkbox__border__size: 2px;
$checkbox__unselected: $text--dark-1;
$checkbox__unselected--disabled: $color__back--high;

$checkbox__checkmark__size: $checkbox__size - (2 * $checkbox__border__size);
$checkbox__checkmark__size--indeterminate: 2px;
$checkbox__selected: $text--dark;
$checkbox__selected--disabled: $color__back--high;

$checkbox__radio__radius: math.div($checkbox__size, 2);

$checkbox__label__color: $color__text;
$checkbox__label__color--disabled: $color__text--high;

// CHECKBOXES

.styled-checkbox {
  display: flex;
  margin: 15px 0;
  flex-direction: column;

  &:hover {
    .styled-checkbox__input {
      border-color: $link--light;
      &::before {
        background-color: $link--light;
      }
    }
  }

  .savvi-tooltip {
    bottom: 12px;
  }

  &__row {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      margin-left: 4px;
    }
    label {
      cursor: pointer;
    }
    .inputSubLabel {
      margin-left: 32px;
    }
  }

  &__label {
    text-align: left;
    @include text--label;
    position: relative;
    margin: 5px 12px 5px 6px;
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    .styled-checkbox__label {
      color: $text--dark-disabled;
      cursor: not-allowed;
    }
    .styled-checkbox__row {
      cursor: not-allowed;
    }
    &:hover {
      .styled-checkbox__input {
        border-color: $checkbox__unselected--disabled;
        &::before {
          background-color: $checkbox__selected--disabled;
        }
      }
    }
  }

  &__input {
    position: relative;
    flex-shrink: 0;
    margin: 0 4px 0 0;
    width: $checkbox__size;
    height: $checkbox__size;
    appearance: none;
    outline: none; // TODO: manage :focus
    cursor: pointer;
    border: $checkbox__border__size solid $checkbox__unselected;
    border-radius: 4px;

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      transition: all 0.1s;
      border-radius: 2px;
      background-color: $checkbox__selected;
    }

    &:checked {
      // &:checked {
      .styled-checkbox__input {
        border-color: $checkbox__selected;
      }
      .styled-checkbox__label {
        color: $checkbox__selected;
      }
      &::before {
        top: $checkbox__border__size;
        right: $checkbox__border__size;
        bottom: $checkbox__border__size;
        left: $checkbox__border__size;
        background-color: $checkbox__selected;
      }
      &:hover {
        .styled-checkbox__input {
          border-color: $link--light;
        }
        &::before {
          background-color: $link--light;
        }
      }
      // &:indeterminate {
      //   &::before {
      //     top: math.div($checkbox__checkmark__size, 2) -
      //       math.div($checkbox__checkmark__size--indeterminate, 2);
      //     right: $checkbox__border__size;
      //     bottom: math.div($checkbox__checkmark__size, 2) -
      //       math.div($checkbox__checkmark__size--indeterminate, 2);
      //     left: $checkbox__border__size;
      //   }
      // }
    }

    &:disabled {
      border-color: $checkbox__unselected--disabled;
      cursor: not-allowed;

      &::before {
        background-color: $checkbox__selected--disabled;
      }
    }
  }

  &--has-error {
    &__input {
      border-color: red;
      background-color: rgba(red, 0.2);

      &::before {
        background-color: red;
      }
    }
  }

  &--checkbox {
    &:indeterminate {
      .styled-checkbox__input {
        color: $checkbox__selected;
      }
      &::before {
        top: math.div($checkbox__checkmark__size, 2) -
          math.div($checkbox__checkmark__size--indeterminate, 2);
        right: $checkbox__border__size;
        bottom: math.div($checkbox__checkmark__size, 2) -
          math.div($checkbox__checkmark__size--indeterminate, 2);
        left: $checkbox__border__size;
        background-color: $checkbox__selected;
      }
      &:hover {
        .styled-checkbox__input {
          color: $link--light;
        }
        &::before {
          background-color: $link--light;
        }
      }
    }
  }

  &--radio {
    border-radius: 50%;
    width: $checkbox__size;
    height: $checkbox__size;
    border: $checkbox__border__size solid $checkbox__unselected;

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      transition: all 0.1s;
      background: $checkbox__selected;
    }

    &:checked {
      border-color: $checkbox__selected;
      &::before {
        top: $checkbox__border__size;
        right: $checkbox__border__size;
        bottom: $checkbox__border__size;
        left: $checkbox__border__size;
        border-radius: 50%;
        background: $checkbox__selected;
      }
    }
    &:disabled {
      border-color: $checkbox__unselected--disabled;
      &::before {
        background: $checkbox__selected--disabled;
      }
    }
  }

  &--switch {
    width: (2 * $switch__size) - (2 * $checkbox__border__size);
    height: $switch__size;
    border: $checkbox__border__size solid $checkbox__unselected--disabled;
    border-radius: $checkbox__checkmark__size;

    &::before {
      top: $checkbox__border__size;
      right: $switch__size - $checkbox__border__size;
      bottom: $checkbox__border__size;
      left: $checkbox__border__size;
      border-radius: 50%;
      background: $checkbox__selected--disabled;
    }

    &:checked {
      border-color: $checkbox__unselected;

      &::before {
        right: $checkbox__border__size;
        left: $switch__size - $checkbox__border__size;
        background: $checkbox__unselected;
      }
    }
  }
}
