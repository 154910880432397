@import 'navigation/navigation';
@import 'color/colors';

.new-company {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
  h2 {
    font-size: 1.25em;
    margin-top: 0;
    align-self: flex-start;
  }
  &__subheader {
    @include text--h4;
    margin-bottom: 12px;
    svg {
      color: $mint;
      margin-right: 4px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  &__action {
    flex: 3;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  @media (max-width: 825px) {
    padding: 10px;
  }
}
