@import 'navigation/navigation';
@import 'color/colors';

.portalView {
  &__container {
    padding: 40px 80px 30px 80px;
    width: 100%;
    max-width: 1360px;
    @media (max-width: 1480px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  &__title {
    @include text--h1;
    margin: 0 0 20px;
    font-size: 28px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  &__main {
    display: flex;
    @media (max-width: 1239px) {
      flex-direction: column-reverse;
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    @media (max-width: 876px) {
      min-width: 680px;
    }
  }
  &__dropdown {
    .dropdown {
      width: 380px;
      padding-bottom: 20px;
      top: 20px;
      &.dropdown--upward {
        top: unset;
      }
      .myBtn--secondary {
        justify-content: flex-start;
        border: none;
        padding: 8px 8px;
        margin: 4px 12px;
        width: 356px;
        border-radius: 8px;
        transition: 0.25s;
        font-size: 14px;

        &:not(&:disabled):hover {
          background-color: $hover;
          color: $text--dark-2;
          text-decoration: none;
          &.isWarning {
            color: $warning--light;
          }
        }
        &:disabled:hover {
          background-color: inherit;
          color: $light-110;
        }
      }
    }
    &--empty {
      .myBtn--secondary {
        width: unset;
      }
    }
  }
  &__share {
    svg {
      margin-right: 4px;
    }
  }
  &__nav {
    display: flex;
    margin-bottom: 30px;
    height: 50px;
    align-items: flex-end;
    border-bottom: thin solid $border;
    margin-right: 20px;
    .myBtn {
      height: 50px;
    }
    .myBtn--primary {
      margin-right: 12px;
      min-width: 175px;
      .savvi-tooltip__wrapper {
        color: $white;
        margin-top: -8px;
      }
    }
    .myBtn--secondary {
      width: 175px;
      .savvi-tooltip__wrapper {
        margin-top: -8px;
      }
    }
    .savvi-tooltip__wrapper {
      margin-top: -8px;
      margin-left: 2px;
      &:hover {
        color: $low;
        svg {
          color: $low;
        }
      }
    }
    .dropdown {
      top: 56px;
    }
    &--small {
      margin-right: 0;
      border-bottom: none;
    }
    @media (max-width: 1240px) {
      margin-top: 20px;
    }
    @media (max-width: 1080px) {
      margin-bottom: 20px;
    }
  }
  &__nav-link {
    display: flex;
    align-items: center;
    padding: 0 0 8px;
    border-bottom: 4px solid transparent;
    margin-right: 40px;
    h4 {
      @include text--h4;
      color: $text--sub;
      font-size: 18px;
      font-weight: 500;
    }
    &--selected,
    &:hover {
      border-bottom: 4px solid $link;
      h4 {
        color: $link;
      }
    }
  }
  &__body {
    width: 820px;
    background-color: $white;
    border-radius: 16px;
    box-shadow: $shadow__table;
    margin-right: 20px;
    @media (max-width: 1280px) {
      width: 780px;
    }
    @media (max-width: 1239px) {
      width: 100%;
      // max-width:
    }
    .myBtn--secondary svg {
      margin-right: 8px;
    }
    .myBtn--primary svg {
      margin-right: 8px;
    }

    &--small {
      .portalInfoCard {
        &__header {
          height: 100px;
          h2 {
            font-size: 20px;
            margin: 0;
            .myBtn--icon {
              border: thin solid $text--dark-1;
              margin-left: 4px;
              margin-top: -8px;
              height: 18px;
              width: 18px;
              min-width: 18px;
              svg {
                color: $text--dark-1;
                font-size: 10px;
              }
              &:hover {
                border: thin solid $link;
                svg {
                  color: $link;
                }
              }
            }
          }
        }
        &__roomItems {
          gap: 0;
          &--single {
            margin-top: 12px;
          }
        }
      }
      @media (min-width: 1240px) {
        width: 360px;
        margin-right: 0;
        .portalInfoCard {
          padding: 12px 18px;
          &__header {
            height: unset;
            cursor: default;
            padding: 16px 10px 12px;
            &Chevron {
              display: none;
            }
            &:hover {
              background-color: $white;
            }
          }
          &__section {
            margin: 12px 0 16px;
          }
          &__sectionCol {
            margin-right: 10px;
            h3 span {
              margin-left: 4px;
            }
            h4 {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              word-break: break-all;
            }
          }
          &__documentWrapper {
            margin-bottom: 12px;
            margin-right: 10px;
          }
          &__roomActions {
            margin: 16px 10px;
            display: flex;
            .myBtn--secondary {
              flex: 1;
            }
          }
          &__roomItems {
            margin: 0;
            --single {
              margin-top: 12px;
            }
          }
          &__sectionHeader {
            margin: 12px 10px;
          }
          &__sectionRow {
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            padding: 8px 10px;
            margin-left: 0;
            &--flat {
              padding: 4px 10px;
            }
            &--documents {
              flex-direction: row;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
  &__empty {
    padding: 30px 60px;
    h2 {
      position: relative;
    }
    h3 {
      font-size: 14px;
      color: $text--dark-2;
      line-height: 22px;
      position: relative;
      strong {
        font-size: 18px;
        color: $text--dark;
      }
    }
  }
  &__empty-info {
    position: absolute;
    left: -30px;
    top: 2px;
    color: $low;
    margin-right: 4px;
  }
  &__header {
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 30px 0 40px;
    position: relative;
    border-radius: 16px;
    cursor: pointer;
    h2 {
      @include text--h1;
      font-weight: 700;
    }
    &--open {
      svg {
        transform: rotate(90deg);
      }
    }
    &:hover {
      background-color: $hover;
    }
  }
}

.portalInfoCard {
  width: 100%;
  padding: 12px 20px;
  &__border {
    height: 1px;
    background-color: $border;
    margin: 0 30px;
  }
  &__header {
    padding: 0 40px 0 50px;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    border-radius: 16px;
    h2 {
      @include text--h1;
      margin: 0;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
    h4 {
      @include text--h4;
      margin-top: 4px;
    }
    .myBtn--link {
      color: $text--dark;
      svg {
        color: $warning--light;
        margin-left: 4px;
        margin-top: 2px;
      }
      &:hover {
        color: $warning--light;
        svg {
          color: $warning--light;
        }
      }
    }
    &--open {
      .portalInfoCard__headerChevron {
        transform: rotate(90deg);
      }
    }
    &:hover {
      background-color: $hover;
    }
    &--flat:hover {
      cursor: default;
      height: 80px;
      background-color: inherit;
    }
  }
  &__headerInfo {
    margin-top: -18px;
  }
  &__headerActions {
    display: flex;
    align-items: center;
    .myBtn--primary {
      margin-left: 12px;
    }
  }
  &__headerChevron {
    position: absolute;
    left: 14px;
    font-size: 24px;
    transition: all 0.2s ease-in-out;
  }
  &__headerAlert {
    font-size: 12px;
    font-weight: 700;
    color: $white;
    width: 20px;
    height: 20px;
    border-radius: 32px;
    margin-left: 8px;
    background-color: $low;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__section {
    margin: 12px 10px 16px;
  }
  &__sectionHeader {
    h4 {
      @include text--h4;
      flex: -1;
      margin-right: 4px;
    }
    display: flex;
    align-items: center;
    margin: 12px 30px 12px 40px;
  }
  &__divider {
    height: 1px;
    background-color: $border;
    margin-left: 4px;
    flex: 1;
  }
  &__sectionRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 30px 12px 30px;
    margin: 4px 0 4px 10px;
    border-radius: 16px;
    cursor: pointer;
    &:hover {
      background-color: $hover;
    }
    &--flat {
      // padding: 0;
      // margin: 30px 30px;
      &:hover {
        background-color: inherit;
        cursor: default;
      }
    }
    &--documents {
      justify-content: flex-start;
      overflow-x: auto;
      gap: 18px;
      align-items: flex-start;
      /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
      &::-webkit-scrollbar-track {
        // visibility: hidden;
        opacity: 0;
        box-shadow: none;
      }
      &::-webkit-scrollbar-thumb {
        // visibility: hidden;
        opacity: 0;
        box-shadow: none;
      }
      &:hover {
        &::-webkit-scrollbar-track {
          opacity: 0.3;
          box-shadow: inset 0 0 6px #a4a4a4;
        }
        &::-webkit-scrollbar-thumb {
          opacity: 0.4;
          box-shadow: inset 0 0 6px #a4a4a4;
        }
      }
      /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
    }
  }
  &__changeTemplateAction {
    color: $link;
    cursor: pointer;
    svg {
      margin-left: 4px;
      width: 10px;
    }
    &:hover {
      color: $link--light;
    }
  }
  &__taskTemplatesWrapper {
    padding: 0 30px 0 40px;
  }
  &__taskTemplates {
    display: flex;
    flex-wrap: wrap;
    border-top: thin solid $border;
    border-bottom: thin solid $border;
    padding-top: 12px;
    &Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 12px;
      h3 {
        @include text--h2;
      }
    }
    &Actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .myBtn {
        margin: 0 8px;
      }
    }
  }
  &__taskTemplate {
    flex: 50%;
    margin-bottom: 8px;
    padding-bottom: 8px;
    .styled-checkbox {
      margin-bottom: 0;
    }
    .styled-checkbox__label {
      @include text--h3;
      padding-left: 8px;
    }
    .styled-checkbox__input {
      margin-top: 0;
    }
    .PDFViewer__wrapper {
      margin-left: 32px;
      text-align: left;
      .PDFViewer {
        margin-left: 0px;
      }
      .PDFViewer__action {
        margin-left: 0;
        width: unset;
        text-align: left;
      }
    }
    &Header {
      @include text--h4;
      margin-left: 32px;
      line-height: 18px;
      margin-bottom: 8px;
      p {
        @include text--body2;
        line-height: 18px;
      }
    }
  }
  &__status {
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    color: $white;
    font-size: 12px;
    &--none {
      background-color: $bg--status;
    }
    &--low {
      background-color: $low;
    }
  }
  &__roomItems {
    display: flex;
    gap: 20px;
    @media (max-width: 876px) {
      gap: 12px;
    }
  }
  &__roomItems--single {
    // justify-content: flex-end;
    // width: 100%;
    margin-top: 12px;
    .myBtn--link {
      padding-left: 0;
      svg {
        margin-right: 8px;
        justify-self: flex-end;
      }
    }
  }
  &__lock {
    margin-right: 4px;
    font-size: 14px;
  }
  &__chevron {
    margin-left: 4px;
    font-size: 12px;
  }
  &__edit {
    margin-left: 4px;
  }
  &__sectionCol {
    margin-right: 8px;
    h3 {
      @include text--h3;
      font-size: 14px;
      font-weight: 700;
    }
    h4 {
      @include text--h4;
      margin-bottom: 4px;
      overflow-wrap: anywhere;
    }
    p {
      @include text--body2;
    }
  }
  &__documentWrapper {
    width: 188px;
    position: relative;
    h3 {
      @include text--h3;
      font-size: 14px;
      font-weight: 700;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;
    }
    h4 {
      @include text--h4;
      margin-bottom: 4px;
      margin-top: 4px;
      display: -webkit-box;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      svg {
        margin-left: 4px;
        margin-top: 2px;
      }
    }

    &--action {
      cursor: pointer;

      &:hover {
        .portalInfoCard__documentPreview {
          border: thin solid $link;
        }
      }
    }
  }
  &__download {
    position: absolute;
    right: -8px;
    margin-top: -8px;
    &:hover {
      color: $link;
    }
  }
  &__documentPreview {
    width: 188px;
    height: 124px;
    background-color: $doc--bg;
    border: thin solid $text--light;
    border-radius: 4px;
    margin-bottom: 12px;
    text-align: center;
    overflow: hidden;
    .bp-container {
      min-width: 186px;
      max-height: 123px;
    }
    .bp .bp-doc.bp-doc-document {
      overflow: hidden;
      overflow-y: hidden;
    }
    .bcpr-navigate-right {
      opacity: 1;
    }
    .bcpr-navigate-left {
      opacity: 1;
    }
    .bp-ControlsRoot {
      display: none;
    }
    .bp-error .bp-icon {
      width: 80px;
      height: 80px;
    }
    .bp-error .bp-error-text {
      padding: 0 12px;
      font-size: 12px;
      line-height: 12px;
    }
    .bp-error .bp-btn-primary {
      display: none;
    }
    .DocumentPreview__thumbnail {
      width: 184px;
      height: 124px;
      padding: 12px 20px;
    }
    .react-pdf__message--error {
      font-size: 12px;
      padding: 12px;
    }
    .ReactPDF__wrapper {
      align-items: flex-start;
      background-color: #f5f5f5;
      margin-top: 16px;
    }
    .react-pdf__Page {
      box-shadow: $shadow__table;
    }
    &--readOnly {
      .bp-error [data-testid='preview-error-download-btn'] {
        display: none;
      }
    }
  }
  &__previewSVG {
    margin-top: 12px;
    height: 100px;
    width: 100px;
  }
  &__docLabel {
    position: relative;
    .styled-text-field__container {
      min-width: 100%;
      margin: 0;
      height: 40px;
    }
  }
  &__docEditActions {
    position: absolute;
    right: 0;
    top: -10px;
    backdrop-filter: blur(1px);
    background-color: transparent;
    display: flex;
    .myBtn--icon {
      background-color: $white;
      color: $new-link;
      opacity: 0.8;
    }
  }
  &__docSublabel--rightPadding {
    padding-right: 26px;
  }
  &__docStatus {
    svg {
      color: $low;
      margin-right: 4px;
    }
  }
  &__listActions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
    &--open {
      display: flex;
      .myBtn--icon {
        border-color: $border--light;
        &:disabled {
          border-color: $light-110;
          cursor: not-allowed;
          pointer-events: unset;
        }
        &:hover {
          border-color: $link--light;
          color: $link--light;
          &:disabled {
            border-color: $light-110;
            color: $light-110;
          }
          &.isWarning {
            color: $warning--light;
            border-color: $warning--light;
          }
        }
      }
    }
    &--float {
      position: absolute;
      right: 40px;
      margin-top: 44px;
    }
    .myBtn {
      margin: 0 0 0 12px;
      opacity: 1;
      background-color: $white;
    }
  }
  &__documentsPreviewer {
    color: purple;
  }
  &__dropdownHeader {
    display: flex;
    align-items: center;
    margin: 20px 20px 12px;
    h4 {
      @include text--h4;
      border: none;
      padding: 0 4px 0 0;
    }
  }
  &__roomAccess {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 0;
    padding-top: 0;
    text-decoration: none;
    width: fit-content;
    h3 {
      @include text--h3;
      font-size: 14px;
      font-weight: 700;
    }
    svg {
      color: $text--dark;
    }
    &--flat {
      cursor: default;
    }
    &:not(&--flat):hover {
      h3 {
        color: $link;
      }
      svg {
        color: $link;
      }
    }
    &:disabled {
      cursor: default;
      h3 {
        color: $text--dark-disabled;
      }
      svg {
        color: $text--dark-disabled;
      }
      &:hover {
        h3,
        svg {
          color: $text--dark-disabled;
        }
      }
    }
  }
  &__roomActions {
    display: flex;
    align-items: center;
    .myBtn--icon {
      margin-right: 4px;
    }
    .myBtn--link {
      text-decoration: none;
      svg {
        margin-left: 4px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__linkIcon {
    margin-right: 4px;
    margin-top: 4px;
    transform: rotate(-45deg);
  }
  &__modal {
    position: relative;
    min-height: 400px;
    outline: none;
    border: none;
    box-shadow: 3px 3px 3px 3px #33373859;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    background-color: $bg--page;
    overflow: visible;
    margin: 20px auto;
    width: 604px;
    height: 800px;
    max-width: 95vw;
    max-height: 95vh;
    padding: 24px 30px 24px 40px;
    border-radius: 16px;
    &Overlay {
      z-index: 9;
      background-color: rgba(0, 0, 0, 0.66);
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      display: flex;
      overflow-y: auto;
      -webkit-overflow-scrolling-: 'touch';
    }
    &Header {
      @include plans__h1;
      color: $text--dark;
    }
    &Subheader {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    &Exit {
      position: absolute;
      align-self: flex-end;
      right: 30px;
      top: 24px;
      font-size: 1.25em;
      color: $primary;
      cursor: pointer;
      &:hover {
        color: $primary--light;
      }
      &:active {
        color: $primary--dark;
      }
      &:focus {
        color: $primary--dark;
      }
    }
    .DocumentPreview__thumbnail {
      width: 100%;
      height: fit-content;
      max-height: 666px;
    }
    &Preview {
      width: 100%;
      max-height: 666px;
      background-color: $doc--bg;
      border: thin solid $text--light;
      border-radius: 4px;
      margin-bottom: 12px;
      overflow: hidden;
      height: 100%;
      .bp-container {
        min-width: 300px;
        max-height: 666px;
      }
      .bcpr-navigate-right {
        opacity: 1;
      }
      .bcpr-navigate-left {
        opacity: 1;
      }
      // .bp-ControlsRoot {
      //   display: none;
      // }
      .react-pdf__message--error {
        font-size: 20px;
        padding: 20px;
      }
      .ReactPDF__wrapper {
        align-items: center;
      }
      &--readOnly {
        .bp-error [data-testid='preview-error-download-btn'] {
          display: none;
        }
      }
    }
    form {
      position: relative;
      height: 100%;
      padding-bottom: 50px;
      margin-top: 20px;
    }
  }
  &__templates {
    display: flex;
    flex-wrap: wrap;
    &Header {
      display: flex;
      align-items: center;
      padding-bottom: 12px;
      h3 {
        @include text--h2;
      }
    }
    &Actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      width: 100%;
      bottom: 0;
      .myBtn {
        margin: 0 8px;
      }
    }
  }
  &__template {
    flex: 50%;
    margin-bottom: 8px;
    padding-bottom: 8px;
    .styled-checkbox {
      margin-bottom: 0;
    }
    .styled-checkbox__label {
      @include text--h3;
      padding-left: 8px;
    }
    .styled-checkbox__input {
      margin-top: 0;
    }
    .PDFViewer__wrapper {
      margin-left: 32px;
      text-align: left;
      .PDFViewer {
        margin-left: 0px;
      }
      .PDFViewer__action {
        margin-left: 0;
        width: unset;
      }
    }
    .PDFViewer__action {
      text-align: left;
    }
    &Header {
      @include text--h4;
      margin-left: 32px;
      line-height: 18px;
      margin-bottom: 8px;
      p {
        @include text--body2;
        line-height: 18px;
      }
    }
  }
}
