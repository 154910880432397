@import 'color/colors';
@import 'typography/fonts';

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  // @include size--base-3;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  font-weight: bold;
}
.control__label {
  @include text--label;
}

input[type='search'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input[type='radio'],
input[type='checkbox'] {
  line-height: normal;
}

.file-explorer__container input[type='file'] {
  display: none;
}

input[type='range'] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: none;
  outline-offset: -2px;
}

output {
  // @include size--base-1;
  color: #555;
  display: block;
  line-height: 1.42857143;
  padding-top: 7px;
}

// Stripe Credit Card Input, and Button
.StripeElement {
  background: $white;
  border: thin solid $light;
  border-radius: 2px;
  // box-shadow: rgba(50, 50, 93, .14902) 0 1px 3px,
  //   rgba(0, 0, 0, .0196078) 0 1px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: block;
  font-size: 17px;
  margin: 10px 0 20px;
  max-width: 540px;
  padding: 10px 14px;
}

.form__group {
  margin: 4px 0;
  position: relative;
  flex: 1;
}

.form__group label {
  // @include size--base-1;
  margin: 4px 0;
  color: $type--dark;
  display: block;
  font-weight: 600;
  text-align: left;
}

.form__controls {
  bottom: 0;
  max-width: 1280px;
  position: absolute;
  width: 100%;
}

.my-form__control {
  border: thin solid $border;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 38px;
  padding: 0 16px;
  width: 100%;
  line-height: 20px;
  color: $text--dark-2;

  background-color: $white;
  background-image: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: $border ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.my-form__control:focus {
  outline: none;
  border-color: $border--active;
  box-shadow: 0 0 0 1px $border--active;
}

// .my-form__control:not([value='']) {
//   border-color: $dark-50;
// }

.my-form__control::-moz-placeholder {
  color: $dark-50;
  opacity: 1;
}

.my-form__control:-ms-input-placeholder {
  color: $dark-50;
}

.my-form__control::-webkit-input-placeholder {
  color: $dark-50;
}

.my-form__control::-ms--expand {
  background-color: transparent;
  border: 0;
}

.my-form__control[disabled],
.my-form__control[readonly],
fieldset[disabled] .my-form__control {
  background-color: #eee;
  opacity: 1;
}

// .form__control[disabled],
// fieldset[disabled] .form__control {
//   cursor: not-allowed;
// }

textarea {
  @include font--primary-regular;
  height: auto;
  resize: vertical;
  padding: 8px;
}

// .textarea {
//   font-family: 'SF Pro Display', -apple-system, 'BlinkMacSystemFont', 'Open Sans',
//     'Helvetica Neue', sans-serif;
//   font: initial;
//   font-style: normal;
//   font-weight: normal;
// }

input[type='search'] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='date'].form__control,
  input[type='time'].form__control,
  input[type='datetime-local'].form__control,
  input[type='month'].form__control {
    line-height: 34px;
  }
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.radio input[type='radio'],
.radio--inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox--inline input[type='checkbox'] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}

.radio--inline,
.checkbox--inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}

.radio--inline + .radio--inline,
.checkbox--inline + .checkbox--inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='checkbox'].disabled,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
  cursor: not-allowed;
}

.radio--inline.disabled,
.checkbox--inline.disabled,
fieldset[disabled] .radio--inline,
fieldset[disabled] .checkbox--inline {
  cursor: not-allowed;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.form__control--static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}

.form__group--has-icon {
  position: relative;
}

.form__group--has-icon .form__control {
  padding-right: 42.5px;
}

.form__group--has-icon-left .form__control {
  padding-left: 52px;
  padding-right: 16px;
}

.form-control__icon {
  position: absolute;
  top: 1px;
  right: 1px;
  display: block;
  width: 42px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
  border-radius: 0 2px 2px 0;
}

.form__group--has-icon-left .form-control__icon {
  right: auto;
  left: 1px;
  border-radius: 2px 0 0 2px;
}

.form__group--has-error .help-block,
.form__group--has-error .radio,
.form__group--has-error .checkbox,
.form__group--has-error .radio--inline,
.form__group--has-error .checkbox--inline {
  color: $red;
}

.form__group--has-error .form__control {
  // border-color: $red;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form__group--has-error .form__control:focus {
  // border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.form__group--has-error .input__group--addon {
  color: $red;
  background-color: #f2dede;
  // border-color: $red;
}

.form__group--has-error .form-control__icon {
  color: $red;
}

.help--block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: $type--medium;
}

@media (min-width: 768px) {
  .form--inline .form__group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form--inline .form__control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form--inline .form__control--static {
    display: inline-block;
  }
  .form--inline .input__group {
    display: inline-table;
    vertical-align: middle;
  }
  .form--inline .input__group .input__group-addon,
  .form--inline .input__group .input__group-btn,
  .form--inline .input__group .form__control {
    width: auto;
  }
  .form--inline .input__group > .form__control {
    width: 100%;
  }
  .form--inline .control__label {
    margin-bottom: 0;
    vertical-align: middle;
    color: $text--dark;
    font-size: 14px;
    span {
      color: $red;
    }
  }
  .form--inline .radio,
  .form--inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form--inline .radio label,
  .form--inline .checkbox label {
    padding-left: 0;
  }
  .form--inline .radio input[type='radio'],
  .form--inline .checkbox input[type='checkbox'] {
    position: relative;
    margin-left: 0;
  }
  .form--inline .form__group--has-icon .form-control__icon {
    top: 0;
  }
}

.form--horizontal .radio,
.form--horizontal .checkbox,
.form--horizontal .radio--inline,
.form--horizontal .checkbox--inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}

.form--horizontal .radio,
.form--horizontal .checkbox {
  min-height: 27px;
}

.form--horizontal .form__group {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  .form--horizontal .control__label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}

.form--horizontal .form__group--has-icon .form-control__icon {
  right: 15px;
}

.text-field__wrapper,
.checkbox__group {
  text-align: left;
}

.checkbox__group {
  padding-left: 25px;
}

.checkbox__group .control__label {
  // @include size--base-1;
  color: $text--dark;
}

.control__input-group {
  position: relative;
}

.form-control__icon {
  // @include size--base-0;
  color: rgba(150, 150, 150, 0.5);
  background-color: $light-90;
  transition: all 0.1s linear;
}

.form__control:focus + .form-control__icon {
  color: rgba(34, 170, 255, 0.5);
}

.form__group--has-error > .form__control,
.form__group--has-error > .control__input-group {
  // border-color: $red;
  margin: 8px 0;
}

.form__group--has-error .form__control + .form-control__icon {
  background-color: rgba(136, 0, 51, 0.1);
  color: rgba(232, 53, 53, 0.4) !important;
}

.help-block {
  margin: 8px 0;
  // @include size--base-1;
}

.loading__notifier {
  text-align: center;
  font-size: 42px;
  color: $primary;
}

.form__heading {
  // @include size--base-4;
  @include text--h1;
  // text-align: center;
  // color: $text--dark;
  // font-size: 18px;
  // margin-bottom: 5px;
  // margin-top: 4px;
}

.form__subheading {
  margin-top: 0px;
  text-align: center;
  margin-bottom: 25px;
}

.form__sub-heading {
  display: block;
  // @include size--base-2;
  @include text--h2;
  margin: 0;
}

.form__heading--secondary {
  // @include size--base-2;
  text-align: center;
  margin-top: 20px;
  color: #777;
}

.form__error {
  background-color: $red--light-3;
  color: $red--dark;
  padding: 9px 20px;
  max-width: 80%;
  margin: 24px auto;
}

.form--success {
  background-color: $mint;
  color: $primary;
  padding: 9px 20px;
  max-width: 80%;
  margin: 24px auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form__message--success {
  background: $mint;
  padding: 9px 20px;
  margin: 36px auto;
  max-width: 95%;
  color: $type--white;
  border-radius: 2px;
}

.form__message--info {
  background: $mint;
  padding: 9px 20px;
  margin: 36px auto;
  max-width: 95%;
  color: $type--white;
  border-radius: 2px;
}

.form__button-wrapper {
  display: flex;
  justify-content: space-between;
}
