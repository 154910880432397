@import 'navigation/navigation';
@import 'color/colors';
@import 'color/color-variables';

.TimelineSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;

  &__section-header {
    width: fit-content;
    margin-bottom: 4px;
    h3 {
      color: $dark-80;
      border-bottom: thin solid $dark-50;
      font-weight: 400;
      margin: 0 0 10px;
      width: fit-content;
      padding: 0 15px 3px 0;
      align-self: center;
    }
    h4 {
      @include text--h4;
      margin-bottom: 8px;
    }
  }
  &__fetching {
    align-self: center;
  }
  &__card {
    max-width: 550px;
    display: flex;
  }
  &__timeline {
    &-header {
      color: $dark-85;
      font-size: 1.1em;
      margin-bottom: 10px;
      margin-top: 10px;
      font-weight: 600;
    }
    &-empty {
      margin-left: 5px;
      color: $dark-75;
      margin-top: 5px;
      margin-bottom: 8px;
    }
  }
  &__pre {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $text--dark;
    margin-right: 10px;
    width: 30px;
  }
  &__icon {
    color: $text--dark;
    width: 24px;
    height: 24px;
    border-radius: 15px;
    border: thin solid $text--dark;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 12px;
    }
  }
  &__line {
    flex: 1;
    background-color: $dark-50;
    width: 1px;
    min-height: 20px;
    height: 100%;
    margin-right: 2px;
    &--isLast {
      display: none;
    }
  }
  &__body {
    padding-top: 4px;
    padding-bottom: 4px;
    h4 {
      @include text--body1;
      margin: 0 0 5px;
      p {
        @include text--h4;
        margin: 2px 0 0 2px;
      }
    }
    p {
      @include text--h4;
      svg {
        margin-right: 3px;
      }
    }
    a {
      @include text--h4;
      color: $new-primary;
      text-decoration: underline;
      &:hover {
        color: $new-primary--light;
      }
    }
  }

  .workbench-timeline__divider {
    width: 2px;
    background-color: $border;
    margin: 2px 4px;
  }

  &__toggle {
    @include text--h4;
    margin-top: 12px;
    margin-left: 8px;
    text-decoration: underline;
    cursor: pointer;
    svg {
      padding-left: 4px;
      font-size: 1.25em;
      vertical-align: middle;
    }
    &:hover {
      color: $new-primary--light;
    }
  }

  &__fade {
    width: 550px;
    height: 80px;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    position: absolute;
    bottom: 0;
  }

  &__content {
    &--inner {
      position: relative;
      width: 100%;
      overflow: hidden;
      max-height: 176px;
      transition: all 0.3s;
    }
  }
}
