@import 'color/colors';

.formCustomDoc {
  &__headerWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    h4 {
      @include text--h4;
    }
    h3 {
      @include text--h2;
      color: $text--dark-2;
    }
    .text-field__wrapper {
      margin: 0 0 0 8px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    min-height: 24px;
    .myBtn--link svg {
      margin-right: 2px;
      margin-top: 1px;
    }
    .myBtn--secondary {
      margin-left: auto;
    }
  }
  &__sectionWrapper {
    margin-bottom: 16px;
  }
  &__section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .styled-text-field__container {
      margin: 4px 0;
      min-width: 300px;
      @media (max-width: 768px) {
        min-width: unset;
      }
    }
  }
  &__sectionItem {
    h4 {
      @include text--h4;
      small {
        display: block;
      }
    }
    p {
      @include text--body1;
    }
    .warning {
      color: $orange;
    }
    .error {
      color: $red;
    }
  }
  &__text {
    @include text--body1;
    margin: 8px 0;
    a {
      color: $blue;
      text-decoration: underline;
    }
    small {
      display: block;
    }
  }
  &__subheader {
    @include text--h2;
    color: $text--dark-1;
    font-weight: 400;
    margin: 8px 0;
    p {
      color: $dark-75;
      font-size: 0.9em;
      display: inline;
    }
  }
  &__preview {
    height: 250px;
    border: thin solid $border;
  }
  &__btns {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  &__btn {
  }
  &__error {
    color: $red;
    margin-right: 4px;
  }
  &__warning {
    color: $red;
    margin-right: 4px;
  }
  &__warning {
    color: $orange;
    margin-right: 4px;
  }
  &__highlight {
    color: $orange;
  }
  &__picker {
    height: 500px;
  }
  &__link {
    background: $primary;
    outline: none;
    font-size: 1em;
    color: $white;
    border: thin solid $primary;
    border-radius: 4px;
    cursor: pointer;
    margin: 8px 0;
    padding: 8px 12px;
    box-shadow: -2px 4px 4px 0px #ccc;
    transition: all 0.2s;
    &:hover {
      background: $primary--light;
    }
    &:active {
      box-shadow: none;
    }
  }
  &__action {
    background: transparent;
    outline: none;
    font-size: 1em;
    color: $orange;
    border: none;
    cursor: pointer;
    margin: 8px 0;
    display: flex;
    padding: 4px;
    text-decoration: underline;
    &:hover {
      color: $orange--light;
    }
  }
}
