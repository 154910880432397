@import 'color/colors';

.finalizeReviewModal {
  &__overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling-: 'touch';
  }
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // height: 100%;
  // margin: 0;
  // padding: 20px;
  // flex: 1;
  // text-align: center;
  position: relative;
  min-height: 260px;
  border-radius: 8px;
  height: fit-content;
  max-width: 90%;
  width: 540px;
  margin: 40px auto 40px;
  padding: 20px 40px 20px;
  outline: none;
  border: none;
  box-shadow: 3px 3px 3px 3px #33373859;
  display: flex;
  flex-direction: column;
  background-color: $white;
  p {
    @include text--body1;
    margin: 4px 0;
    text-align: left;
    svg {
      color: $med;
      margin-right: 4px;
    }
  }
  h2 {
    @include text--h1;
    margin-top: 0;
    span {
      color: $mint;
    }
  }
  h6 {
    margin: 5px 0 0;
    font-size: 0.75em;
  }
  @media (max-width: 768px) {
    max-width: 95%;
    padding: 20px 28px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    max-height: 450px;
    overflow-y: auto;
    border-bottom: thin solid $border;
    margin-bottom: 8px;
    &.visible {
      overflow-y: visible;
      max-height: none;
      border-bottom: none;
    }
    h4 {
      @include text--body1;
      text-align: left;
      span {
        font-weight: bolder;
        color: $dark;
        h4 {
          @include text--h4;
        }
        li {
          @include text--body2;
        }
      }
      svg {
        color: $mint--light;
        margin-right: 2px;
      }
    }
    @media (max-height: 1080px) {
      max-height: 400px;
    }
    @media (max-height: 860px) {
      max-height: 320px;
    }
    @media (max-height: 768px) {
      max-height: 280px;
    }
  }
  &__header {
    text-align: start;
    margin: 0 8px 8px 0;
    h4 {
      @include text--h3;
    }
    p {
      @include text--body1;
      margin: 5px 5px 5px;
      color: $dark-50;
    }
  }
  &__row {
    h4 {
      @include text--h4;
      margin: 5px;
      margin-left: 0;
      p {
        display: inline;
        @include text--body2;
      }
    }
    p {
      @include text--body2;
      a {
        color: $text--dark-1;
        text-decoration: underline;
        margin-left: 4px;
        &:hover {
          color: $new-primary--light;
        }
      }
    }
  }
  &__help {
    h4 {
      margin-top: 8px;
      margin-bottom: 8px;
      color: $dark-50;
    }
    h6 {
      margin: 0;
      margin-top: -5px;
      color: $dark-60;
    }
    sup {
      svg {
        color: $link;
        margin-left: 2px;
        &:hover {
          color: $link--light;
          cursor: pointer;
        }
      }
    }
    .dd-menu-items {
      margin: 0 0 0 10px;
      z-index: 100;
      .dd-items-center {
        min-width: 240px;
        text-align: left;
        padding: 5px 5px 5px 10px;
        li {
          margin: 5px 0;
          font-size: 15px;
          font-weight: 500;
          color: $dark-60;
          display: flex;
        }
        ul {
          box-shadow: none;
          border: none;
        }
        p a {
          margin: 0;
          padding: 0;
          min-width: 0;
          color: $link;
          &:hover {
            color: $link--dark;
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__exit {
    position: absolute;
    align-self: flex-end;
    margin-top: -3px;
    font-size: 1.25em;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &__redlining {
    // color: $dark-80;
    text-align: left;
    margin: 4px 0;
    p {
      font-size: 12px;
      line-height: 18px;
    }
    h4 {
      @include text--h3;
      margin: 0 0 4px;
      color: $dark-80;
    }
    .styled-checkbox {
      margin-bottom: 4px;
      margin-top: 8px;
      .styled-checkbox__label {
        padding: 2px 0 2px 12px;
        color: $dark-75;
        font-size: 12px;
      }
    }
    h6 {
      margin: 0;
    }
  }

  &__upgrade {
    margin: 0 0 4px;
    text-align: left;
    svg {
      color: $mint;
      margin-right: 4px;
    }
    a {
      color: $link;
      text-decoration: underline;
      &:hover {
        color: $link--light;
        text-decoration: underline;
      }
    }
  }

  &__inputRow {
    display: flex;
    justify-content: space-between;
    & > div:first-child {
      margin-right: 16px;
      flex: 1.1;
      @media (max-width: 876px) {
        margin-right: 4px;
      }
    }
    & > div:nth-child(2) {
      flex: 1;
    }
  }
  &__payment {
    display: flex;
    flex: 1.75;
    flex-direction: column;
    margin-right: 40px;
    .myBtn--link {
      align-self: flex-start;
      text-decoration: none;
      font-size: inherit;
      font-weight: inherit;
      padding-left: 0px;
      svg {
        margin-right: 8px;
      }
    }
    @media (max-width: 876px) {
      margin-right: 0;
    }
  }
  &__stripeContainer.StripeElement {
    padding-top: 12px;
    border-radius: 12px;
    height: 50px;
    border: thin solid $text--light;
  }
  &__stripeError {
    padding: 8px 20px;
    border-radius: 8px;
    background-color: $red--light;
    h4 {
      color: white;
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin: 16px 15px 15px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    flex-direction: row;
    @media (max-width: 768px) {
      margin: 5px;
    }
    @media (max-width: 568px) {
      .myBtn {
        width: 148px;
        min-width: 148px;
        height: 44px;
      }
    }
  }
  &__asterik {
    color: $warning;
  }
}
