@import 'color/colors';

.plansModal {
  &__overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling-: 'touch';
  }
  h1 {
    @include plans__h1;
  }
  h2 {
    @include plans__h2;
    margin-bottom: 4px;
  }
  h3 {
    @include plans__h3;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .savvi-tooltip__wrapper {
      svg {
        position: relative;
        top: -2px;
        color: $low;
      }
      &:hover svg {
        color: $low--light;
      }
    }
    .myBtn--link {
      margin-top: 0;
      flex: 1;
      justify-content: flex-end;
    }
  }
  p {
    @include plans__p1;
    margin-bottom: 4px;
  }
  .myBtn--link {
    margin-top: 12px;
    padding-left: 0;
  }
  position: relative;
  min-height: 260px;
  border-radius: 8px;
  height: fit-content;
  max-width: 90%;
  width: 900px;
  min-width: 720px;
  margin: 40px auto 40px;
  padding: 20px 48px 40px;
  outline: none;
  border: none;
  box-shadow: 3px 3px 3px 3px #33373859;
  display: flex;
  flex-direction: column;
  background-color: $white;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 26px;
    margin-right: 26px;
    margin-top: 20px;
    h1 {
      @include plans__h1;
    }
    h4 {
      @include plans__p2;
      strong {
        margin-left: 8px;
      }
    }
  }
  &__options {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    h4 {
      @include plans__p2;
    }
  }
  &__option {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 26px;
    border: thin solid $border;
    border-radius: 24px;
    margin: 8px 0;
    cursor: pointer;
    &--selected {
      border-color: $link;
    }
    .styled-checkbox {
      margin-top: 4px;
    }
  }
  &__optionCheckbox {
    margin: 0 40px 0 0;
    width: 26px;
    height: 26px;
  }
  &__optionBody {
    flex: 1;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    h2 span {
      color: $text--light;
      margin-left: 4px;
      &.plansModal__asterik {
        color: $low;
      }
    }
    h4 {
      margin-top: 8px;
    }
    .myBtn--link {
      svg {
        margin-right: 4px;
      }
    }
  }
  &__asterik {
    color: $low;
  }
  &__bottom {
    display: flex;
    justify-content: flex-end;
    margin: 30px;
  }
  &__inputRow {
    display: flex;
    justify-content: space-between;
    & > div:first-child {
      margin-right: 16px;
      flex: 1.1;
    }
    & > div:nth-child(2) {
      flex: 1;
    }
  }
  &__payment {
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
    margin-right: 40px;
    .myBtn--link {
      svg {
        margin-right: 8px;
      }
    }
  }
  &__stripeContainer.StripeElement {
    padding-top: 16px;
    border-radius: 12px;
    height: 50px;
    border: thin solid $text--light;
  }
  &__stripeError {
    padding: 8px 20px;
    border-radius: 8px;
    background-color: $red--light;
    h4 {
      color: white;
    }
  }
  &__questions {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h3 {
      margin-bottom: 24px;
      width: 180px;
      text-align: center;
    }
  }
  &__alert {
    padding: 4px 20px;
    margin: 0 -40px;
    max-width: 90vw;
    width: 900px;
    text-align: center;
    h4 {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
    .myBtn--link {
      margin-top: 0;
      padding: 0 4px;
      color: $link__light-bg;
      font-size: inherit;
      &:hover {
        color: $link__light-bg--lighten;
        text-decoration: underline;
      }
    }
    a {
      color: $link__light-bg;
      text-decoration: underline;
      &:hover {
        color: $link__light-bg--lighten;
        text-decoration: underline;
      }
    }
    svg {
      color: $warning;
      margin-right: 4px;
    }
    .plansModal__asterik {
      display: inline-flex;
      justify-content: flex-end;
      width: 18px;
    }
  }
  &__actions {
    flex: 3;
    display: flex;
    margin: 12px 30px 0 30px;
    align-items: center;
    justify-content: space-between;
    h1 {
      flex: 2;
      text-align: right;
      margin-right: 20px;
      svg {
        height: unset;
        margin-left: 20px;
      }
    }
  }
  &__sub {
    @include text--h4;
    margin-top: 4px;
    margin-right: 26px;
    align-self: flex-end;
  }
  &__methodActions {
    display: flex;
    // flex-direction: column;
    // width: fit-content;
    // align-items: flex-end;
    .myBtn {
      margin-top: 0;
    }
    .myBtn--secondary {
      max-width: 280px;
      margin-right: 8px;
    }
    h4 {
      @include text--h4;
      margin: 4px 0;
    }
  }
  &__methodActionsCol {
    display: flex;
    flex-direction: column;
  }
  &__methodAction--link {
    color: $text--dark-2;
    &:hover {
      color: $link--light;
    }
  }
}
