@import 'color/colors';

.alert-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0;
  padding: 20px;
  flex: 1;
  align-items: center;
  position: relative;

  &__exit {
    position: absolute;
    align-self: flex-end;
    margin-top: -8px;
    right: 16px;
    font-size: 18px;
    color: $text--dark;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }

  &__exclamation {
    color: $low;
    margin-right: 4px;
    &.warning {
      color: $orange;
    }
  }
  h2 {
    @include text--h2;
    width: 100%;
    margin: 0 0 12px;
  }

  p {
    flex: 1;
    @include text--body1;
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
    margin: 20px 15px 15px;
    align-items: flex-end;
    flex-direction: row;
    @media (max-width: 768px) {
      margin: 12px 4px 8px;
    }
  }
}
