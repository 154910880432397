@import 'navigation/navigation';
@import 'color/colors';

.portalTaskModal {
  display: flex;
  overflow-y: visible;
  min-height: 90%;

  &__container {
    position: relative;
    outline: none;
    margin: 0 auto auto;
    border-radius: 8px;
    border: none;
    box-shadow: 3px 3px 3px 3px #33373859;
    background-color: $white;
    min-height: 220px;
    min-width: 1000px;
    width: 100vw;
    height: 90vh;
    max-height: 1400px;
    max-width: 1220px;
    margin-top: 20px;
    padding: 30px 40px 0 40px;
    overflow-y: auto;
  }
  &__overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    -webkit-overflow-scrolling-: 'touch';
    .myBtn--link {
      align-self: flex-end;
    }
  }
  &__exit {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 40px;
    top: 34px;
    z-index: 10;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &__sidebarToggle {
    position: absolute;
    top: 34px;
    right: 148px;
    border-color: $text--dark-1;
    color: $text--dark-1;
    z-index: 10;
    &:hover {
      border-color: $link;
      color: $link;
    }
  }

  .workbench__content {
    padding: 0 40px 40px 20px;
    overflow-y: visible;
    border-right: thin solid $border;
    max-width: 760px;
    // overflow-x: visible;
  }
  .workbenchTaskOverview__top-actions .myBtn--icon {
    width: 40px;
    height: 40px;
    min-width: unset;
  }
  .TaskCard {
    box-shadow: none;
    border: thin solid $border;
  }
  .workbench-form {
    padding: 0;
    margin-left: 0;
    box-shadow: none;
    // border: thin solid $border;
  }
  .workbenchTaskOverview__bottom {
    bottom: 10vh;
    top: unset;
    max-width: 1220px;
    margin: 0 auto;
    margin-bottom: -20px;
    left: 0;
    right: 0;
    border-radius: 8px;
    padding: 20px 0;
    .workbench-actions {
      justify-content: space-between;
      max-width: unset;
      padding: 0 80px;
    }
  }
  .workbench-form__bottom {
    // padding-top: 24px;
    // padding-bottom: 20px;
    // margin-top: 20px;
    // position: sticky;
    // bottom: 0;
    bottom: 10vh;
    top: unset;
    max-width: 1220px;
    margin: 0 auto;
    margin-bottom: -20px;
    left: 0;
    right: 0;
    border-radius: 8px;
    padding: 20px 0;
    .workbench-actions {
      justify-content: space-between;
      max-width: unset;
      padding: 0 80px;
    }
  }
  .infoSidebar__container {
    height: unset;
    position: relative;
    margin-left: 40px;
    background-color: $white;
    width: 340px;
    top: unset;
    padding: 0 0 100px 0;
    overflow-y: visible;
    max-height: unset;
    &::before {
      border: none;
    }
  }
  .infoSidebar__reviewInner {
    overflow-y: visible;
    padding-bottom: 40px;
    h1 {
      margin-top: 0;
    }
  }
  .infoSidebar__review {
    overflow-y: visible;
  }
  .infoSidebar__reviewRow {
    margin-left: 0;
  }
  .infoSidebar__reviewPreview {
    width: 95%;
  }
  .infoSidebar__room-tags {
    width: unset;
  }
  .infoSidebar__room-tag {
    max-width: 112px;
    padding: 4px 10px;
    border-radius: 10px;
  }
  .infoSidebar__faq {
    bottom: 0;
    padding-bottom: 40px;
  }
  .infoSidebar__faqInner {
    bottom: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  &--sidebarCollapsed {
    .infoSidebar__container {
      display: none;
    }
    .workbench__content {
      max-width: 98%;
      border-right: none;
    }
  }
  @media (max-height: 1080px) {
    // max-height: 90vh;
  }
  @media (min-height: 1600px) {
    &__container {
      margin-top: 60px;
    }
    // .workbenchTaskOverview__bottom {
    //   top: 1400px;
    // }
    // .portalTaskModal__sidebarToggle {
    //   top: 100px;
    // }
  }
  @media (max-width: 1380px) {
    &__container {
      min-width: unset;
      padding-left: 40px;
    }
    .workbench__content {
      padding: 0 20px 0 12px;
    }
  }
  @media (max-width: 1280px) {
    .portalTaskModal__sidebarToggle {
      right: 140px;
    }
    .infoSidebar__container {
      width: 300px;
      margin-left: 30px;
    }
    .infoSidebar__reviewRow {
      margin-left: 0;
    }
    .infoSidebar__reviewPreview {
      width: 100%;
    }
  }
  @media (max-width: 1080px) {
    &__container {
      padding: 20px 12px 0 16px;
    }
    .portalTaskModal__sidebarToggle {
      right: 120px;
      top: 24px;
    }
    .portalTaskModal__exit {
      top: 24px;
    }
    &--sidebarCollapsed {
      .workbenchTaskOverview__top-actions {
        margin-right: 20px;
      }
    }
  }
  @media (max-width: 876px) {
    &--sidebarCollapsed .workbenchTaskOverview__top {
      margin-left: 0;
    }

    .workbench__content--form {
      overflow-x: auto;
    }
    .workbenchTaskOverview__top {
      margin-left: 0;
    }
    .workbenchTaskOverview__top-sections {
      margin-left: 0;
    }
    .workbench-form {
      min-width: 580px;
    }
  }
}
