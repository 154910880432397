@import 'color/colors';
@import 'navigation/navigation';

.room__container {
  width: 100%;
  display: flex;
  flex: 1 1;
  background-color: $workbench--background;
  .infoSidebar__container {
    bottom: 12px;
  }
  .pageLoader__wrapper {
    top: 70px;
  }
}

.room__exit {
  position: fixed;
  left: 80px;
  top: 20px;
  z-index: 7;
  color: $text--dark;
  text-decoration: none;
  svg {
    margin-right: 8px;
    margin-top: 2px;
  }
  &:hover {
    color: $link;
  }
}

.room__error {
  @include text--h2;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: $text--dark-1;

  margin: 30px 60px 60px;
  .myBtn--link {
    font-size: inherit;
  }
}

.room__main {
  display: flex;
  flex: 1;
  margin-top: $topbar-height;
  overflow-x: auto;
  position: relative;
  @media (min-width: 1700px) {
    justify-content: center;
    .room__content {
      max-width: 1400px;
      margin-left: 0;
      margin-right: 0;
    }
    .infoSidebar__container {
      left: unset;
      right: unset;
      margin-left: 980px;
    }
    .workbench__toggle-info {
      left: unset;
      margin-left: 1000px;
    }
  }
}

.room__h3 {
  @include text--h3;
  margin: 4px 0;
}

.room__content {
  background-color: $workbench--background;
  flex: 1;
  overflow-x: visible;
  margin-bottom: 20px;
  min-width: 560px;
  flex-direction: column;
  margin: 60px 60px 160px 80px;
  max-width: 1260px;
  .room__header {
    @include text--h1;
    font-weight: 700;
    color: #0f1924;
    margin: 0 0 20px;
    min-height: 40px;
    display: flex;
    align-items: center;
    &--text {
      font-size: 28px;
      font-weight: 700;
      color: #0f1924;
    }
    @media (max-width: 1680px) {
      margin: 0 0 30px;
    }
    @media (max-width: 1580px) {
      max-width: 1160px;
      justify-content: flex-start;
      .myBtn--link {
        margin-left: 12px;
      }
    }
    @media (max-width: 876px) {
      max-width: 85vw;
      .workbench-form {
        min-width: unset;
        width: 100%;
        padding-right: 20px;
        margin-left: 0;
        margin-right: 0;
        max-width: unset;
      }
      &--text {
        font-size: 20px;
      }
    }
  }
  &.is-outside {
    margin-right: 0;
    overflow-x: hidden;
    max-width: 1200px;
    .outside-form__content {
      padding: 12px 0;
      max-width: 840px;
    }
    @media (max-width: 1480px) {
      .room__header {
        max-width: 840px;
      }
    }
    @media (max-width: 1280px) {
      overflow-x: auto;
    }
  }
  .basic-topbar {
    display: none;
  }
  .outside-form__main {
    margin: 0;
    padding: 0;
  }
  .outside-form-header {
    text-align: left;
    h1 {
      @include text--h2;
      margin: 20px 0 12px;
    }
  }
  .outside-form__loader {
    margin-top: 0;
  }
  .outside-form__content {
    padding: 0;
  }
  @media (max-width: 1480px) {
    margin-left: 60px;
    margin-top: 40px;
  }
  @media (max-width: 1080px) {
    margin-left: 40px;
  }
  @media (max-width: 876px) {
    .room__nav-link {
      font-size: 16px;
    }
  }
  @media (max-width: 760px) {
    margin-left: 20px;
    margin-right: 20px;
    min-width: unset;
    .room__nav {
      overflow-x: auto;
    }
  }
}

.room__nav {
  display: flex;
  margin-bottom: 20px;

  align-items: flex-end;
  border-bottom: thin solid $border;
  column-gap: 30px;
  .myBtn--primary {
    margin-right: 12px;
    height: 50px;
  }
  .myBtn--secondary {
    min-width: 108px;
    height: 50px;
  }
  .dropdown {
    top: 56px;
  }
  .room__action {
    margin-bottom: 8px;
    margin-left: 0;
  }
  .styled-checkbox {
    margin: 0 0 6px;
  }
  @media (max-width: 1240px) {
    margin-top: 20px;
  }
}
.room__nav-link {
  display: flex;
  align-items: center;
  padding: 0 0 6px;
  border-bottom: 4px solid transparent;
  margin-right: 30px;
  @include text--h4;
  color: $text--sub;
  font-size: 18px;
  font-weight: 500;
  &--selected {
    border-bottom: 4px solid $link;
    color: $link;
  }
}

.room__action {
  color: $text--dark-1;
  flex: -1;
  text-decoration: none;
  margin-left: 20px;
  svg {
    color: $link;
    margin-right: 4px;
  }
  &:hover {
    color: $link--light;
    svg {
      color: $link--light;
    }
  }
}

.room__subheader {
  @include text--h2;
  color: $text--dark-1;
  margin: 8px 0;
  display: flex;
  align-items: center;
  .dropdown {
    right: -128px;
    width: 200px;
  }
}

.room__subsection {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.room__user {
  &Row {
    display: flex;
    align-items: center;
  }
  h4 {
    @include text--h4;
    margin-top: -4px;
  }
}
.room__code {
  &Row {
    display: flex;
    align-items: center;
    margin-top: -4px;
    margin-bottom: 4px;
  }
  &Ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 140px;
  }
  h4 {
    @include text--h4;
    margin-top: -4px;
  }
}
.room__shareCode {
  align-self: flex-start;
}

.room__floatingHeader {
  position: absolute;
  left: 120px;
  top: 10px;
}

.room__sectionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 20px;
  box-shadow: $shadow__table;
  background-color: $white;
  border-radius: 16px;
  min-width: 680px;
  @media (min-width: 1240px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    box-shadow: none;
    background-color: inherit;
  }
  @media (min-width: 1700px) {
    column-gap: 30px;
  }
}

.room__section {
  max-width: 1200px;
  min-width: 680px;
  // min-height: 260px;
  position: relative;
  display: flex;
  flex-direction: column;

  &--error {
    min-height: 340px;
    justify-content: center;
    box-shadow: $shadow__table;
    background-color: $white;
    border-radius: 16px;
    max-width: 800px;
    min-width: 95vw;
    width: 95vw;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dataTable__wrapper {
    margin-bottom: 20px;
    border-radius: 16px;
  }
  .dataTable__documentRequired {
    color: $text--dark-1;
  }

  @media (min-width: 1240px) {
    box-shadow: $shadow__table;
    background-color: $white;
    border-radius: 16px;
    max-width: 800px;
    min-width: 800px;
  }

  @media (max-width: 1280px) {
    .dataTable__wrapper {
      min-width: 880px;
    }
  }

  @media (max-width: 1080px) {
    .infoSidebar__linksWrapper {
      position: relative;
      right: unset;
      width: 100%;
      flex-direction: row;
      h2 {
        width: unset;
      }
    }
    .infoSidebar__link {
      height: 20px;
    }
    .infoSidebar__links {
      background-color: transparent;
      padding: 0;
      box-shadow: unset;
      width: unset;
      column-gap: 20px;
      row-gap: 12px;
      flex-direction: row;
      max-width: 85vw;
      flex-wrap: wrap;
      margin-top: 0;
      margin-left: 20px;
      svg {
        display: none;
      }
    }
  }
  &--overview {
    padding: 0 0 12px;
    // max-width: 780px;
    min-width: 780px;
    height: fit-content;
    .roomInfoCard {
      background-color: transparent;
      box-shadow: none;
      padding-bottom: 0;
    }
    @media (min-width: 1240px) {
      max-width: 780px;
    }
    @media (max-width: 1580px) {
      min-width: 700px;
    }
    @media (min-width: 1700px) {
      min-width: 900px;
    }
  }
  &--infoBarHiding {
    max-width: 980px;
    width: 100%;
  }
  &--reports {
    max-width: 1080px;
    min-width: unset;
    box-shadow: none;
    background-color: transparent;
    .dataTable__wrapper {
      // box-shadow: none;
      margin-bottom: 12px;
    }
  }
  &--small {
    .roomInfoCard {
      &__header {
        height: 80px;
        h2 {
          font-size: 20px;
          margin: 0;
          .myBtn--icon {
            border: thin solid $text--dark-1;
            margin-left: 4px;
            margin-top: -8px;
            height: 18px;
            width: 18px;
            svg {
              color: $text--dark-1;
              font-size: 10px;
            }
            &:hover {
              border: thin solid $link;
              svg {
                color: $link;
              }
            }
          }
        }
      }
      &--resource {
        box-shadow: none;
        padding: 0;
      }
    }
    @media (min-width: 1240px) {
      height: fit-content;
      width: 460px;
      min-width: 460px;
      max-width: unset;
      margin-right: 0;
      .roomInfoCard {
        &__header {
          height: unset;
          cursor: default;
          padding: 0 10px 12px;
          &Chevron {
            display: none;
          }
          &:hover {
            background-color: $white;
          }
        }
        &__section {
          margin: 12px 0 16px;
        }
        &__sectionCol {
          .roomInfoCard__sectionRowItems {
            .roomInfoCard__sectionCol {
              width: 100px;
            }
          }
          h3 span {
            margin-left: 4px;
          }
        }
        &__roomActions {
          margin: 16px 10px;
          display: flex;
          .myBtn--secondary {
            flex: 1;
          }
        }
        &__roomItems {
          margin: 0;
        }
        &__sectionHeader {
          margin: 12px 0;
        }
        &__sectionRow {
          justify-content: flex-start;
          flex-direction: column;
          padding: 8px 16px;
          margin-left: 0;
          &--flat {
            padding: 4px 0;
          }
          &--documents {
            flex-direction: row;
            padding-bottom: 0;
            flex-wrap: wrap;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 440px;
            align-items: flex-start;
          }
          &--link {
            margin-left: -16px;
          }
        }
        &__documentWrapper {
          margin-right: 20px;
        }
        &--resource {
          .roomInfoCard__sectionRow--resource {
            grid-template-columns: 1fr 1fr;
          }
          .roomInfoCard__sectionRowHeader {
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }
      }
    }
    @media (min-width: 1480px) {
      .roomInfoCard__documentWrapper {
        margin-right: 40px;
      }
      .roomInfoCard__sectionRowItems {
        .roomInfoCard__sectionCol {
          width: 120px;
        }
      }
    }
  }
}

.room__resourceActions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.room__addResource {
  margin-top: 8px;
}
.room__modal {
  &-overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling-: 'touch';
    .myBtn--link {
      align-self: flex-end;
    }
  }
  h4 {
    @include text--h4;
  }
  p {
    @include text--body1;
    margin-bottom: 4px;
  }
  position: relative;
  max-width: 90%;
  min-height: 300px;
  min-width: 540px;
  padding: 20px 40px 20px;
  outline: none;
  margin: auto;
  border: none;
  box-shadow: 3px 3px 3px 3px #33373859;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 4px;
  &--sm {
    min-height: 220px;
    max-width: 600px;
  }
  &--lg {
    min-height: 340px;
  }
  &Actions {
    flex: 3;
    display: flex;
    align-items: flex-end;
    margin-top: 12px;
    justify-content: space-around;
  }
  &-exit {
    position: absolute;
    align-self: flex-end;
    margin-top: -3px;
    right: 16px;
    font-size: 1.25em;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &-preview {
    height: 400px;
    margin-bottom: 20px;
  }
  &-error {
    color: $warning;
    svg {
      margin-right: 4px;
      color: $warning;
    }
  }
  &-option {
    &-wrapper {
      display: flex;
      align-items: flex-start;
      position: relative;
      &:hover {
        background-color: $select--hover;
      }
      &--selected {
        background-color: $select--focus;
        .room__modal-option {
          color: $white;
        }
        &:hover {
          background-color: $select--focus;
        }
      }
      &--disabled {
        &:hover {
          background-color: $white;
        }
        h4 {
          color: #cccccc;
        }
        .savvi-tooltip {
          right: 20px;
        }
      }
    }
    @include text--h4;
    margin-top: 10px;
    display: flex;
    color: $low;
    background-color: transparent;
    text-align: right;
    outline: none;
    border: none;
    cursor: pointer;
    min-width: fit-content;
    margin-right: 12px;
    &--low {
      color: $mint;
    }
  }
  h2 {
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    span {
      color: $red;
    }
  }
  .myBtn--link {
    padding-left: 0;
    margin-bottom: 8px;
    margin-top: -8px;
  }
  .bcu-footer {
    position: absolute;
    height: 0;
    width: 0;
    margin: 0;
    overflow: hidden;
    .bcu-footer-left {
      width: 0;
      overflow: hidden;
    }
  }
}

.room__reports-preview {
  border: thin solid $border;
  border-radius: 8px;
  padding: 20px;
}

.room__features {
  // flex: 2;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  display: contents;
  div {
    margin-bottom: 8px;
    p a {
      color: $text--dark-1;
      text-decoration: underline;
      &:hover {
        color: $new-primary--light;
      }
    }
  }
}

.room__feature {
  margin-right: 8px;
  p {
    max-height: 80px;
    overflow-y: auto;
    white-space: pre-wrap;
  }
}
.room__feature--single {
  grid-column: 2 / 4;
}

.room__box-explorer {
  margin-top: 20px;
  padding: 8px 12px 12px;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
}

.room__loader {
  align-self: center;
  text-align: center;
  font-size: 24px;
  color: $primary;
  margin: auto;
  padding-bottom: 110px;
  img {
    width: 2em;
  }
}

.room__grouped-logs {
  // margin-top: 20px;
  h4 {
    @include text--h4;
    margin: 4px 0;
  }
  p {
    @include text--body2;
  }
  .myBtn--link {
    margin-left: 0;
    padding-left: 0;
    margin: 0 0 4px;
  }
}

.room__access {
  display: flex;
  margin-bottom: 4px;
  align-items: center;
}

.room__tags {
  display: flex;
  p {
    padding: 2px 8px;
    margin: 0 4px 0 0;
    border-radius: 16px;
    color: $white;
    background-color: $bg--status;
    display: flex;
    align-items: center;
    margin-left: 4px;
    &.low {
      background-color: $low;
    }
    &.room__tag--disabled {
      background-color: $bg--status;
    }
  }
  .myBtn--icon {
    height: 20px;
    width: 20px;
  }
}

.room__access-logs {
  margin-bottom: 8px;
}

.room__section-header {
  margin-bottom: 5px;
}

.room__btn {
  margin-top: 15px;
  box-shadow: -2px 4px 4px 0 #ccc;
}

.room__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.room__bottom {
  bottom: 26px;
  left: 0;
  right: 0;
  position: fixed;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $white;
  border-top: 1px solid $light-95;
  z-index: $info-bar-z-index - 1;
}

.roomInfoCard {
  width: 100%;
  padding: 30px 30px 4px;
  &__border {
    height: 1px;
    background-color: $border;
    margin: 0 30px;
  }
  &__header {
    padding: 0 40px 0 50px;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    border-radius: 16px;
    h2 {
      @include text--h1;
      margin: 0;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
    &:hover {
      background-color: $hover;
    }
  }
  &__headerInfo {
    margin-top: -18px;
  }
  &__headerChevron {
    position: absolute;
    left: 14px;
    font-size: 24px;
    transition: all 0.2s ease-in-out;
  }
  &__section {
    margin: 12px 0 0;
  }
  &__sectionHeader {
    h4 {
      @include text--h4;
      .styled-text-field__container {
        margin: 0;
      }
      .styled-text-field__wrapper {
        height: 40px;
      }
    }
    display: flex;
    align-items: center;
    margin: 12px 10px;
    .myBtn--icon {
      margin-left: 4px;
    }
  }
  &__divider {
    height: 1px;
    background-color: $border;
    flex: 1;
    margin-left: 4px;
  }
  &__sectionRow {
    display: flex;
    justify-content: space-between;
    padding: 12px 10px;
    border-radius: 16px;
    margin: 4px 0;
    cursor: pointer;
    &:hover {
      background-color: $hover;
    }
    &--flat {
      &:hover {
        background-color: inherit;
        cursor: default;
      }
    }
    &--resource {
      display: grid;
      grid-template-columns: 1.25fr 1fr 1fr;
      gap: 12px;
      .roomInfoCard__sectionColItem {
        font-size: 14px;
        font-weight: 500;
        color: $text--dark-2;
        align-items: flex-start;
        max-height: 100px;
        position: relative;
        overflow-y: auto;
        &--email {
          word-break: break-word;
        }
        &--address {
          max-height: 140px;
        }
        &--long {
          padding-right: 4px;
        }
        /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
        &::-webkit-scrollbar-track {
          // visibility: hidden;
          opacity: 0.1;
          box-shadow: inset 0 0 2px #a4a4a4;
        }
        &::-webkit-scrollbar-thumb {
          // visibility: hidden;
          opacity: 0.2;
          box-shadow: inset 0 0 2px #a4a4a4;
        }
        &:hover {
          &::-webkit-scrollbar-track {
            opacity: 0.3;
            box-shadow: inset 0 0 6px #a4a4a4;
          }
          &::-webkit-scrollbar-thumb {
            opacity: 0.4;
            box-shadow: inset 0 0 6px #a4a4a4;
          }
        }
        /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
      }
    }
    &--documents {
      justify-content: flex-start;
      overflow-x: auto;
      gap: 18px;
      border-radius: 8px;

      .portalInfoCard__documentPreview {
        border-radius: 8px;
      }
      // /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
      // &::-webkit-scrollbar-track {
      //   // visibility: hidden;
      //   opacity: 0;
      //   box-shadow: none;
      // }
      // &::-webkit-scrollbar-thumb {
      //   // visibility: hidden;
      //   opacity: 0;
      //   box-shadow: none;
      // }
      // &:hover {
      //   &::-webkit-scrollbar-track {
      //     opacity: 0.3;
      //     box-shadow: inset 0 0 6px #a4a4a4;
      //   }
      //   &::-webkit-scrollbar-thumb {
      //     opacity: 0.4;
      //     box-shadow: inset 0 0 6px #a4a4a4;
      //   }
      // }
      // /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
      &--long {
        &::-webkit-scrollbar-track {
          opacity: 0.3;
          box-shadow: inset 0 0 6px #a4a4a4;
        }
        &::-webkit-scrollbar-thumb {
          opacity: 0.4;
          box-shadow: inset 0 0 6px #a4a4a4;
        }
      }
    }
  }
  &__sectionRowHeader {
    @include text--h2;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    height: 36px;
    display: flex;
    align-items: center;
    color: #2d363c;
    margin-right: 20px;
  }
  &__addIcon {
    color: $link;
    &:hover {
      color: $link--light;
    }
  }
  &__dropdown {
    .dropdown {
      width: 380px;
      padding-bottom: 20px;
      top: 20px;
      .myBtn--secondary {
        justify-content: flex-start;
        border: none;
        padding: 8px 8px;
        margin: 4px 12px;
        width: 356px;
        border-radius: 8px;
        transition: 0.25s;
        font-size: 14px;

        &:not(&:disabled):hover {
          background-color: $hover;
          color: $text--dark-2;
          text-decoration: none;
          &.isWarning {
            color: $warning--light;
          }
        }
      }
    }
  }
  &__sectionRowItems {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__lock {
    margin-right: 4px;
    font-size: 14px;
  }
  &__chevron {
    margin-left: 4px;
    font-size: 12px;
  }
  &__sectionCol {
    h3 {
      @include text--h3;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
    }
    p {
      @include text--body2;
    }
    &--links {
      width: unset;
      overflow: visible;
      h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 280px;
      }
    }
  }
  &__sectionColH4 {
    @include text--h4;
    margin-bottom: 4px;
    word-break: break-word;
    position: relative;

    // &--check {
    //   margin-top: -2px;
    // }
  }
  &__sectionColCheck {
    display: flex;
    // align-items: center;
    margin-top: 12px;
    svg {
      font-size: 14px;
      margin-right: 4px;
    }
    h4 {
      @include text--h4;
      font-size: 14px;
      // margin-bottom: 4px;
      margin-top: -2px;
      word-break: break-word;
      position: relative;
    }
  }
  &__checkmark {
    margin-right: 4px;
    font-size: 14px;
  }
  &__sectionColDelete {
    position: absolute;
    top: -12px;
  }
  &__fade {
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, $white);
  }
  &__dropdownHeader {
    display: flex;
    align-items: center;
    margin: 20px 20px 12px;
    h4 {
      @include text--h4;
      border: none;
      padding: 0 4px 0 0;
    }
  }
  &__roomAccess {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 0;
    padding-top: 0;
    text-decoration: none;
    h3 {
      @include text--h3;
      font-size: 14px;
      font-weight: 700;
    }
    svg {
      color: $text--dark;
    }
    &--flat {
      cursor: default;
    }
    &:not(&--flat):hover {
      h3 {
        color: $link;
      }
      svg {
        color: $link;
      }
    }
    &:disabled {
      cursor: default;
      h3 {
        color: $text--dark-disabled;
      }
      svg {
        color: $text--dark-disabled;
      }
      &:hover {
        h3,
        svg {
          color: $text--dark-disabled;
        }
      }
    }
  }
  &__roomActions {
    display: flex;
    align-items: center;
    .myBtn--icon {
      margin-right: 4px;
    }
    .myBtn--link {
      text-decoration: none;
      svg {
        margin-left: 4px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__linkIcon {
    margin-right: 4px;
    margin-top: 4px;
    transform: rotate(-45deg);
  }
  &__resource {
    background-color: $white;
    display: grid;
    grid-template-columns: 280px 190px 190px;
    // min-height: 120px;
    align-content: center;
    &--first {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &--last {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: none;
    }
  }
  &__resourceType {
    @include text--h2;
    display: flex;
    font-size: 20px;
    align-items: center;
    margin-bottom: 12px;
    margin-left: 36px;
    margin-right: 36px;
    .myBtn--icon {
      color: $text--dark-1;
      margin-left: 4px;
      &:hover {
        color: $link--light;
      }
      &.isWarning {
        color: $orange;
        &:hover {
          border: thin solid $orange--light;
        }
        &:active {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  &__resourceName {
    @include text--h2;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: #2d363c;
    margin-right: 20px;
    flex: 1.5;
    display: flex;
    .dropdown__wrapper {
      top: -6px;
    }
  }
  &__resourceFeatures {
    display: contents;
    div {
      margin-bottom: 8px;
      p a {
        color: $text--dark-1;
        text-decoration: underline;
        &:hover {
          color: $new-primary--light;
        }
      }
    }
  }
  &__resourceFeature {
    margin-right: 8px;
    p {
      max-height: 80px;
      overflow-y: auto;
      white-space: pre-wrap;
    }
  }
  @media (min-width: 1240px) {
    .roomInfoCard--resource {
      padding: 0;
    }
  }
}
