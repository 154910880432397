@import 'color/colors';
@import 'navigation/navigation';

.redirectBanner {
  color: $white;
  margin-right: 8px;
  position: fixed;
  text-align: center;
  background-color: $low;
  z-index: $bars-z-index + 1;
  display: flex;
  padding: 0 8px 0 12px;
  border-radius: 20px;
  align-items: center;
  top: 64px;
  height: 30px;
  min-height: fit-content;
  width: fit-content;
  right: 0;
  left: 0;
  margin: 0 auto;
  &--hide {
    display: none;
  }
  &__icon {
    color: $white;
    margin-right: 4px;
  }
  &__hide {
    padding: 0 4px;
    margin: 0;
    margin-left: 8px;
    svg {
      color: $white;
    }
    &:hover {
      svg {
        color: $link--light;
      }
    }
  }
  h4 {
    @include text--h4;
    color: $white;
    margin: 4px 0;
  }
}
