@import 'color/colors';

.sidebarNavLink {
  align-items: center;
  background-color: inherit;
  border-left: 8px solid transparent;
  display: flex;
  height: 100%;
  min-height: 40px;
  padding-left: 36px;
  transition: 0.2s;
  width: 100%;

  .savvi-tooltip {
    left: 16px;
    bottom: 40px;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    position: relative;
    height: 56px;

    &.isCompany {
      margin-top: 0px;
      margin-bottom: 60px;
    }

    &:hover {
      .sidebarNavLink {
        border-left-color: $theme-light--darker;
        // color: $theme-light;
      }
      .sidebarNavLink__icon {
        color: $theme-light--darker;
      }
      .sidebarNavLink__text {
        color: $theme-light--darker;
        font-weight: bold;
      }
    }
  }

  &--selected {
    border-left-color: $theme-light;
    .sidebarNavLink__text {
      color: $theme-light;
      font-weight: bold;
    }
    .sidebarNavLink__icon {
      color: $theme-light;
    }

    &:hover {
      .sidebarNavLink__text {
        color: $theme-light--darker;
      }
      .sidebarNavLink__icon {
        color: $theme-light--darker;
      }
    }
  }
  &:focus {
    outline: 0;
  }

  &__text {
    margin-left: 12px;
    color: $text--light;
    font-size: 12px;
  }
  &__icon {
    height: 22px;
    width: 22px;
    color: $text--light;
  }

  &__company {
    &Wrapper {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 40px;
      width: 100%;
      height: 46px;
      cursor: pointer;
      svg {
        background-color: inherit;
        color: $text--light;
        display: flex;
        margin-left: 4px;
        text-decoration: underline;
        font-size: 12px;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        .sidebarNavLink__companyIcon {
          color: $new-primary;
          border-color: $new-primary;
        }
        .sidebarNavLink__companyText {
          color: $new-primary;
        }
        svg {
          color: $new-primary;
          font-weight: bolder;
        }
      }
      &:focus {
        svg {
          color: $new-primary;
          border-color: $new-primary;
        }
      }
      &.workbench {
        cursor: default;
        .sidebarNavLink__companyText {
          color: $text--dark;
          max-width: 134px;
        }
        .sidebarNavLink__companyIcon {
          color: $text--light;
        }
      }
      .savvi-tooltip {
        left: 105px;
        max-width: 200px;
        top: 90%;
      }
    }
    &Icon {
      align-items: center;
      border-radius: 14px;
      border: 2px solid $text--light;
      color: $text--light;
      display: flex;
      height: 28px;
      font-size: 12px;
      justify-content: center;
      width: 28px;
      margin: 8px 0;
    }
    &Text {
      color: $text--light;
      position: relative;
      font-size: 12px;
      font-weight: bold;
      margin-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 116px;
    }

    &Menu {
      background-color: $white;
      position: relative;
      width: 200px;
      max-height: 400px;
      left: 30px;
      top: 100px;
      padding: 0 0 0 12px;
      border: none;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 20%);
      border-radius: 4px;
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      outline: none;

      &Overlay {
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.33);
        overflow-y: auto;
        position: fixed;
        height: 100vh;
        width: 100vw;
        display: flex;
        padding-top: 20px;
        top: 0;
        left: 0;
        -webkit-overflow-scrolling: touch;
      }

      &Arrow {
        position: fixed;
        width: 0;
        height: 0;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent $white transparent;
        top: 100px;
        left: 57px;
      }
      &Item {
        min-height: 28px;
        font-size: 14px;
        font-weight: bold;
        opacity: 1;
        color: $text--dark;
        margin: 0;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        cursor: pointer;
        &.first {
          position: sticky;
          top: 0;
          min-height: 32px;
          line-height: 32px;
          color: $new-primary;
          background-color: $white;
          border-bottom: thin solid $border--light-1;
          svg {
            margin-right: 2px;
          }
          &:hover {
            color: $new-primary--light;
          }
        }
        &:hover {
          color: $new-primary;
          text-decoration: underline;
        }
      }

      &.collapsed {
        left: 12px;
        .sidebarNavLink__companyMenuArrow {
          left: 30px;
        }
      }
    }
  }
}
