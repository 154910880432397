@import 'color/colors';

.orgButton {
  .dropdown {
    padding-top: 20px;
    width: 220px;
    right: 8px;
    top: 20px;
    padding-bottom: 20px;
    border-bottom: thin solid $text--light;
    :last-child {
      border-bottom: none;
    }
    .myBtn--link {
      text-decoration: underline;
      text-align: start;
      color: $text--dark;
      padding: 10px 20px;
      font-size: 10px;
      font-weight: 600;
      border: none;
      text-transform: uppercase;
      justify-content: flex-start;
      &:hover,
      &:active,
      &:focus {
        color: $link--light;
      }
    }
    &:before,
    &:after {
      border: none;
    }
  }
  h4 {
    @include text--h4;
    max-height: 140px;
    overflow-wrap: break-word;
    overflow-y: auto;
  }
  h3 {
    @include text--h3;
    margin: 0 20px;
  }
  h2 {
    @include text--h2;
    font-weight: 400;
    margin: 0 20px;
  }
  p {
    @include text--body1;
  }

  &__toggle {
    // color: $text--dark;
    max-width: 250px;
    text-align: start;
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 0 20px;
    // @media (max-width: 678px) {
    //   svg {
    //     font-size: 1.1em;
    //     color: $text--dark;
    //     &:hover {
    //       color: $new-primary;
    //     }
    //   }
    // }
    &:hover,
    &:active,
    &:focus {
      h4,
      svg {
        color: $link--light;
      }
    }
    h4 {
      color: $text--dark-1;
      font-size: 12px;
      font-weight: 700;
      // letter-spacing: 0.85px;
      padding-right: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // text-transform: uppercase;
    }
    p {
      position: absolute;
      left: 44px;
      top: -14px;
      font-size: 10px;
      color: $text--dark-1;
    }
    &Icon {
      margin-right: 8px;
      font-size: 18px;
      color: $text--dark-1;
    }
    &Chevron {
      margin-left: 8px;
      font-size: 12px;
    }
    @media (min-width: 1680px) {
      max-width: 300px;
    }
  }

  &__exclamation {
    position: absolute;
    left: 32px;
    top: -4px;
    font-size: 10px;
    color: $mint;
  }
  &__exclamationCircle {
    margin-right: 4px;
    color: $mint;
  }
  &__loading {
    margin-left: 40px;
    margin-right: 40px;
  }

  &__section {
    p {
      padding: 0;
      margin: 0;
    }
    h4 {
      margin: 4px 0;
      padding: 0 20px;
      border-bottom: none;
    }
    a {
      padding-left: 0;
    }
  }

  &__separator {
    background-color: $border;
    height: 2px;
    width: 100%;
    margin: 8px 0;
  }
  &__name {
    @include text--h2;
  }
}
