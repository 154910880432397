@import 'color/colors';

.form-file-uploader {
  &__container {
    width: 100%;
    max-width: 600px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    &--error {
      border-top: thin solid $red;
      .address-group__label {
        color: $red;
      }
    }
    &--disabled {
      pointer-events: none;
      .file-uploader-content-preview {
        opacity: 0.7;
      }
    }
    .inputSubLabel {
      margin-left: 0;
    }
    .inputFeedback {
      margin-left: 0;
    }
  }
  &__label {
    @include text--label;
    margin: 4px 0 8px;
    position: relative;
  }
  &__value {
    @include text--body1;
  }
}
