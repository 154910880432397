@import 'navigation/navigation';
@import 'color/colors';

.home {
  // display: flex;
  // margin-top: 20px;
  p {
    margin: 4px 0 4px 4px;
  }
  h2 {
    margin-bottom: 16px;
    margin-top: 16px;
    color: $dark-85;
    text-decoration: underline $primary;
    text-underline-position: under;
    font-size: 2em;
    font-weight: 500;
  }
  &__empty {
    .myBtn--link {
      font-size: inherit;
      margin: 0 4px;
    }
  }
  @media (max-width: 1280px) {
    p {
      font-size: 0.9em;
    }
    h4 {
      font-size: 1em;
    }
  }
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.home__subheader {
  margin: 8px 0;
  a {
    text-decoration: underline;
    &:hover {
      color: $link--light;
    }
  }
  svg {
    color: $mint;
  }
}

.home__actionItems {
  &__header {
    display: flex;
    transition: 0.05s all linear;
    align-items: center;
    margin-top: 8px;
    width: fit-content;
    padding-right: 60px;
    border-bottom: 2px solid $link;
    h2 {
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 2em;
      color: $dark-85;
    }
    svg {
      font-size: 1.5em;
      margin-right: 4px;
    }
    @media (max-width: 1280px) {
      h2 {
        font-size: 1.8em;
      }
    }
    @media (max-width: 768px) {
      h2 {
        font-size: 1.5em;
      }
      svg {
        font-size: 1.3em;
      }
    }
  }

  &__content {
    position: relative;
    min-height: 40px;
    // animation: animateLeft 0.4s;
  }
  // @keyframes animateLeft {
  //   from {
  //     left: -400px;
  //     opacity: 0;
  //   }
  //   to {
  //     left: 0;
  //     opacity: 1;
  //   }
  // }

  &__notice {
    color: $mint;
  }
  &__warning {
    color: $red;
  }
}

.mercury-bank {
  &__wrapper {
    p {
      @include text--h4;
      margin: 6px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      white-space: pre-wrap;
      span {
        margin-right: 2px;
      }
      svg {
        color: $warning;
        margin-right: 4px;
      }
    }
  }

  &__status {
    color: $text--sub;
    font-weight: 600;
    &.low {
      color: $low;
    }
    &.med {
      color: $med;
    }
    &.high {
      color: $warning;
    }
  }

  &__dropdown {
    &Header {
      display: flex;
      align-items: center;
      margin: 20px 20px 12px;
      h4 {
        @include text--h4;
        border: none;
        padding: 0 4px 0 0;
      }
    }
    .dropdown {
      width: 380px;
      padding-bottom: 20px;
      top: 20px;
      .myBtn--secondary {
        justify-content: flex-start;
        border: none;
        padding: 8px 8px;
        margin: 4px 12px;
        width: 356px;
        border-radius: 8px;
        transition: 0.25s;
        font-size: 14px;
        svg {
          margin-left: 8px;
          width: 12px;
          color: $link;
        }

        &:not(&:disabled):hover {
          background-color: $hover;
          color: $text--dark-2;
          text-decoration: none;
          &.isWarning {
            color: $warning--light;
          }
        }
        &:disabled:hover {
          background-color: inherit;
          color: $light-110;
        }
      }
    }
    &--empty {
      .myBtn--secondary {
        width: unset;
      }
    }
  }
  &__divider {
    height: 1px;
    background-color: $border;
    margin-left: 4px;
    flex: 1;
  }

  &__bank-link {
    // padding-left: 0;
    margin-bottom: 4px;
  }
}
