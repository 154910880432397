@import 'navigation/navigation';
@import 'color/colors';

.DocRecipientsModal {
  position: relative;
  outline: none;
  margin: 0 auto auto;
  border-radius: 8px;
  border: none;
  box-shadow: 3px 3px 3px 3px #33373859;
  background-color: $white;
  min-height: 220px;
  max-width: 98vw;
  width: 680px;
  height: fit-content;
  max-height: 90vh;
  margin-top: 20px;
  padding: 30px 60px;
  overflow-y: auto;
  overflow-x: hidden;
  h2 {
    @include text--h2;
  }
  &__overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    -webkit-overflow-scrolling-: 'touch';
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-bottom: 20px;
    h1 {
      // @include text--h1;
      // margin: 0 20px 20px 0;
      color: #101010;
      font-weight: 600;
      font-size: 20px;
      margin: 0 20px 20px 0;
    }
    p {
      @include text--body1;
      font-size: 15px;
    }
    .myBtn--link {
      margin-top: 12px;
      padding-left: 0;
      svg {
        margin-right: 8px;
        position: relative;
      }
    }
  }

  &__exit {
    height: 30px;
    right: 0;
    top: -2px;
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &__body {
    h3 {
      @include text--body1;
      font-size: 18px;
    }
  }
  &__subheader {
    display: flex;
    margin-bottom: 8px;
  }
  &__recipients {
    display: flex;
    flex-wrap: wrap;
    h2 {
      @include text--h2;
    }
  }
  &__recipientCard {
    background-color: $white;
    width: 220px;
    @include border__subtle;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 16px 8px 0;
    h4 {
      @include text--h4;
    }
    p {
      @include text--body1;
    }
  }
  &__error {
    @include text--body1;
    color: $red;
    text-align: end;
  }
  &__actions {
    margin: 20px 0 0;
    display: flex;
    justify-content: space-between;
    .myBtn svg {
      margin-right: 4px;
    }
  }

  @media (max-width: 1080px) {
    min-width: unset;
  }
  @media (max-width: 520px) {
    padding: 20px 20px 20px 40px;
  }
  @media (min-height: 1280px) {
    height: 80vh;
    margin-top: 80px;
    max-height: 1400px;
  }
  @media (min-width: 1800px) {
    margin-top: 80px;
  }
}
