@import 'color/colors';
@import 'navigation/navigation';

.infoSidebar {
  &__container {
    position: fixed;
    top: $topbar-height;
    right: 0;
    width: $info-sidebar-width;
    padding: 40px 16px 140px 26px;
    overflow-y: visible;
    max-height: 1000px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $bg--page;

    &::before {
      content: '';
      border-left: thin solid $light;
      height: 70%;
      position: absolute;
      left: 0;
      top: 6%;
      @media (max-width: 1280px) {
        height: 100%;
        top: 0;
      }
    }

    @media (min-width: 1440px) {
      position: fixed;
      bottom: unset;
      right: unset;
      left: unset;
      margin-left: 890px;
      width: $info-sidebar-width--lg;
      .infoSidebar__review {
        &Inner {
          width: $info-sidebar-width--lg - 40px;
          overflow-x: hidden;
        }
      }
      .infoSidebar__faq {
        max-height: 80vh;
        .infoSidebar__faqInner {
          max-height: 80vh;
        }
      }
      .infoSidebar__faqInner {
        margin: 12px;
      }
    }
    @media (min-width: 1540px) {
      margin-left: 980px;
    }
    @media (min-width: 1780px) {
      padding-top: 60px;
    }
    @media (min-height: 1080px) {
      .infoSidebar__faq {
        max-height: 800px;
        &Inner {
          max-height: 800px;
        }
        &.collapsed {
          max-height: 240px;
        }
      }
    }
    @media (max-height: 876px) {
      .infoSidebar__faq {
        max-height: 75vh;
        bottom: 60px;
        &Inner {
          max-height: 75vh;
        }
      }
    }
    @media (max-width: 1280px) {
      justify-content: space-between;
      width: $info-sidebar-width--sm;
    }

    @media (max-width: 876px) {
      top: 0;
      width: $info-sidebar-width--mobile;
      padding: 30px 12px 0;
      z-index: $sidebar-z-index + 4;
    }

    @media (min-height: 1400px) {
      max-height: 1300px;
    }
  }

  &__openForms {
    .infoSidebar__room {
      margin-left: 20px;
      &-label {
        h2,
        h3 {
          width: 146px;
          @media (min-width: 1281px) {
            width: 220px;
          }
        }
      }
    }
  }

  &__rooms {
    h2 {
      @include text--h2;
      margin: 12px 0 6px;
    }
    h3 {
      @include text--body1;
      margin: 4px 0;
    }
  }
  &__room {
    margin-bottom: 8px;
    &-subtype {
      @include text--h2;
      font-weight: 400;
      margin: 8px 0;
    }
    &-type {
      @include text--h2;
      display: flex;
      flex-direction: column;
      h2 {
        @include text--h2;
      }
      margin-right: 4px;
      width: 166px;
      @media (min-width: 1281px) {
        width: 240px;
      }
    }
    &-label {
      display: flex;
      align-items: center;
      h2 {
        @include text--h2;
        margin-right: 4px;
        width: 166px;
        @media (min-width: 1281px) {
          width: 240px;
        }
      }
      h3 {
        @include text--h3;
        margin-right: 4px;
        width: 166px;
        @media (min-width: 1281px) {
          width: 240px;
        }
      }
      @media (max-width: 1540px) {
        .myBtn__tooltip {
          right: 0;
        }
      }
    }
    &-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 4px;
    }
    &-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      h4 {
        @include text--h4;
      }
      h3 {
        @include text--h3;
        margin-right: 4px;
        width: 166px;
        @media (min-width: 1281px) {
          width: 240px;
        }
      }
      p {
        @include text--body2;
        span {
          color: $low;
          &.unpaid {
            color: $warning;
          }
        }
      }
      &.wide {
        grid-column: 1 / 3;
      }
    }
    &-tags {
      display: flex;
      align-items: center;
      margin: 4px 0;
      width: 226px;
      .dropdown__wrapper {
        margin-left: auto;
      }
      @media (min-width: 1281px) {
        width: 300px;
        .infoSidebar__room-tag {
          max-width: 116px;
        }
      }
    }
    &-tag {
      padding: 2px 8px;
      background-color: $bg--status;
      @include text--h4;
      margin: 0 4px 0 0;
      color: $white;
      border-radius: 8px;
      max-width: 68px;
      &--low {
        background-color: $low;
      }
      &--med {
        background-color: $med;
      }
      @media (max-width: 1080px) {
        padding: 2px 4px;
        max-width: 68px;
      }
    }
  }

  &__review {
    flex: 1;
    flex-grow: 3;
    overflow: hidden;
    &.maxHeight {
      margin-bottom: 140px;
    }
    &Inner {
      min-height: 160px;
      overflow: auto;
      height: 100%;
      padding-bottom: 200px;
      /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
      &::-webkit-scrollbar-track {
        // visibility: hidden;
        opacity: 0;
      }
      &::-webkit-scrollbar-thumb {
        // visibility: hidden;
        opacity: 0;
      }
      &:hover {
        &::-webkit-scrollbar-track {
          opacity: 0.3;
        }
        &::-webkit-scrollbar-thumb {
          opacity: 0.4;
        }
      }
      /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
      &Sub {
        @include text--h4;
        position: relative;
        margin-top: -8px;
      }
      h1 {
        @include text--h1;
        margin: 12px 0 8px;
        line-height: 40px;
      }
    }
    &Header {
      h4 {
        @include text--body1;
        margin-top: 8px;
        margin-bottom: 8px;
      }
      p {
        @include text--body2;
      }
    }
    &Row {
      margin-left: 12px;
      margin-bottom: 8px;
      h4 {
        @include text--sub;
        margin-top: 12px;
        p {
          // display: inline;
          @include text--body2;
        }
        a {
          margin-left: 4px;
          color: $text--dark-1;
          text-decoration: underline;
          &:hover {
            color: $new-primary--light;
          }
        }
        svg {
          margin-right: 8px;
          color: $new-primary--light;
        }
      }
      p {
        @include text--body2;
        margin: 0;
      }
    }
    &Preview {
      height: 180px;
      margin-left: -4px;
      .bp-controls {
        left: -60%;
      }
      .page {
        padding: 8px 0 !important;
        margin: 0 8px !important;
      }
      @media (max-width: 876px) {
        .bp-container {
          min-width: 276px;
        }
      }
    }

    &GotoCabinet {
      color: $new-primary;
      text-decoration: underline;
      cursor: pointer;
      outline: none;
      border: none;
      font-size: 12px;
      margin-bottom: 8px;
      text-align: center;
      width: 250px;
      background: transparent;
      &:hover {
        color: $new-primary--light;
      }
    }
  }

  &__task {
    margin-left: 20px;
    margin-bottom: 20px;
    h4 {
      @include text--sub;
    }
    &Toggle {
      position: relative;
      @include text--body1;
      cursor: pointer;
      svg {
        margin-left: -20px;
        margin-right: 8px;
      }
      &:hover {
        color: $link;
      }
    }
    &Body {
      display: flex;
      flex-direction: column;
    }
    &Row {
      display: grid;
      margin-top: 10px;
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
      width: 100%;
    }
    &Item {
      display: flex;
      flex-direction: column;
      p {
        @include text--body2;
        span {
          color: $low;
          &.unpaid {
            color: $warning;
          }
        }
      }
      &.wide {
        grid-column: 1 / 3;
      }
    }
    &Actions {
      display: flex;
      .styled-checkbox {
        flex: 1;
        label {
          display: none;
        }
        input {
          width: 100%;
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    height: 32px;
    &s {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 4px;
      background-color: $white;
      box-shadow: $shadow__table;
      height: fit-content;
      padding: 20px 20px 34px;
      width: 100%;
      margin-top: 8px;

      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
    &sWrapper {
      margin: 0 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // position: absolute;
      width: 300px;
      // right: -260px;
      h2 {
        @include text--h2;
        width: 100%;
        display: flex;
        margin-bottom: 2px;
        align-items: center;
        justify-content: space-between;
      }
      .myBtn--link {
        svg {
          margin-right: 6px;
        }
      }
      // @media (min-width: 1780px) {
      //   right: -300px;
      // }
      // @media (max-width: 1440px) {
      //   right: -240px;
      // }
      // @media (max-width: 1280px) {
      //   right: -260px;
      // }
      // @media (min-width: 1440px) {
      //   right: -300px;
      // }
    }
    &Modal {
      &Overlay {
        z-index: 9;
        background-color: rgba(0, 0, 0, 0.66);
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        display: flex;
        overflow-y: auto;
        -webkit-overflow-scrolling-: 'touch';
      }
      position: relative;
      max-width: 90%;
      min-height: 260px;
      min-width: 540px;
      padding: 20px 40px 20px;
      outline: none;
      margin: auto;
      border: none;
      box-shadow: 3px 3px 3px 3px #33373859;
      display: flex;
      flex-direction: column;
      background-color: $white;
      &Exit {
        position: absolute;
        align-self: flex-end;
        margin-top: -3px;
        font-size: 1.25em;
        color: $primary;
        cursor: pointer;
        &:hover {
          color: $primary--light;
        }
        &:active {
          color: $primary--dark;
        }
        &:focus {
          color: $primary--dark;
        }
      }
    }
    .myBtn--link {
      @include text--h4;
      color: $text--dark-1;
      cursor: pointer;
      svg {
        margin-right: 8px;
        // color: $new-primary--light;
      }
      &:hover {
        text-decoration: underline;
        color: $link--light;
        svg {
          color: $link--light;
        }
      }
    }
  }

  &__dropdown {
    min-width: fit-content;
    .dropdown {
      width: 280px;
      padding-bottom: 20px;
      top: 20px;
      right: 20px;
      .myBtn--secondary {
        justify-content: flex-start;
        border: none;
        padding: 8px 8px;
        margin: 4px 12px;
        width: 280px;
        border-radius: 8px;
        transition: 0.25s;
        font-size: 14px;

        &:not(&:disabled):hover {
          background-color: $hover;
          color: $text--dark-2;
          text-decoration: none;
          &.isWarning {
            color: $warning--light;
          }
        }
        &:disabled:hover {
          background-color: inherit;
          color: $light-110;
        }
      }
    }
    &--empty {
      .myBtn--secondary {
        width: unset;
      }
    }
  }
  &__share {
    padding: 4px 8px;
    position: relative;
    right: 8px;
    svg {
      margin-right: 4px;
    }
  }
  &__dropdownHeader {
    display: flex;
    align-items: center;
    margin: 20px 20px 12px;
    h4 {
      @include text--h4;
      border: none;
      padding: 0 4px 0 0;
    }
  }
  &__divider {
    height: 1px;
    background-color: $border;
    margin-left: 4px;
    flex: 1;
  }

  &__faq {
    padding-top: 20px;
    overflow: hidden;
    flex: 1;
    flex-grow: 6;
    max-height: 660px;
    margin-top: -20px;
    transition: max-height 0.5s ease-in-out 0.05s;
    position: absolute;
    bottom: 140px;
    h2 {
      @include text--h2;
    }

    &.collapsed {
      transition: none;
      max-height: 160px;
      .infoSidebar__faqInner {
        max-height: 120px;
      }
    }
    &Content {
      height: 100%;
      overflow-y: auto;
    }
    &Inner {
      height: fit-content;
      box-shadow: $border--dark;
      padding: 40px 20px 140px;
      margin: 8px 8px 0;
      background-color: $white;
      max-height: 660px;
      overflow-y: auto;
      /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
      &::-webkit-scrollbar-track {
        visibility: hidden;
      }
      &::-webkit-scrollbar-thumb {
        visibility: hidden;
      }
      &:hover {
        &::-webkit-scrollbar-track {
          visibility: visible;
        }
        &::-webkit-scrollbar-thumb {
          visibility: visible;
        }
      }
      /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
      h2 {
        margin-bottom: 10px;
      }
      p {
        @include text--body1;
        margin-bottom: 12px;

        @media (max-width: 1280px) {
          @include text--body2;
        }
      }
      ul {
        color: $text--dark-1;
      }
      @media (max-width: 1280px) {
        padding-bottom: 120px;
      }
      @media (max-height: 876px) {
        max-height: 75vh;
      }
    }

    &Header {
      position: sticky;
      top: 0;
      height: 0;
      text-align: center;
      width: 100%;
      overflow: visible;
      cursor: pointer;
      button {
        width: fit-content;
        height: fit-content;
        background-color: #9ba6b2;
        border-radius: 30px;
        border: 6px solid white;
        margin-top: -30px;
        &:hover {
          background-color: $border--button;
          h1 {
            span {
              opacity: 0;
            }
            svg {
              opacity: 1;
            }
          }
        }
      }
      cursor: pointer;
      padding: 0;
      @media (max-width: 1080px) {
        left: calc(50% - 30px);
      }
      h1 {
        width: 45px;
        height: 45px;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          transition: opacity 0.3s ease-in-out;
        }
        svg {
          position: absolute;
          transition: opacity 0.3s ease-in-out;
          opacity: 0;
        }
      }
    }
  }
}
