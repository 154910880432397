@import 'color/colors';

.reportingTable {
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  margin-bottom: 8px;
  border-radius: 4px;

  &__userOnly {
    margin: 0 40px;
    @include text--h2;
    padding-left: 40px;
    color: $text--dark-1;
  }

  @media (max-width: 1600px) {
    max-width: unset;
  }
  @media (max-width: 1080px) {
    overflow-x: auto;
  }

  &__wrapper {
    width: 100%;
    max-width: 1080px;
    min-width: 980px;
    // margin-bottom: 20px;
    // background-color: $white;
    // box-shadow: $shadow__table;
    border-radius: 16px;
    &--company {
      min-width: unset;
      .reportingTable__title {
        margin-top: 28px 40px;
        margin-bottom: 0;
        justify-content: flex-start;
        .myBtn--icon {
          color: $text--dark;
          margin-bottom: 18px;
          position: absolute;
          right: -28px;
          &:hover {
            color: $link;
          }
        }
      }
      .reportingTable {
        border: none;
        box-shadow: none;
        background-color: transparent;
        grid-template-columns: repeat(5, auto);
        padding: 8px 8px 0 0;
        margin-bottom: 12px;
      }
      .reportingTable__documents {
        text-align: left;
        grid-column: auto;
        grid-row: auto;
        h4,
        p {
          width: unset;
          margin: unset;
        }
      }
    }
    @media (max-width: 1240px) {
      max-width: 100%;
      min-width: 100%;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    padding: 40px 40px 16px;
    position: relative;
    // border-bottom: thin solid $border;
    .sort-dropdown {
      position: absolute;
      right: 40px;
      bottom: 12px;
      .myBtn--link {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    h2 {
      @include text--h1;
      margin-top: 0;
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 4px;
      position: relative;
      display: flex;
      .myBtn--icon {
        margin-left: 4px;
      }
    }
    // &:hover {
    //   cursor: pointer;
    //   .reportingTable__toggleCollapse {
    //     text-decoration: underline;
    //     // color: $link;
    //   }
    //   .reportingTable__toggleIcon {
    //     color: $link;
    //   }
    // }
    &--empty:hover {
      cursor: default;
      .reportingTable__toggleCollapse {
        text-decoration: none;
        color: $text--dark;
      }
    }
    &--collapsed {
      border-bottom: none;
      .reportingTable__toggleIcon {
        margin-left: -20px;
      }
    }
    @media (max-width: 1400px) {
      padding-right: 40px;
    }
  }
  &__dropdownWrapper {
    margin-top: -10px;
    .dropdown {
      width: 300px;
      padding-bottom: 20px;
      .myBtn--secondary {
        justify-content: flex-start;
        border: none;
        padding: 8px 8px;
        margin: 4px 12px;
        width: 300px;
        border-radius: 8px;
        transition: 0.25s;
        font-size: 14px;

        svg {
          margin-right: 8px;
        }

        &:not(&:disabled):hover {
          background-color: $hover;
          color: $text--dark-2;
          text-decoration: none;
          &.isWarning {
            color: $warning--light;
          }
        }
        &:disabled:hover {
          background-color: inherit;
          color: $light-110;
        }
      }
    }
  }
  &__dropdownHeader {
    display: flex;
    align-items: center;
    margin: 20px 20px 12px;
    h4 {
      @include text--h4;
      border: none;
      padding: 0 4px 0 0;
    }
  }
  &__dropdownDivider {
    height: 1px;
    background-color: $border;
    margin-left: 4px;
    flex: 1;
  }
  &__add {
    width: fit-content;
    color: $text--dark;
    font-size: 12px;
    color: $text--dark;
    position: absolute;
    bottom: 12px;
    span {
      color: $red--light;
      text-decoration: none;
    }
    &:hover {
      color: $new-primary--light;
    }
  }
  &__titleCol {
    display: flex;
    flex-direction: column;
    // margin-right: 80px;
    h4 {
      @include text--h4;
    }
    h2 {
      @include text--h2;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      align-items: center;
    }
    &--first {
      flex: 1;
    }
    &--last {
      margin-right: 40px;
    }
  }
  &__divider {
    height: 1px;
    background-color: #dbdee0;
    flex: 1 1;
    margin-left: 8px;
  }
  &__toggleIcon {
    position: absolute;
    margin-left: -28px;
    font-size: 20px;
    top: 4px;
  }
  &__titleSummary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 80px;
    h4 {
      @include text--h4;
    }
    h2 {
      font-size: 28px;
      font-weight: 600;
      display: flex;
      align-items: center;
      svg {
        font-size: 20px;
        font-weight: 600;
        color: #60696f;
      }
    }
    svg {
      margin-right: 8px;
    }
    &--first {
      flex: 1;
    }
    &--last {
      margin-right: 0;
    }
  }
  &__empty {
    @include text--h2;
    color: $text--light;
    margin: 8px 0;
  }
  &__subquestion {
    color: $text--dark;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    span {
      color: $red--light;
    }
    .myBtn {
      color: $text--dark-1;
      display: inline-flex;
      font-size: inherit;
      &:hover {
        color: $new-primary--light;
      }
    }
  }
  &__info {
    color: $mint;
    margin-right: 4px;
  }
  &__resourceHeaders {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns:
      minmax(220px, 1.2fr) minmax(190px, 1fr) minmax(190px, 1fr) minmax(80px, 0.8fr)
      minmax(120px, 1fr);
    row-gap: 4px;
    column-gap: 8px;
    padding: 20px 16px 8px 10px;
    margin: 0 20px 0 30px;
    @media (max-width: 1560px) {
      grid-template-columns:
        minmax(130px, 1.2fr)
        minmax(160px, 1fr) minmax(160px, 1fr)
        minmax(80px, 0.8fr)
        minmax(160px, 1fr);
    }
  }
  &__resourceHeader {
    // border-bottom: thin solid $border;
    padding: 4px 0 4px 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    h4 {
      @include text--h4;
      border-bottom: thin solid $border;
      width: fit-content;
    }
    &--first {
      grid-column: 0;
      text-align: left;
      justify-content: flex-start;
    }
    &--documents {
      grid-column: 5;
    }
  }
  &__row {
    background-color: inherit;
    cursor: pointer;
    // width: 100%;
    position: relative;
    grid-template-columns:
      minmax(220px, 1.2fr) minmax(190px, 1fr) minmax(190px, 1fr) minmax(80px, 0.8fr)
      minmax(120px, 1fr);
    // direction: rtl;
    grid-auto-flow: dense;
    display: grid;
    // border-top: thin solid $border;
    row-gap: 4px;
    column-gap: 8px;
    padding: 20px 16px 12px 10px;
    margin: 0 20px 0 30px;
    border-radius: 16px;
    // @media (min-width: 1440px) {
    //   grid-template-columns:
    //     minmax(130px, 200px)
    //     minmax(200px, 1fr) minmax(200px, 1fr)
    //     minmax(280px, 2.5fr);
    // }
    // &:hover {
    //   background-color: $hover;
    // }
    &:hover {
      background-color: $hover;
      cursor: pointer;
      // width: fit-content;
    }

    &::before {
      content: ' ';
      height: 1px;
      background-color: $border;
      position: absolute;
      top: 0;
      left: 12px;
      right: 18px;
    }
    &.firstRow {
      &::before {
        height: 0px;
      }
    }
    @media (max-width: 1560px) {
      grid-template-columns:
        minmax(130px, 1.2fr)
        minmax(160px, 1fr) minmax(160px, 1fr)
        minmax(80px, 0.8fr)
        minmax(160px, 1fr);
      // &:hover {
      //   width: fit-content;
      // }
    }
  }
  &__item {
    color: $text--dark;
    font-size: 14px;
    font-weight: normal;
    word-break: break-word;
    text-align: end;
    direction: initial;
    &.first {
      grid-column: 0;
      text-align: left;
      margin: 0;
      svg {
        color: $text--dark-1;
        margin-right: 4px;
      }
      h4 {
        margin-top: 2px;
      }
    }
    &--sublabel {
      margin-bottom: 4px;
    }
    &--locked {
      pointer-events: none;
    }
    h2 {
      @include text--h2;
      color: $text--dark-1;
      font-weight: 700;
      font-size: 14px;
    }
    h4 {
      @include text--h4;
    }
    h3 {
      @include text--h2;
      font-weight: 700;
      font-size: 14px;
    }
    p {
      @include text--body2;
      white-space: pre-wrap;
      margin: 0 0 0 auto;
    }
    .myBtn--link {
      // color: $text--dark-1;
      svg {
        margin-right: 4px;
      }
      &:hover {
        color: $new-primary--light;
      }
    }
    &.is-company {
      border-top: none;
      padding: 4px 8px 4px 0;
      position: relative;
      text-align: left;
    }
  }
  &__documents {
    grid-column: 5;
    grid-row: 1 / 3;
    padding-right: 0;
    position: relative;
    cursor: pointer;
    h4 {
      position: relative;
      margin-bottom: 8px;
      .myBtn--link {
        text-decoration: none;
        color: $new-link;
        padding: 0 2px 2px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    p {
      @include text--body2;
      font-weight: 500;
      position: relative;
    }
    .savvi-tooltip {
      text-align: left;
      bottom: 40px;
      top: unset;
      h4 {
        margin-bottom: 4px;
        color: $white;
        border-bottom: $border;
      }
      p {
        @include text--body1;
        position: relative;
        margin-left: 20px;
        color: $white;
      }
    }
    // &:hover {
    //   h4,
    //   .reportingTable__documentsSearch {
    //     color: $link;
    //   }
    // }
    &.is-company {
      .reportingTable__documentLabel {
        margin-left: 0;
      }
    }
  }
  .reportingTable__documentLabel {
    position: relative;
    margin: 0 0 0 auto;
    width: fit-content;
    span {
      margin-right: 2px;
    }
    // .FilePreviewer__wrapper {
    //   text-align: end;
    // }

    // .FilePreviewer__wrapper {
    //   min-width: 186px;
    //   min-height: 158px;
    //   background-color: #f5f5f5;
    //   .bp-doc.bp-is-scrollable {
    //     overflow-x: hidden;
    //   }
    // }
    // .FilePreviewer__action {
    //   color: $new-link;
    //   text-decoration: none;
    //   text-align: end;
    //   &:hover {
    //     text-decoration: underline;
    //     color: $new-link--light;
    //   }
    // }
    p {
      color: $text--dark-1;
    }
  }
  // .reportingTable__documentCheck {
  //   position: absolute;
  //   left: -16px;
  //   font-size: 10px;
  //   margin-top: 2px;
  // }
  // .reportingTable__documentRequired {
  //   color: $text--dark-1;
  //   position: absolute;
  //   left: -14px;
  //   margin-top: 2px;
  // }
  &__tooltipItem {
    svg {
      position: absolute;
      left: -16px;
      margin-top: 2px;
      color: $link--light;
    }
    &--empty svg {
      color: $red--light;
    }
  }
}
