@import 'color/colors';

.login__container {
  width: 400px;
  margin: 60px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 435px;
  .savvi-loading {
    margin: auto;
  }
  @media (max-width: 876px) {
    margin-top: 40px;
    padding: 0 4px;
  }
}

.login__loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: 4px;
  margin-top: -18px;
  margin-left: -26px;
  background-color: $white;
  z-index: 4;
}

.login__logoWrapper {
  margin-bottom: 20px;
  text-align: center;
  text-align: center;
}
.login__logo {
  max-width: 320px;
  min-width: 220px;
  max-height: 120px;
  transition: 0.05s all linear;
  cursor: pointer;
  &--savvi {
    max-width: 280px;
  }
  &--fundLaunch {
    max-width: 350px;
  }
}

.login__formWrapper {
  width: 100%;
  max-width: 400px;
  min-height: 220px;
  position: relative;
  border-radius: 4px;
  box-shadow: $shadow__table;
  background-color: $white;
  padding: 18px 26px 26px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login__form {
  width: 100%;
  position: relative;
  // min-height: 200px;
  .styled-text-field__container {
    min-height: 76px;
  }
  &--hide-email {
    .styled-text-field__container {
      min-height: unset;
    }
  }
  .input-password__wrapper {
    width: 346px;
    height: 52px;
    z-index: 3;
    &--hidden {
      z-index: -1;
      margin-top: -68px;
    }
  }
  .styled-text-field__wrapper {
    width: 346px;
    height: 52px;
    &--hidden {
      margin-top: 16px;
      z-index: -1;
    }
  }
  &--hidden {
    margin: 0;
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
  }
}

.login__heading {
  @include text--h1;
  margin: 0 0 16px;
  text-align: center;
  line-height: 18px;
  small {
    line-height: 20px;
  }
  span {
    @include text--h4;
    line-height: 12px;
    svg {
      color: $low;
      margin-left: 4px;
      display: inline-block;
    }
  }
}

.login__error {
  background-color: $red--light-3;
  color: $red--dark;
  padding: 8px 20px;
  max-width: 100%;
  margin: 0 0 20px;
  border-radius: 8px;
}

.login__sub-heading {
  display: block;
  margin-top: 12px;
  margin-bottom: 4px;
  color: $dark-85;
  font-size: 16px;
  svg {
    color: $low;
    margin-right: 4px;
  }
}

.login__linksWrapper {
  display: flex;
  width: 100%;
  margin: 16px 0 0;
  font-size: 12px;
  .myBtn--link {
    font-size: 12px;
    margin-top: -2px;
  }
  h4 {
    @include text--h4;
    font-size: 12px;
    margin-bottom: 4px;
    margin-right: 8px;
    width: fit-content;
  }
  &--center {
    justify-content: center;
    .myBtn--link {
      font-size: 14px;
    }
  }
}

.login__action {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .savvi-tooltip__toggle {
    margin-bottom: 2px;
  }
  .myBtn--primary,
  .myBtn--secondary {
    width: 348px;
    margin-bottom: 2px;
    margin-top: 20px;
    img {
      padding-right: 2px;
      margin-right: 6px;
      width: 30px;
    }
  }
  &--fl360 img {
    width: 40px;
  }
}

.login__h3 {
  @include text--h3;
  color: $dark-85;
  font-size: 16px;
  text-align: center;
  margin: 12px 0 0;
}

.login__emailLink {
  cursor: pointer;
  margin-top: 8px;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  b {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:hover {
    text-decoration: underline $dark-75;
    .login__link {
      color: $link--light;
    }
  }
}
.login__fl360-subheading svg {
  margin-right: 4px;
}

.login__link {
  color: $link;
  position: relative;
  margin-left: 4px;
  cursor: pointer;
  svg {
    font-size: 20px;
  }
}

.login__text {
  text-align: center;

  margin: 16px 0;
  p {
    @include text--body1;
    margin-bottom: 12px;
  }
  .myBtn--link {
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
