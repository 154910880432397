@import 'color/colors';

.DocumentPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bg--page;
  &.no-thumbnail {
    background-color: inherit;
  }
  h4 {
    padding-top: 8px;
    color: $link;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $link--dark;
    }
  }
  &__page {
    max-width: 580px;
    min-width: 300px;
    margin: 8px 0 0;
    border: thin solid $border;
  }
  &__thumbnail {
    width: 300px;
    height: 365px;
    padding: 12px;
    overflow-y: auto;
    @media (max-width: 1280px) {
      width: 280px;
      height: 340px;
    }
    &Page {
      width: 100%;
    }
  }
}
