@import 'color/colors';

.addResourceModal {
  position: relative;
  max-width: 90%;
  min-height: 260px;
  min-width: 540px;
  width: 920px;
  padding: 20px 0 0;
  background-color: $bg--page;
  outline: none;
  margin: auto;
  border: none;
  border-radius: 4px;
  box-shadow: 3px 3px 3px 3px #33373859;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    padding: 20px 0;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling-: 'touch';
  }
  &__top {
    padding: 0 40px 20px;
    position: relative;
    h1 {
      @include text--h1;
      margin: 0;
      display: flex;
      align-items: center;
      span {
        color: $red;
      }
      .myBtn--icon {
        margin-left: 8px;
      }
    }
  }
  &__exit {
    position: absolute;
    align-self: flex-end;
    top: 0;
    right: 40px;
    font-size: 20px;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &__subheader {
    @include text--h4;
    margin: 4px 0;
    span {
      color: $red;
    }
    .myBtn {
      color: $text--dark-1;
      font-size: inherit;
      &:hover {
        color: $new-primary--light;
      }
    }
  }
  &__warning {
    color: $red--light;
    margin-right: 4px;
  }
  &__info {
    color: $mint;
    margin-right: 4px;
  }

  &__addActions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: $white;
    padding: 80px 40px 100px;
    gap: 40px;
    position: relative;
  }
  &__addAction {
    width: 350px;
    height: 300px;
    display: flex;
    justify-content: center;
    border: thin solid $border;
    border-radius: 8px;
    padding: 90px 40px 20px;
    cursor: pointer;
    &Text {
      display: flex;
      flex-direction: column;
      span {
        color: $red--light;
      }
    }
    svg {
      max-width: 80px;
      margin-top: 16px;
      width: 100%;
      height: 80px;
      margin-right: 12px;
    }
    h2 {
      @include text--h2;
      color: $text--dark-2;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 12px;
    }
    h4 {
      @include text--h4;
    }
    &:hover {
      border-color: $link;
    }
  }
  &__addActionSublabel {
    @include text--h4;
    margin: 4px 0;
    span {
      color: $red;
    }
  }
  form {
    flex: 1;
    .workbench-form__group {
      text-align: left;
    }
    .address-group__label {
      display: none;
    }
    .address-group__state {
      flex: 3.8;
    }
  }
  &__formHeaderWrapper {
    background-color: $white;
    width: 100%;
    padding-right: 40px;
  }
  &__formHeader {
    height: 80px;
    width: 100%;
    // width: fit-content;
    padding: 0 0 0 68px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    h2 {
      margin-top: 20px;
      @include text--h2;
    }
    h4 {
      @include text--h4;
      span {
        color: $red--light;
      }
    }
    &:hover .addResourceModal__formHeaderIcon {
      color: $link;
      transform: rotate(-90deg);
    }
    &--collapsed {
      .addResourceModal__formHeaderIcon {
        transform: rotate(-90deg);
      }
      &:hover .addResourceModal__formHeaderIcon {
        transform: rotate(0);
      }
    }
  }
  &__formHeaderIcon {
    transition: all 0.1s ease-in-out;
    position: absolute;
    left: 40px;
  }
  &__formContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: $white;
    padding: 0 40px 0 68px;
    grid-column-gap: 40px;
    height: 0;
    overflow: hidden;
    &--visible {
      height: unset;
      overflow: visible;
    }
    &--borderBottom {
      border-bottom: thin solid $border;
      padding-bottom: 20px;
    }
    .select-resource {
      align-self: flex-start;
      .styled-text-field__container {
        margin: 16px 0 0 0;
      }
      .select-resource__dropdown {
        top: 16px;
      }
    }
    .form-file-uploader__container {
      margin: 0 0 16px;
    }
    .file-uploader-content-preview {
      height: 390px;
      &.file-uploader-content-preview--error {
        height: unset;
      }
    }
    .styled-checkbox {
      margin: 26px 0 15px;
    }
    @media (max-width: 876px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  h6 {
    margin: 5px 0 0;
    font-size: 0.75em;
  }

  .address-group__city {
    flex: 3.6;
  }

  .address-group__zip {
    flex: 3;
  }

  &__links {
    margin: 12px 40px 12px 40px;
    width: fit-content;
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 40px;
    margin: 20px 40px 20px;
    @media (max-width: 768px) {
      margin: 0 5px 5px;
    }
  }
  &__buttonCol {
    max-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .myBtn--link {
      margin-top: 8px;
    }
  }
  @media (max-width: 576px) {
    min-width: 360px;
    &__top {
      padding: 0 16px 20px;
    }
    &__formHeader {
      padding-left: 38px;
      &Icon {
        left: 16px;
      }
    }
    &__formContent {
      padding: 0 16px 0 20px;
    }
  }
}
