@import 'color/colors';

.companyDropdown {
  align-items: center;
  background-color: inherit;
  border-left: 5px solid transparent;
  display: flex;
  height: 100%;
  padding-left: 36px;
  transition: 0.2s;
  width: 100%;

  .savvi-tooltip {
    left: 16px;
    bottom: 40px;
  }
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      .companyDropdown__icon {
        color: $new-primary;
        // border-color: $new-primary;
      }
      .companyDropdown__chevron {
        color: $new-primary;
        // border-color: $new-primary;
      }
      .companyDropdown__text {
        color: $new-primary;
      }
      svg {
        color: $new-primary;
        font-weight: bolder;
      }
    }
    &:focus {
      svg {
        color: $new-primary;
        border-color: $new-primary;
      }
    }
    .savvi-tooltip {
      left: 105px;
      max-width: 200px;
      top: 90%;
    }
  }
  &__chevron {
    background-color: inherit;
    color: $text--dark-1;
    display: flex;
    margin-left: 4px;
    text-decoration: underline;
    font-size: 12px;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    color: $text--dark-1;
    font-size: 12px;
  }
  &__text {
    position: relative;
    color: $text--dark-1;
    font-size: 12px;
    font-weight: 700;
    margin-left: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 280px;
    @media (max-width: 1400px) {
      max-width: 240px;
    }
    @media (max-width: 1280px) {
      max-width: 200px;
    }
    @media (max-width: 876px) {
      max-width: 100px;
    }
    @media (max-width: 486px) {
      display: none;
    }
  }

  &__menu {
    width: 240px;
    max-height: 400px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 20%);
    padding: 0 20px;
    border-radius: 4px;
    overflow-y: auto;
    /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
    &::-webkit-scrollbar-track {
      visibility: hidden;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
    &:hover {
      &::-webkit-scrollbar-track {
        visibility: visible;
      }
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
    /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/

    &Arrow {
      position: fixed;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent $white transparent;
      top: 100px;
      left: 57px;
    }
    &Item {
      display: flex;
      align-items: center;
      &.first {
        position: sticky;
        top: 0;
        z-index: 5;
        // min-height: 38px;
        // line-height: 38px;
        font-size: 12px;
        color: $new-primary;
        background-color: $white;
        border-bottom: thin solid $border--light-1;
        padding-top: 12px;
        svg {
          margin-right: 2px;
        }
        &:hover {
          color: $new-primary--light;
        }
      }
      &.second {
        position: sticky;
        z-index: 5;
        top: 29px;
        // min-height: 32px;
        // line-height: 32px;
        color: $text--dark;
        background-color: $white;
        border-bottom: thin solid $border;
        svg {
          margin-right: 2px;
        }
        &:hover {
          color: $new-primary--light;
        }
      }
      &:hover {
        color: $new-primary;
        text-decoration: underline;
      }
    }

    // &.collapsed {
    //   left: 12px;
    //   .companyDropdown__companyMenuArrow {
    //     left: 30px;
    //   }
    // }
    @media (max-width: 576px) {
      left: 16px;
    }
  }
}
