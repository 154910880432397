@import 'navigation/navigation';
@import 'color/colors';

.sort-dropdown {
  .dropdown__wrapper {
    .myBtn svg {
      padding-left: 4px;
    }
    .myBtn--link {
      color: $text--dark-1;
      font-size: 12px;
      font-weight: 400;
      text-decoration: none;
      white-space: break-spaces;
      span {
        font-weight: 600;
      }
      svg {
        padding-left: 4px;
        margin-top: 2px;
      }
      &:hover {
        color: $link;
        text-decoration: underline;
      }
      &:active {
        color: $link--dark;
      }
      &:disabled {
        color: $light-110;
        text-decoration: none;
      }
    }
    .dropdown {
      top: 30px;
      padding: 16px 30px;
      min-width: 260px;
      max-width: 380px;
      border-radius: 16px;
      width: fit-content;
      .myBtn--secondary {
        font-size: 14px;
        color: $text--dark-1;
        border-bottom: none;
        white-space: nowrap;
        justify-content: flex-start;
        svg {
          margin-right: 8px;
          width: 18px;
        }
      }
    }
  }
  &__option {
    &#{&}--active {
      font-weight: 700;
      color: $text--dark-2;
      svg {
        color: $text--dark-2;
      }
    }
  }
}
