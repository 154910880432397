@import 'color/colors';

.checkbox-group {
  &__container {
    flex: 1;
    position: relative;
    width: 100%;
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    &--error {
      border-color: $red;
      .styled-text-field__label {
        color: $red;
      }
    }
  }
  &__label {
    @include text--label;
    margin-bottom: 5px;
    margin-left: 0;
    position: relative;
  }

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .styled-checkbox {
    margin: 0;
  }
}
