@import 'color/colors';

.prereq-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 550px;
  padding: 20px;
  @media (max-width: 825px) {
    width: 80vw;
    padding: 10px;
  }
  @media (max-width: 576px) {
    width: 95vw;
  }
}

.prereq__card {
  display: flex;
  flex-direction: column;

  h2 {
    color: $dark-75;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 32px;
  }

  h4 {
    margin: 0;
    margin-top: 10px;
    color: $dark-75;
    button {
      color: $blue;
      font-size: inherit;
      outline: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      transition: 0.05s all linear;
      &:hover {
        color: $blue--light;
      }
      &:focus {
        color: $blue--dark;
      }
    }
  }

  p {
    color: $red;
    line-height: 24px;
    margin: 0;
    margin-bottom: 5px;
  }
}

.prereq__card__bold {
  font-weight: bold;
}

.prereq-modal__list {
  margin: 0;
  padding: 0;
  h4 {
    margin: 0;
    margin-top: 10px;
  }
  margin-bottom: 15px;
}

.prereq-modal__sublist {
  margin: 0;
  padding: 0 0 0 15px;
  h4 {
    margin: 0;
    margin-bottom: 5px;
  }
  // p {
  //   margin: 0;
  // }
  margin-bottom: 5px;
}

.prereq__prereqs {
  margin-top: 25px;
  flex: 1;
  color: $red;
  p {
    color: $red;
  }
}

.prereq-modal--buttons {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.prereq-modal--button {
  width: 540px;
  text-align: center;
  vertical-align: middle;
}

.prereq-modal-fees__details {
  color: $dark-50;
  font-size: 12px;
  display: block;
  margin: 0;
}
