@import 'color/colors';
@import 'navigation/navigation';

.workbenchDocStatus {
  margin: 4px 8px 12px 0;

  display: flex;
  flex-direction: column;

  &__empty {
    margin-left: 5px;
    color: $dark-75;
    margin-top: 5px;
    margin-bottom: 8px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    margin-right: 5px;
    margin-top: 4px;
    width: 100%;
    &-header {
      width: 100%;
      margin: 0 auto;
      h3 {
        color: $dark-80;
        border-bottom: thin solid $dark-50;
        font-weight: 400;
        margin: 0 0 10px;
        width: fit-content;
        padding: 0 15px 3px 0;
        align-self: center;
      }
    }
    /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
    &::-webkit-scrollbar-track {
      visibility: hidden;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
    &:hover {
      &::-webkit-scrollbar-track {
        visibility: visible;
      }
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
    /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
  }
  &__card {
    display: flex;
    margin-bottom: 5px;
  }
  &__warning-icon {
    color: $mint;
    margin-right: 4px;
  }
  &__pre {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $mint;
    margin-right: 10px;
    margin-top: 5px;
    width: 20px;
  }
  &__body {
    flex: 1;
    form {
      margin-bottom: 20px;
    }
    h4 {
      @include text--h4;
      margin-bottom: 4px;
      margin-top: 12px;
    }
    p {
      @include text--body2;
      svg {
        margin-right: 3px;
      }
    }
    .myBtn {
      max-width: 200px;
      margin-top: 4px;
    }
    .myBtn--primary {
      width: 180px;
    }
    @media (max-width: 1480px) {
      width: 144px;
    }
  }
  &__help {
    sup {
      color: $link;
      margin-left: 2px;
      &:hover {
        color: $link--dark;
        cursor: pointer;
      }
    }
    .dd-menu-items {
      margin: 0 0 0 10px;
      .dd-items-left {
        min-width: 275px;
        padding: 5px 5px 5px 10px;
        li {
          margin: 5px 0;
          font-size: 15px;
          font-weight: 500;
          color: $dark-60;
          display: flex;
        }
        p a {
          margin: 0;
          padding: 0;
          min-width: 0;
          color: $link;
          &:hover {
            color: $link--dark;
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__status {
    @include text--body2;
    margin-top: 4px;
    margin-bottom: 4px;
    .myBtn--link {
      margin: 0;
      padding: 2px 0;
      align-items: center;
      svg {
        margin-left: 4px;
      }
    }
    span {
      color: $warning;
    }
  }
  &__warning {
    color: $warning;
  }
  &__sub {
    @include text--body2;
    font-size: 10px;
    margin-top: -2px;
    line-height: 14px;
    margin-bottom: 8px;
    span {
      color: $warning;
    }
  }
  &__redlineAction {
    position: relative;
  }
  &__redlineDisable {
    position: absolute;
    top: 2px;
    right: 2px;
  }
  &__alert {
    margin-right: 2px;
    color: $med;
  }

  &__subheader {
    @include text--h4;
    margin-bottom: 8px;
  }
  &__reviewer-action {
    color: $blue;
    border: none;
    outline: none;
    background: transparent;
    text-decoration: underline;
    font-size: 0.9em;
    cursor: pointer;
    transition: 0.05s all linear;
    &:hover {
      color: $blue--light;
    }
    &:active {
      color: $blue--dark;
      font-weight: bold;
    }
  }

  &__suggestions {
    margin-bottom: 20px;
    &Header {
      @include text--h4;
      margin-top: 12px;
      position: relative;
      cursor: pointer;
      &:hover {
        .workbenchDocStatus__suggestionsInfo {
          color: $link--light;
        }
      }
    }
    &Alert {
      color: $low;
      position: absolute;
      left: -14px;
      bottom: 1px;
    }
    &Info {
      color: $link;
      margin-left: 2px;
      position: relative;
      top: -4px;
      font-size: 10px;
    }
  }
  &__suggestion {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    margin-top: 8px;
    width: 100%;
    &Col {
      display: flex;
      flex-direction: column;
      margin-right: 12px;
      h3 {
        display: flex;
        align-items: center;
        @include text--body2;
        span {
          margin-left: 4px;
        }
        .myBtn--xs {
          width: fit-content;
          margin: 0;
          padding: 0;
          color: $text--dark-1;
          // svg {
          //   color: $link;
          // }
          &:hover {
            color: $link--light;
          }
        }
      }
      h4 {
        @include text--h4;
      }
      // align-items: flex-end;

      align-items: flex-start;
      // &--first {
      // flex: 2.5;
      // }
    }
    &Row {
      display: flex;
      width: 100%;
      align-items: center;
      h4 {
        word-wrap: break-word;
        word-break: break-word;
      }
    }
    &Divider {
      height: 100%;
      width: 1px;
      background-color: $border;
      margin: 0 8px;
    }

    .savvi-tooltip__wrapper {
      svg {
        margin-left: 2px;
        font-size: 10px;
      }
      .myBtn--link {
        padding-left: 0;
      }
    }
    .savvi-tooltip {
      border-radius: 4px;
      border: 1px solid #e8e8e8;
      background-color: $white;
      box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
      max-width: 380px;
      padding-top: 0;
      h3 {
        @include text--h3;
        margin-top: 16px;
        text-align: left;
      }
      h4 {
        font-size: 10px;
      }
    }
    &Items {
      display: flex;
    }
    p {
      display: flex;
      align-items: center;
      margin-right: 4px;
      span {
        @include text--h4;
      }
    }
  }
  &__actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    .myBtn {
      margin-right: 20px;
      min-width: 144px;
      padding: 4px 12px;
      svg {
        margin-right: 4px;
      }
    }
    @media (max-width: 820px) {
      .myBtn {
        width: 150px;
      }
    }
  }
  &__comments {
    color: $text--dark-1;
    padding-left: 4px;
    cursor: pointer;
    position: relative;
    text-decoration: underline;
    .savvi-tooltip {
      width: 320px;
      padding-bottom: 16px;
    }
    &:hover {
      color: $link--light;
    }
  }
  &__comment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
    margin-left: 2px;
    &Col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &Subheader {
      display: flex;
      align-items: center;
      margin-top: 16px;
      p {
        @include text--body-light;
        font-size: 12px;
      }
    }
    &Separator {
      height: 1px;
      flex: 1;
      margin-top: 1px;
      background-color: $border;
    }
  }

  &__redline {
    &-row {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        @include text--h4;
      }
    }
    &-ver {
      display: flex;
      flex-direction: column;
      .styled-checkbox {
        margin-bottom: 0;
        margin-top: 4px;
        label {
          padding-left: 8px;
          @include text--body2;
        }
      }
      p {
        img {
          width: 350px;
        }
      }
    }
  }

  &__recipient {
    display: flex;
    @include text--body2;
    margin: 4px 0 8px;
    white-space: pre;

    svg {
      font-size: 12px;
      min-width: 18px;
      transition: 0.3s;
    }
    &Label {
      width: 160px;
      display: flex;
      flex-direction: column;
      span {
        @include text--h4;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      svg {
        margin-right: 2px;
      }
    }
    &Status {
      @include text--body2;
      position: relative;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
      p {
        @include text--body2;
        margin-right: 8px;
        display: flex;
        align-items: center;
        text-decoration: underline;
        width: 120px;
        justify-content: stretch;
        span {
          flex: 1;
        }
        svg {
          font-size: 12px;
          min-width: 18px;
          transition: 0.3s;
          flex: 0.2;
        }
      }
      &:hover {
        p {
          color: $link--light;
        }
        .workbenchDocStatus__recipientChevron {
          font-size: 12px;
        }
      }
      .dropdown {
        width: 140px;
      }
    }
    &Warning {
      color: $orange;
      font-size: 14px;
    }
    &s {
      grid-column-start: 1;
      grid-column-end: 5;
      background-color: inherit;
      padding-left: 60px;
      padding-bottom: 4px;
      margin: 0;
      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 400;
      }
    }
    &Chevron {
      width: 22px;
      svg {
        display: none;
      }
    }
  }

  &__preview {
    display: flex;
    align-items: flex-end;
    min-height: 160px;
    max-height: 100%;
    min-width: 320px;
    max-width: 400px;
    flex: 1;
  }
}
