@import 'color/colors';

.share-links {
  &__container {
    width: 100%;
    max-width: 600px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    &--error {
      .address-group__label {
        color: $red;
      }
    }
  }
  &__label {
    @include text--label;
    position: relative;
  }
  &__link {
    h4 {
      @include text--sub;
      text-align: left;
      margin: 8px 0 0;
    }
  }
  &__input--first {
    margin-right: 16px;
  }
  &__add {
    @include text--h4;
    align-self: flex-start;
  }

  &__link-label {
    display: flex;
    .styled-text-field__container {
      margin-top: 4px;
    }
    p {
      @include text--body2;
      margin-right: 8px;
      line-height: 40px;
      a {
        color: $text--dark-1;
        text-decoration: underline;
        &:hover {
          color: $blue;
        }
      }
    }
    .myBtn {
      align-items: center;
    }
    .myBtn--link {
      height: 32px;
      margin-right: 20px;
    }
  }
  &__url {
    min-width: 340px;
    @media (max-width: 1200px) {
      min-width: 280px;
    }
  }
  &__actions {
    position: relative;
    margin-left: -2px;
    margin-top: 4px;
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .dropdown {
      width: 184px;
    }
  }
  &__modal {
    position: relative;
    max-width: 90%;
    min-height: 400px;
    min-width: 600px;
    padding: 20px 40px 20px;
    outline: none;
    margin: auto;
    border: none;
    border-radius: 16px;
    box-shadow: 3px 3px 3px 3px #33373859;
    display: flex;
    flex-direction: column;
    background-color: $white;
    h4 {
      @include text--h4;
      text-align: center;
      margin: 0 auto 8px;
    }
    &Overlay {
      z-index: 9;
      background-color: rgba(0, 0, 0, 0.66);
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      display: flex;
      overflow-y: auto;
      -webkit-overflow-scrolling-: 'touch';
    }
    &Header {
      @include text--h2;
      text-align: center;
      margin: 0 auto 8px;
    }
    &Text {
      @include text--body1;
      text-align: end;
      span {
        font-weight: 600;
        color: $text--dark;
      }
    }
    &Exit {
      position: absolute;
      align-self: flex-end;
      right: 16px;
      top: 8px;
      font-size: 1.25em;
      color: $primary;
      cursor: pointer;
      &:hover {
        color: $primary--light;
      }
      &:active {
        color: $primary--dark;
      }
      &:focus {
        color: $primary--dark;
      }
    }
    &Actions {
      margin-top: 20px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
