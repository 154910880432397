@import 'color/colors';

.reviewers-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  margin: 0;
  padding: 20px;
  flex: 1;
  text-align: center;
  .finalize-v2__section-header {
    text-align: start;
    margin: 8px;
    h4 {
      margin: 5px;
    }
    p {
      margin: 5px;
    }
  }
  &__exit {
    position: absolute;
    align-self: flex-end;
    margin-top: -3px;
    font-size: 1.25em;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  h2 {
    margin: 0;
    span {
      color: $mint;
    }
    svg {
      color: $mint--light;
    }
  }
  &__form {
    margin-top: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__body {
    flex: 1;
    p {
      margin-bottom: 0;
    }
    h4 {
      margin: 5px 0;
    }
    .workbench-form__group {
      max-width: 350px;
      margin: 0 auto;
    }
  }
  &__action {
    border: none;
    outline: none;
    background-color: $white;
    color: $blue;
    cursor: pointer;
    &:hover {
      color: $blue--dark;
    }
  }

  &__alert {
    color: $red;
  }

  &__buttons {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin: 10px 15px 15px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    flex-direction: row;
    @media (max-width: 768px) {
      margin: 5px;
    }
  }
}
