@import 'color/colors';
@import 'navigation/navigation';

.comment__container {
  display: block;
  box-sizing: border-box;
  margin: 12px 0;
  p {
    @include text--body2;
    strong {
      @include text--h4;
    }
  }
  ul {
    margin: 8px 0;
    padding-left: 16px;
  }
  ol {
    margin: 8px 0;
    padding-left: 14px;
  }
  li {
    @include text--body1;
    strong {
      font-weight: 500;
    }
    margin: 4px 0;
  }
  h1 {
    @include text--h2;
    margin: 4px 0;
  }
}

.comment__label {
  @include text--label;
  color: $text--dark;
  font-weight: 400;
  position: relative;
}
