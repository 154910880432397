// font-weight: regular && normal --> 400
// font-weight: medium --> 500
// font-weight: semi bold --> 600

/// Slightly mix lighten a color
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

/** ***THEME HANDLING*** **/
$medDark: #60696f;

$theme-light: var(--primary-light);
$theme-light--lighter: hsl(
  var(--primary-light-h),
  var(--primary-light-s),
  calc(var(--primary-light-l) / 0.9)
);
$theme-light--darker: hsl(
  var(--primary-light-h),
  var(--primary-light-s),
  calc(var(--primary-light-l) * 0.9)
);
$theme-dark: var(#{--primary-dark});
$theme-button: var(--button-color);
$theme-button--light: hsl(
  var(--button-color-h),
  var(--button-color-s),
  calc(var(--button-color-l) / 0.9)
);
$theme-button--dark: hsl(
  var(--button-color-h),
  var(--button-color-s),
  calc(var(--button-color-l) * 0.9)
);
$theme-text: var(--text);
$theme-text-10: hsla(var(--text-hsl), 0.1);
$theme-text-20: hsla(var(--text-hsl), 0.2);
$theme-text-30: hsla(var(--text-hsl), 0.3);
$theme-text-40: hsla(var(--text-hsl), 0.4);
$theme-text-50: hsla(var(--text-hsl), 0.5);
$theme-text-60: hsla(var(--text-hsl), 0.6);
$theme-text-70: hsla(var(--text-hsl), 0.7);
$theme-text-80: hsla(var(--text-hsl), 0.8);
$theme-text-90: hsla(var(--text-hsl), 0.9);
$theme-filter: var(--primary-filter);

/** ***END OF THEME*** **/

$text--dark: #0d2238;
$text--dark-2: #2d363c;
$text--dark-1: #60696f;
$text--dark-disabled: #8997a0ad;
// $text--dark-disabled: hsla(203, 11%, 58%, 0.678);
$text--sub: #8a8f92;
$text--light-1: #85949b;
$text--placeholder: #b7b6b6;
$text--light: #afbdc5;
$text--light-light: tint(#afbdc5, 20%);

$border--dark: transparentize(#858d92, 0.5);
$border--light: #e8eef1;
$border--light-1: transparentize(#f8f8f8, 0.8);
$border--button: #2d363c;
$border--button-2: #b1b4b6; // TODO: test if this color works everywhere
$border--disabled: #e6e6e6;

$doc--bg: #f5f5f5;

$bg--nav: #2d363c;
$bg--hover: #afbdc5;
$bg--page: #fcfcfc;
// $bg--page: #f7f7f7;
$bg--table: #ffffff;
$new-primary: #0da1ff;
$new-primary--light: tint(#0da1ff, 20%);
$new-primary--dark: shade(#0da1ff, 20%);
$select--hover: #deebff;
$select--focus: #2684ff;

$text__label: #777777;
$text__label--disabled: #8e8e8ed0;

@mixin text--h1 {
  color: $text--dark;
  font-weight: bold;
  font-size: 22px;
  margin: 16px 0;
  // span {
  //   font-weight: 400;
  // }
}
@mixin text--h2 {
  color: $text--dark;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}
@mixin text--h3 {
  color: $text--dark;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
}
@mixin text--h4 {
  color: $text--dark-1;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}
@mixin text--label {
  color: $text__label;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  margin: 4px 0 4px 8px;
}
@mixin text--input-label {
  color: $text--light;
  font-weight: 400;
  border-radius: 4px;
  font-size: 16px;
  margin: 0;
}
@mixin text--input {
  color: $text--dark-2;
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  margin: 0;
}
@mixin text--sub {
  color: $text--sub;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}
@mixin text--body1 {
  color: $text--dark-2;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
@mixin text--body2 {
  color: $text--dark;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
@mixin text--body-light {
  color: $text--dark-1;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

//mixins for pricing modal
@mixin plans__h1 {
  color: $text--dark;
  font-size: 28px;
  font-weight: bold;
  margin: 0;
}
@mixin plans__h2 {
  color: $text--dark;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
@mixin plans__h2--light {
  color: $text--dark;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
@mixin plans__h3 {
  color: $text--dark-2;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}
@mixin plans__p1 {
  color: $text--dark-1;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
@mixin plans__p2 {
  color: $text--dark-1;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
@mixin border__subtle {
  border: thin solid #e5e7eb;
  border-radius: 12px;
  border-width: 1px;
}

$new-link: #3ba8ed;
$new-link--light: tint(#3ba8ed, 20%);
$new-link--dark: shade(#3ba8ed, 20%);

$low: #86dbc1;
$low--light: tint(#86dbc1, 20%);
$low--dark: shade(#86dbc1, 20%);
// $med: #c2b946;
// $med--light: tint(#c2b946, 20%);
// $med--dark: shade(#c2b946, 20%);
$med: #ffde6f;
$med--light: tint(#ffde6f, 20%);
$med--dark: tint(#ffde6f, 20%);
$warning: #db6949;
$warning--dark: shade(#db6949, 20%);
$warning--light: tint(#db6949, 20%);
$bg--status: #60696f;
$bg--status-light: #a7aaac;

// Layout
$dark: #02080d;
$dark-85: #282d31;
$dark-80: #393a3d;
$dark-75: #4e5256;
$dark-60: #676b6e;
$dark-50: #818486;

$light-110: #bdc8cd;
$light: #dae0e3;
$light-95: #e9ecee;
$light-90: #f7f8f9;

$shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
$shadow--dark: 0 4px 10px 0 transparentize(#000000, 0.2);
$shadow__table: 0 2px 20px 0 transparentize($text--dark, 0.93);

$white: #fff;

$border: #dbdee0;
$border--active: #2684ff;

$tooltip: rgba(70, 70, 70, 0.8);
$hover: #eaf4f9;
$transparentHover: rgba(234, 244, 249, 0.8);

// Core
// $orange: #E55920;  // new
$orange: #db6949; // old
$orange--dark: shade($orange, 20%);
$orange--light: tint($orange, 20%);
$orange--light-1: tint($orange, 30%);

$yellow: $med;
$yellow--light: #e6cd73;
$yellow--light-1: #ecd993;
$yellow--light-2: #f1e3b1;
$yellow--light-3: #f6edcd;
$yellow--dark: #b2993e;
$yellow--dark-1: #6a5c25;

$red: #e04f4f;
$red--light: #e98282;
$red--light-1: #efa4a4;
$red--warning: #8800331a;
$red--light-2: #f3c0c0;
$red--light-3: #f8d7d7;
$red--dark: #b13e3e;
$red--dark-1: #682424;

$dark-cerulean: #0c6987;
$dark-cerulean--light: #13a3d4; // iris blue
$dark-cerulean--light-1: #0d7294;
$dark-cerulean--dark: #063747;
$dark-cerulean--dark-1: #0a556e;

$cerulean: #0771b3;
$cerulean--light-1: #0879bf; // iris blue

$powder-blue: #bde3e5;
$powder-blue--light: #d4f6f8;
$powder-blue--background: #f6f7ff;

// $mint: #29d3c5;
$mint: #86dbc1;
$mint--light: tint(#86dbc1, 10%);
// $mint--light: #81f3ea;
$mint--light-background: #cff5f3ea;
$mint--light-background-1: #cff5f394;
$mint--dark: #00b9aa;
$notice--text: #59a59f;

$link: $new-primary;
$link--light: $new-primary--light;
$link--dark: $new-primary--dark;
$link__light-bg: #1c7dc1;
$link__light-bg--lighten: tint($link__light-bg, 20%);

$blue: #21a0ff;
$blue--light: #35a8ff;
$blue--light-1: #5abaff;
$blue--light-2: #85ccff;
$blue--light-3: #aadbff;
$blue--dark: #1c83d1;
$blue--dark-1: #061e2f;

$green: #128c85;
$green--light: #52aba5;
$green--light-1: #81c2bd;
$green--light-2: #a7d4d1;
$green--light-3: #c8e4e2;
$green--dark: #0e6e68;
$green--dark-1: #08403c;

// $workbench--background: #f3f9ff;
$workbench--background: $bg--page;

$modal--background: #f3f9ffc2;

$primary: $dark-cerulean;
$primary--light: $dark-cerulean--light;
$primary--light-1: tint($dark-cerulean--light, 20%);
$primary--dark: $dark-cerulean--dark;
$primary--dark-1: $dark-cerulean--dark-1;
