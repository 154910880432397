@import 'color/colors';

.myBtn {
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: 0.05s all linear;
  &:hover {
    .myBtn__tooltip {
      visibility: visible;
    }
  }
  &:focus {
    outline: none;
  }

  &--xs {
    font-size: 12px;
    min-width: 80px;
    height: 24px;
    &.myBtn--icon {
      height: 28px;
      width: 28px;
      min-width: 28px;
      svg {
        font-size: 12px;
      }
    }
  }

  &--sm {
    font-size: 14px;
    min-width: 100px;
    height: 32px;
    &.myBtn--icon {
      width: 32px;
      min-width: 32px;
      svg {
        font-size: 16px;
      }
    }
  }

  &--md {
    font-size: 16px;
    min-width: 180px;
    height: 50px;
    &.myBtn--icon {
      width: 50px;
      min-width: 50px;
      svg {
        font-size: 18px;
      }
    }
  }

  &--wide {
    font-size: 18px;
    min-width: 200px;
    height: 50px;
    &.myBtn--icon {
      width: 50px;
      min-width: 50px;
      svg {
        font-size: 22px;
      }
    }
  }

  &--lg {
    font-size: 20px;
    min-width: 200px;
    height: 52px;
    &.myBtn--icon {
      width: 52px;
      min-width: 52px;
      svg {
        font-size: 22px;
      }
    }
  }

  &--primary {
    color: $white;
    background-color: $theme-button;
    border: thin solid $theme-button--dark;
    // border: thin solid darken(#{$new-primary}, 5%);
    box-shadow: -2px 6px 8px -2px rgba($theme-button, 0.4);
    padding: 4px 16px;
    text-align: center;
    .savvi-tooltip__toggle {
      color: $white;
      &:hover {
        color: $select--hover;
      }
    }
    &:hover {
      background-color: $theme-button--light;
      border: thin solid $theme-button--light;
      color: $white;
    }
    &:active {
      box-shadow: none;
      transform: none;
    }

    &.isWarning {
      background-color: $orange;
      border: thin solid darken($orange, 5%);
      box-shadow: -2px 6px 8px -2px transparentize($orange, 0.4);
      &:hover {
        background-color: $orange--light;
        border: thin solid $orange--light;
      }
      &:active {
        box-shadow: none;
        outline: none;
      }
    }
    &:disabled,
    &.isDisabled {
      cursor: default;
      background-color: $light-110;
      border: thin solid $light-110;
      box-shadow: none;
    }
    &.myBtn--flat {
      box-shadow: none;
    }
  }
  &--secondary {
    background-color: $white;
    border: thin solid $border--button-2;
    padding: 4px 16px;
    color: $text--dark-2;

    .savvi-tooltip__toggle {
      margin-bottom: 8px;
    }

    &:hover {
      border: thin solid $new-primary;
      color: $new-primary;
    }
    &:active {
      outline: none;
      border-color: $new-primary--light;
      color: $new-primary--light;
    }

    &.isWarning {
      border: 2px solid $orange;
      color: $orange;
      transition: all 0.02s;

      &:hover {
        background: $orange--light;
        border: thin solid $orange--light;
        color: $white;
      }
      &:active {
        border: thin solid $orange--dark;
      }
    }
    &:disabled,
    &.isDisabled {
      cursor: default;
      color: $light-110;
      border: thin solid $light-110;
      box-shadow: none;
      svg {
        color: $light-110;
      }
    }
  }
  &--link {
    color: $link;
    text-decoration: underline;
    align-items: flex-start;
    height: auto;
    min-width: unset;
    padding: 2px 4px;
    text-align: start;
    &:hover {
      color: $link--light;
    }
    &.isWarning {
      color: $orange;
      &:hover {
        color: $orange--light;
      }
    }
    &:disabled,
    &.isDisabled {
      cursor: default;
      color: $light-110;
      svg {
        color: $light-110;
      }
    }
  }
  &--primaryLink {
    background-color: $link;
    border: thin solid $link;
    // border: thin solid darken($link, 5%);
    box-shadow: -2px 6px 8px -2px rgba($link, 0.4);
    color: $white;

    &:hover {
      background-color: $link--light;
      border: thin solid $link--light;
    }
    &:active {
      box-shadow: none;
      outline: none;
    }

    &.isWarning {
      background-color: $orange;
      border: thin solid darken($orange, 5%);
      box-shadow: -2px 6px 8px -2px transparentize($orange, 0.4);
      &:hover {
        background-color: $orange--light;
        border: thin solid $orange--light;
      }
      &:active {
        box-shadow: none;
        outline: none;
      }
    }
  }
  &--icon {
    background-color: inherit;
    color: $bg--status;
    text-decoration: underline;
    align-items: center;
    justify-content: center;
    min-width: unset;
    border: thin solid transparent;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    &:hover,
    &:focus,
    &:active {
      color: $link--light;
      background-color: $white;
      border-color: $link--light;
    }
    &.isWarning {
      color: $orange;
      &:hover,
      &:focus,
      &:active {
        color: $orange--light;
        border-color: $orange--light;
      }
    }
    &:disabled,
    &.isDisabled {
      cursor: default;
      color: $light-110;
      border-color: $light-110;
      svg {
        color: $light-110;
      }
    }
  }

  .myBtn__tooltip {
    visibility: hidden;
    font-size: 12px;
    min-width: 120px;
    width: max-content;
    background-color: $tooltip;
    color: #fff;
    text-align: center;
    padding: 8px;
    border-radius: 14px;
    position: absolute;
    z-index: 1;

    &--align-left {
      left: -16px;
    }
    &--align-right {
      right: -16px;
    }

    &--position-top {
      bottom: 38px;
    }
    &--position-right {
      top: -8px;
      left: 115%;
    }
    &--position-bottom {
      top: 80%;
    }
    &--position-left {
      top: -8px;
      right: 115%;
    }
  }
}
