@import 'color/colors';
@import 'navigation/navigation';

.outside-form__container {
  width: 100%;
  display: flex;
  flex: 1;
  background-color: $workbench--background;
  // margin-right: $info-sidebar-width;
  position: relative;
  .infoSidebar__container {
    width: $info-sidebar-width;
    @media (min-width: 1540px) {
      width: $info-sidebar-width--lg;
    }
    @media (max-width: 1280px) {
      width: $info-sidebar-width--sm;
    }
    @media (max-width: 876px) {
      width: $info-sidebar-width--mobile;
    }
  }
  &.info-sidebar-collapsed {
    justify-content: center;
    margin-right: 0;
    .infoSidebar__container {
      width: 0;
      overflow: hidden;
      padding: 0;
    }
    .workbench__toggle-info {
      right: 12px;
      top: 64px;
      // height: 40px;
      width: 40px;
      box-shadow: rgba(0, 0, 0, 10%) 0px 1px 6px, rgba(0, 0, 0, 20%) 0px 2px 24px;
      color: $link--light;
      background-color: $white;
      border-color: $border;
      transition: 0.05s ease;
      svg {
        color: $link--light;
      }
      &:active {
        box-shadow: none;
        border-color: $link--light;
      }
      &:hover {
        border-color: $link--light;
      }
      @media (min-width: 1440px) {
        right: unset;
      }
    }
    .workbench__info-sidebar-overlay {
      display: none;
    }
  }
  @media (min-width: 1440px) {
    justify-content: center;
  }
}

.outside-form__main {
  margin-top: $topbar-height;
  margin-right: $info-sidebar-width;
  overflow: visible;
  display: flex;
  background-color: $workbench--background;
  flex: 1;
  // overflow: auto;
  max-width: 1180px;
  padding: 0 60px;
  @media (min-width: 1440px) {
    margin-right: 0;
  }
  @media (max-width: 1280px) {
    margin-right: $info-sidebar-width--sm;
  }
  @media (max-width: 1080px) {
    margin-right: 40px;
  }
  @media (max-width: 876px) {
    margin-right: unset;
    padding: 0;
  }
  &.no-sidebar {
    max-width: unset;
    margin-right: unset;
    justify-content: center;
    .outside-form__header {
      margin: 0 auto;
    }
    .outside-form__help {
      text-align: center;
      width: 100%;
    }
  }
}

.outside-form__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  align-items: flex-start;
  padding: 12px 52px;
  width: 100%;
  min-height: 480px;
  padding: 52px 80px 140px;
  max-width: 960px;
  min-width: 720px;
  height: fit-content;
  .workbench-form {
    margin-bottom: 12px;
  }
  @media (min-width: 1780px) {
    padding: 60px 100px 160px;
    max-width: 1000px;
  }
  @media (max-width: 1440px) {
    padding: 40px 40px 100px;
  }

  @media (max-width: 1080px) {
    padding: 40px 20px 100px;
  }
  @media (max-width: 876px) {
    padding: 34px 8px 100px;
    min-width: unset;
  }
}

.outside-form__loader {
  text-align: center;
  font-size: 24px;
  color: $text--dark;
  margin: 12px 0;
  background: $white;
  padding: 40px 52px;
  min-height: 220px;
  box-shadow: 0 2px 5px 2px #ccc;
  // min-width: 720px;
  display: flex;
  align-items: center;
  img {
    width: 2em;
  }
}

.outside-form-header {
  margin-bottom: 5px;
  width: 100%;
  h1 {
    @include text--h1;
  }
  h4 {
    @include text--h3;
    margin: 4px 0;
    color: $dark-80;
    span {
      color: $red;
    }
  }
  &--error {
    text-align: center;
  }
  @media (max-width: 876px) {
    margin-left: 12px;
  }
}

.outside-form-section-header {
  margin-bottom: 5px;
}

.outside-form--btn {
  margin-top: 15px;
  // align-self: flex-end;
  box-shadow: -2px 4px 4px 0 #ccc;
}

.outside-form-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.outside-form__bottom {
  bottom: 30px;
  left: 0;
  right: 0;
  position: fixed;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: $white;
  border-top: 1px solid $light-95;
  z-index: $info-bar-z-index - 1;
}

.outside-form__help {
  p {
    margin-bottom: 12px;
  }
}
