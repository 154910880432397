@import 'navigation/navigation';
@import 'color/colors';

.workbenchTaskOverview {
  &__container {
    width: 100%;
    min-width: 660px;
    // max-width: 920px;

    .form__heading {
      text-align: center;
    }
    @media (min-width: 1540px) {
      max-width: 1020px;
      margin-right: 100px;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 680px;
    @media (max-width: 1240px) {
      min-width: unset;
    }
    @media (max-width: 876px) {
      margin-left: 12px;
    }
  }
  &__top-actions {
    position: relative;
    .dropdown {
      top: 48px;
      right: -20px;
      width: 160px;
      // &:before {
      //   left: 110px;
      // }
      // &:after {
      //   left: 109px;
      // }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    .DocumentCredits__popup {
      margin-left: -14px;
    }
    h2 {
      @include text--h1;
      margin-top: 0;
      margin-bottom: 0;
    }
    h3 {
      @include text--h3;
      margin-left: 4px;
    }
  }

  &__content {
    border-radius: 4px;
    padding-bottom: 8px;
    min-width: 680px;
    width: 100%;
  }

  &__top-sections {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    min-width: 680px;
    @media (max-width: 876px) {
      min-width: unset;
      margin-left: 12px;
    }
  }
  &__top-section {
    margin-right: 8px;
    &.first {
      flex: 1;
    }
    p {
      @include text--h4;
      margin: 6px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      white-space: pre-wrap;
      span {
        margin-right: 2px;
      }
      svg {
        color: $warning;
        margin-right: 4px;
      }
    }
    h4 {
      margin-right: 12px;
    }
  }
  &__update-inputs {
    color: inherit;
    text-decoration: underline;
    &:hover {
      color: $new-primary--light;
    }
  }

  &__status {
    color: $text--sub;
    font-weight: 600;
    &.low {
      color: $low;
    }
    &.med {
      color: $med;
    }
    &.high {
      color: $warning;
    }
  }

  &__bank-link {
    margin-bottom: 4px;
  }

  &__dropdown {
    .dropdown {
      width: 380px;
      padding-bottom: 20px;
      top: 20px;
      .myBtn--secondary {
        justify-content: flex-start;
        border: none;
        padding: 8px 8px;
        margin: 4px 12px;
        width: 356px;
        border-radius: 8px;
        transition: 0.25s;
        font-size: 14px;
        svg {
          margin-left: 8px;
          width: 12px;
          color: $link;
        }

        &:not(&:disabled):hover {
          background-color: $hover;
          color: $text--dark-2;
          text-decoration: none;
          &.isWarning {
            color: $warning--light;
          }
        }
        &:disabled:hover {
          background-color: inherit;
          color: $light-110;
        }
      }
    }
    &--empty {
      .myBtn--secondary {
        width: unset;
      }
    }
  }

  &__dropdownHeader {
    display: flex;
    align-items: center;
    margin: 20px 20px 12px;
    h4 {
      @include text--h4;
      border: none;
      padding: 0 4px 0 0;
    }
  }

  &__divider {
    height: 1px;
    background-color: $border;
    margin-left: 4px;
    flex: 1;
  }

  &__questionnaire-status {
    display: flex;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      white-space: pre-wrap;
      margin: 4px 0;
      font-size: 14px;
      color: $text--dark-1;
      strong {
        color: $text--dark;
      }
      .workbenchTaskOverview__action {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }
  &__delete {
    height: 45px;
    width: 45px;
    transition: 0.05s all linear;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $orange;
      font-size: 20px;
    }
    &:hover {
      svg {
        font-size: 22px;
        color: $orange--light;
      }
    }
    &:active {
      svg {
        font-size: 20px;
        color: $orange--dark;
      }
    }
  }

  &__help {
    h4 {
      margin-top: 8px;
      margin-bottom: 8px;
      color: $dark-50;
    }
    h6 {
      margin: 0;
      margin-top: -5px;
      color: $dark-60;
    }
    sup {
      svg {
        color: $link;
        margin-left: 2px;
        &:hover {
          color: $link--light;
          cursor: pointer;
        }
      }
    }
    .dd-menu-items {
      margin: 0 0 0 10px;
      .dd-items-left {
        min-width: 275px;
        padding: 5px 5px 5px 10px;
        li {
          margin: 5px 0;
          font-size: 15px;
          font-weight: 500;
          color: $dark-60;
          display: flex;
        }
        ul {
          box-shadow: none;
          border: none;
        }
        p a {
          margin: 0;
          padding: 0;
          min-width: 0;
          color: $link;
          &:hover {
            color: $link--dark;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.workbenchTaskOverview__faq {
  background-color: $white;
  margin-left: 14px;
  margin-top: 10px;
  padding-left: 0;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: $link;
  font-size: 1em;
  width: 225px;
  align-self: center;
  svg {
    font-size: 0.8em;
  }

  &:hover {
    color: $link--dark;
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
    outline: none;
  }
}

.workbenchTaskOverview__group {
  box-shadow: 2px 0 5px 2px rgba(0, 0, 0, 0.15);
  padding: 4px 4px 10px;
  margin: 10px 0;
  max-height: 750px;
  transition: all 0.25s;
  background: $white;
  &-header {
    color: $dark-60;
    margin: 0;
    padding: 4px 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    h2 {
      margin: 0;
    }
    svg {
      font-size: 24px;
      margin-top: 4px;
    }
    &:hover {
      svg {
        transform: translateY(-1px);
        color: $link;
      }
    }
  }
  &.collapsed {
    max-height: 41px;
    overflow: hidden;
  }
  &.borderless {
    box-shadow: none;
    max-height: 3000px;
    background: transparent;
  }
}

.workbenchTaskOverview__bottom {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding-top: 8px;
  padding-bottom: 40px;
  background-color: $white;
  border-top: 1px solid $light-95;
  z-index: $info-bar-z-index - 1;
}

.workbenchTaskOverview__description {
  font-weight: 500;
  margin: 8px auto 16px;
  max-width: 660px;
  svg {
    color: $mint;
    margin-right: 4px;
    &.error {
      color: $red;
    }
  }
  span {
    font-weight: bolder;
    color: $dark;
  }

  @media (max-width: 768px) {
    max-width: 600px;
  }
  @media (max-width: 568px) {
    max-width: 80%;
  }
}
