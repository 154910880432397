@import 'navigation/navigation';
@import 'color/colors';

.BatchUploadModal {
  position: relative;
  outline: none;
  margin: 0 auto auto;
  border-radius: 8px;
  border: none;
  box-shadow: 3px 3px 3px 3px #33373859;
  background-color: $white;
  min-height: 220px;
  max-width: 98vw;
  width: 680px;
  height: fit-content;
  max-height: 90vh;
  margin-top: 20px;
  padding: 30px 60px;
  overflow-y: auto;
  overflow-x: hidden;
  h2 {
    @include text--h2;
  }
  &__overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    -webkit-overflow-scrolling-: 'touch';
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-bottom: 20px;
    h1 {
      @include text--h1;
      margin: 0 20px 0 0;
    }
    .myBtn--link {
      margin-top: 12px;
      padding-left: 0;
      svg {
        margin-right: 8px;
        position: relative;
      }
    }
  }

  &__exit {
    height: 30px;
    right: 0;
    top: -2px;
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &__key {
    padding: 20px 30px 20px 40px;
    margin-bottom: 16px;
    margin-left: -40px;
    border: thin solid $doc--bg;
    border-radius: 12px;
    h3 {
      @include text--h3;
      position: relative;
      svg {
        position: absolute;
        left: -16px;
        top: 2px;
        color: $low;
        margin-right: 4px;
      }
      .myBtn--link {
        font-size: inherit;
        margin: 0;
        padding: 2px 4px;
      }
    }
    ul {
      padding-left: 12px;
      margin-top: 8px;
      list-style: none;
    }
    li {
      @include text--body2;
      margin-left: -12px;
      &::before {
        content: '-'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $text--dark-1; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }

      .myBtn--icon {
        color: $link;
      }
    }
  }
  &__keyAddress {
    display: inline-flex;
    margin: 4px 4px 4px -6px;
  }
  &__keyAddressCol {
    padding: 8px;
    border-radius: 4px;
    background-color: $doc--bg;
    overflow-x: auto;
    p {
      @include text--body2;
      white-space: nowrap;
    }
    @media (max-width: 678px) {
      max-width: 40%;
    }
  }
  &__keyAddressDivider {
    height: 60px;
    width: 1px;
    background: $border;
    margin: 0 20px;
    align-self: center;
  }
  &__body {
    .file-uploader__primary-action {
      align-self: flex-start;
      margin-left: 0;
      padding-left: 0;
    }
    .file-uploader-content-preview {
      max-width: unset;
      margin-top: 8px;
      .bp-container {
        border-radius: 8px;
      }
    }
  }
  &__errors {
    margin-top: 12px;
    h4 {
      @include text--h4;
      color: $red;
    }
    ul {
      padding-left: 12px;
      list-style: none;
    }
    li {
      @include text--body2;
      span {
        color: $red;
      }
      &::before {
        content: '-'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $red; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
  }
  &__actions {
    margin: 20px 0 0;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1080px) {
    min-width: unset;
  }
  @media (max-width: 520px) {
    padding: 20px 20px 20px 40px;
  }
  @media (min-height: 1280px) {
    height: 80vh;
    margin-top: 80px;
    max-height: 1400px;
  }
  @media (min-width: 1800px) {
    margin-top: 80px;
  }
}
