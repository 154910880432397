@import 'navigation/navigation';
@import 'color/colors';
@import 'color/color-variables';

.RoomUserHistoryModal {
  h4 {
    @include text--h4;
  }
  position: relative;
  min-height: 260px;
  min-width: 480px;
  max-height: 620px;
  width: fit-content;
  max-width: 600px;
  padding: 36px 44px 20px 50px;
  outline: none;
  margin: 80px auto auto;
  border-radius: 8px;
  border: none;
  box-shadow: 3px 3px 3px 3px #33373859;
  display: flex;
  flex-direction: column;
  background-color: $white;
  min-height: 220px;
  @media (max-height: 768px) {
    margin-top: 40px;
    max-height: 90vh;
  }
  @media (max-width: 520px) {
    padding: 20px 30px 20px 40px;
    max-width: 90vw;
  }
  @media (min-width: 1400px) {
    max-width: 680px;
  }
  @media (min-height: 1080px) {
    max-height: 900px;
  }
  &__overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling-: 'touch';
    .myBtn--link {
      align-self: flex-end;
    }
  }
  &__headerRow {
    display: flex;
    justify-content: space-between;
  }
  &__header {
    margin-bottom: 20px;
    h2 {
      @include text--h1;
      margin: 0;
      display: flex;
      align-items: center;
    }
    h3 {
      @include text--h3;
      color: $text--dark-1;
      margin-bottom: 20px;
    }
    p {
      @include text--body1;
    }
  }
  &__exit {
    // position: absolute;
    // align-self: flex-end;
    // margin-top: -3px;
    height: 30px;
    // color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }

  &__sectionRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
    h3 {
      color: $dark-80;
      border-bottom: thin solid $dark-50;
      font-weight: 400;
      margin: 0 0 10px;
      width: fit-content;
      padding: 0 15px 3px 0;
      align-self: center;
    }
  }
  &__subsectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 12px;
    p {
      @include text--body2;
    }
  }
  &__subsectionDivider {
    height: 1px;
    flex: 1;
    background-color: $border;
    margin: 0 8px;
  }
  &__rowItem {
    margin-right: 20px;
    h4 {
      @include text--h4;
      position: relative;
      .savvi-tooltip__wrapper {
        position: absolute;
        top: -2px;
      }
    }
    p {
      @include text--body1;
    }
  }
  &__rowActions {
    height: 35px;
    display: flex;
    align-items: center;
    .myBtn--icon {
      margin-right: 4px;
    }
  }
  &__fetching {
    align-self: center;
  }
  &__card {
    width: 100%;
    min-height: 54px;
    display: flex;
    padding-right: 12px;
  }
  &__timeline {
    &-header {
      color: $dark-85;
      font-size: 1.1em;
      margin-bottom: 10px;
      margin-top: 10px;
      font-weight: 600;
    }
    &-empty {
      margin-left: 5px;
      color: $dark-75;
      margin-top: 5px;
      margin-bottom: 8px;
    }
  }
  &__pre {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $text--dark;
    // height: 100%;
    width: 30px;
    margin-right: 12px;
    // top: 6px;
    // left: -36px;
  }
  &__icon {
    color: $text--dark;
    width: 24px;
    height: 24px;
    border-radius: 15px;
    border: thin solid $text--dark;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 12px;
    }
  }
  &__line {
    flex: 1;
    background-color: $dark-50;
    width: 1px;
    min-height: 20px;
    height: 100%;
    margin-right: 2px;
    &--isLast {
      display: none;
    }
  }
  &__body {
    padding-bottom: 8px;
    h4 {
      @include text--body1;
      // margin: 0 0 5px;
      p {
        @include text--h4;
        margin: 2px 0 0 2px;
      }
    }
    p {
      @include text--h4;
      margin-top: 2px;
      svg {
        margin-right: 3px;
      }
    }
    a {
      @include text--h4;
      color: $new-primary;
      text-decoration: underline;
      &:hover {
        color: $new-primary--light;
      }
    }
  }

  .workbench-timeline__divider {
    width: 2px;
    background-color: $border;
    margin: 2px 4px;
  }

  &__toggle {
    @include text--h4;
    margin-top: 12px;
    margin-left: 8px;
    text-decoration: underline;
    cursor: pointer;
    svg {
      padding-left: 4px;
      font-size: 1.25em;
      vertical-align: middle;
    }
    &:hover {
      color: $new-primary--light;
    }
  }

  &__fade {
    width: 550px;
    height: 80px;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    position: absolute;
    bottom: 0;
  }

  &__content {
    &--inner {
      position: relative;
      width: 100%;
      transition: all 0.3s;
      overflow-y: auto;
      /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
      &::-webkit-scrollbar-track {
        // visibility: hidden;
        opacity: 0;
        box-shadow: none;
      }
      &::-webkit-scrollbar-thumb {
        // visibility: hidden;
        opacity: 0;
        box-shadow: none;
      }
      &:hover {
        &::-webkit-scrollbar-track {
          opacity: 0.3;
          box-shadow: inset 0 0 6px #a4a4a4;
        }
        &::-webkit-scrollbar-thumb {
          opacity: 0.4;
          box-shadow: inset 0 0 6px #a4a4a4;
        }
      }
      /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
    }
  }
}
