@import 'src/assets/styles/color/colors';
@import 'src/assets/styles/navigation/navigation';

.roomSettings__h3 {
  @include text--h3;
  margin: 4px 0;
}
.roomSettings__h4 {
  padding-left: 6px;
  position: relative;
}

.roomSettings__subheader {
  @include text--h2;
  color: $text--dark-1;
  margin: 0 12px 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.roomSettings__subsectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 0 6px;
  p {
    @include text--body2;
  }
}
.roomSettings__subsectionDivider {
  height: 1px;
  flex: 1;
  background-color: $border;
  margin: 0 8px;
}

.roomSettings__subsection {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  margin-top: 24px;
  // max-width: 800px;
  padding-bottom: 4px;
  min-height: 100px;
  border-top: thin solid $border;
  &--first {
    border-top: none;
    padding-top: 0;
  }
  // &--last {
  //   border-bottom: none;
  // }
}

.roomSettings__user {
  &Row {
    display: flex;
    align-items: center;
  }
  h4 {
    @include text--h4;
    margin-top: -4px;
  }
}
.roomSettings__titleRow {
  display: flex;
  width: 100%;
  min-height: 40px;
  justify-content: space-between;
  position: relative;
  align-items: flex-start;
  padding: 0 6px 12px;
  .dropdown {
    right: -128px;
    width: 200px;
  }
  .myBtn--secondary svg {
    margin-right: 4px;
  }
  // padding: 0 0 12px;
}
.roomSettings__titleItems {
  display: flex;
  flex: 2;
  justify-content: flex-end;
}
.roomSettings__titleItem {
  display: flex;
  flex-direction: column;
  text-align: end;
  margin-right: 40px;
  .savvi-tooltip__wrapper {
    position: absolute;
  }
  &.first {
    text-align: left;
    min-width: 280px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
}

.roomSettings__titleActions {
  display: flex;
  min-width: 200px;
  justify-content: flex-end;
  .myBtn--icon {
    margin-right: 4px;
  }
}
.roomSettings__titleAction--wide {
  width: 188px;
}
.roomSettings__card {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 12px 6px;
  min-height: 60px;
  border-radius: 16px;
  cursor: pointer;
  &--first {
    margin-top: 8px;
    height: 70px;
  }
  &--alignRight {
    justify-content: flex-end;
  }
  &--last {
    border-bottom: none;
  }
  &:hover {
    background-color: $hover;
  }
  &--flat {
    cursor: default;
    &:hover {
      background: $white;
    }
  }
}
.roomSettings__actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex: 1;

  .myBtn--link {
    margin-bottom: 8px;
    text-decoration: none;
    margin: 0 0 0 auto;
    svg {
      margin-left: 4px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.roomSettings__cardItem {
  display: flex;
  flex-direction: column;
  text-align: end;
  min-height: 40px;
  flex: 1;
  .savvi-tooltip__wrapper {
    position: absolute;
  }
  &.first {
    text-align: left;
    min-width: 240px;
    flex: 2.5;
    svg {
      font-size: 12px;
    }
  }
  h4 {
    @include text--h4;
  }
  h3 {
    @include text--body1;
    color: $text--dark-2;
    display: flex;
    span {
      @include text--h4;
      margin-left: 4px;
    }
  }
  p {
    justify-content: flex-end;
    span {
      margin-left: 2px;
    }
    .myBtn--link {
      color: $text--dark-1;
      &:hover {
        color: $link;
      }
    }
  }
  &--date {
    width: 144px;
  }
}
.roomSettings__code {
  &Row {
    display: flex;
    align-items: center;

    margin-top: -4px;
    margin-bottom: 4px;
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
    }
  }
  &Ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 140px;
  }
  h4 {
    @include text--h4;
    margin-top: -4px;
  }
}
.roomSettings__shareCode {
  align-self: flex-start;
}

.roomSettings__floatingHeader {
  position: absolute;
  left: 120px;
  top: 10px;
}

.roomSettings__modalCard {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: thin solid $border;
}

.roomSettings__col--first {
  margin-right: 12px;
  width: 200px;
}
.roomSettings__section {
  max-width: 1200px;
  min-width: 680px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .allDocsSection {
    margin-top: 0;
    &__list {
      min-width: 100%;
    }
  }
  .documentsSection {
    margin-top: 0;
    margin-bottom: 20px;
    max-width: unset;
    .documentsSection__header h2 {
      @include text--h2;
    }
  }
  .dataTable__wrapper {
    margin-bottom: 20px;
  }
  .dataTable__documentRequired {
    color: $text--dark-1;
  }

  @media (max-width: 1280px) {
    .dataTable__wrapper {
      min-width: 880px;
    }
  }

  @media (max-width: 1080px) {
    .infoSidebar__linksWrapper {
      position: relative;
      right: unset;
    }
    .infoSidebar__links {
      background-color: transparent;
      padding: 0;
      box-shadow: unset;
      width: unset;
      column-gap: 20px;
      flex-direction: row;
      max-width: 85vw;
      flex-wrap: wrap;
      svg {
        display: none;
      }
    }
  }
}

.roomSettings__settings {
  padding: 30px 20px;
  box-shadow: $shadow__table;
  background-color: $white;
  border-radius: 16px;
  margin-bottom: 12px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  h4 {
    @include text--h4;
    a {
      display: contents;
    }
  }
  p {
    @include text--body2;
    position: relative;
    .savvi-tooltip__wrapper {
      position: absolute;
      margin-top: -4px;
    }
  }
  .myBtn--link {
    align-self: flex-start;
  }
}

.roomSettings__toggleCollapse {
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  p {
    margin: 0 4px;
    color: $link;
  }
  &:hover p {
    color: $link--light;
  }
}
.roomSettings__toggleCollapseDivider {
  flex: 1;
  height: 1px;
  background-color: $border;
}

.roomSettings__resourceType {
  @include text--h2;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .myBtn--icon {
    color: $text--dark-1;
    margin-left: 4px;
    &:hover {
      color: $link--light;
    }
    &.isWarning {
      color: $orange;
      &:hover {
        border: thin solid $orange--light;
      }
      &:active {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.roomSettings__divider {
  width: 100%;
  border-top: thin solid $border;
  margin-bottom: 20px;
}

.roomSettings__modal {
  &-overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling-: 'touch';
    .myBtn--link {
      align-self: flex-end;
    }
  }
  h4 {
    @include text--h4;
  }
  position: relative;
  max-width: 90%;
  min-height: 260px;
  min-width: 540px;
  padding: 20px 40px 20px;
  outline: none;
  margin: auto;
  border: none;
  box-shadow: 3px 3px 3px 3px #33373859;
  display: flex;
  flex-direction: column;
  background-color: $white;
  &--sm {
    min-height: 220px;
    max-width: 660px;
  }
  &--lg {
    min-height: 340px;
  }
  &Actions {
    flex: 3;
    display: flex;
    align-items: flex-end;
    margin-top: 12px;
    justify-content: space-around;
  }
  &-exit {
    position: absolute;
    align-self: flex-end;
    margin-top: -3px;
    font-size: 1.25em;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &-preview {
    height: 400px;
    margin-bottom: 20px;
  }
  &-error {
    color: $warning;
    svg {
      margin-right: 4px;
      color: $warning;
    }
  }
  &-option {
    &-wrapper {
      display: flex;
      align-items: flex-start;
      &:hover {
        background-color: $select--hover;
      }
      &--selected {
        background-color: $select--focus;
        .roomSettings__modal-option {
          color: $white;
        }
        &:hover {
          background-color: $select--focus;
        }
      }
    }
    @include text--h4;
    margin-top: 10px;
    display: flex;
    color: $low;
    background-color: transparent;
    text-align: right;
    outline: none;
    border: none;
    cursor: pointer;
    min-width: fit-content;
    margin-right: 12px;
    &--low {
      color: $mint;
    }
  }
  h2 {
    margin: 0;
    display: flex;
    align-items: center;
    span {
      color: $red;
    }
  }
  .myBtn--link {
    padding-left: 0;
    margin-bottom: 8px;
    margin-top: -8px;
  }
  .bcu-footer {
    position: absolute;
    height: 0;
    width: 0;
    margin: 0;
    overflow: hidden;
    .bcu-footer-left {
      width: 0;
      overflow: hidden;
    }
  }
  .roomSettings__titleActions {
    flex: 1;
  }
}

.roomSettings__grouped-logs {
  h4 {
    @include text--h4;
    margin: 4px 0;
  }
  p {
    @include text--body2;
  }
  .myBtn--link {
    margin-left: 0;
    padding-left: 0;
    margin: 0 0 4px;
  }
}

.roomSettings__access {
  margin-left: 6px;
  h3 {
    @include text--h3;
    font-size: 14px;
    font-weight: 700;
  }
  h4 {
    @include text--h4;
    margin-bottom: 4px;
    overflow-wrap: anywhere;
  }
  p {
    @include text--body2;
  }
}
.roomSettings__dropdown {
  .dropdown {
    width: 380px;
    padding-bottom: 20px;
    top: 20px;
    .myBtn--secondary {
      justify-content: flex-start;
      border: none;
      padding: 8px 8px;
      margin: 4px 12px;
      width: 356px;
      border-radius: 8px;
      transition: 0.25s;
      font-size: 14px;

      &:not(&:disabled):hover {
        background-color: $hover;
        color: $text--dark-2;
        text-decoration: none;
        &.isWarning {
          color: $warning--light;
        }
      }
      &:disabled:hover {
        background-color: inherit;
        color: $light-110;
      }
    }
  }
  &--empty {
    .myBtn--secondary {
      width: unset;
    }
  }
}
.roomSettings__accessAction {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 0;
  padding-top: 0;
  text-decoration: none;
  h3 {
    @include text--h3;
    font-size: 14px;
    font-weight: 700;
  }
  svg {
    color: $text--dark;
  }
  &--flat {
    cursor: default;
  }
  &:not(&--flat):hover {
    h3 {
      color: $link;
    }
    svg {
      color: $link;
    }
  }
  &:disabled {
    cursor: default;
    h3 {
      color: $text--dark-disabled;
    }
    svg {
      color: $text--dark-disabled;
    }
    &:hover {
      h3,
      svg {
        color: $text--dark-disabled;
      }
    }
  }
}
.roomSettings__lock {
  margin-right: 4px;
  font-size: 14px;
}
.roomSettings__chevron {
  margin-left: 4px;
  font-size: 12px;
}
.roomSettings__dropdownHeader {
  display: flex;
  align-items: center;
  margin: 20px 20px 12px;
  h4 {
    @include text--h4;
    border: none;
    padding: 0 4px 0 0;
  }
}
.roomSettings__dropdownDivider {
  height: 1px;
  background-color: $border;
  margin-left: 4px;
  flex: 1;
}

.roomSettings__access-logs {
  margin-bottom: 8px;
}
