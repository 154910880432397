@import 'color/colors';
@import 'navigation/navigation';

.myDashboard {
  &__container {
    padding: 0 50px 0 30px;
    // max-width: 100%;
    margin: 15px auto 5px;
    width: 100%;
    padding: 0 48px;
    @media (max-width: 1280px) {
      width: 80vw;
      min-width: 768px;
    }
    @media (max-width: 768px) {
      padding: 15px 30;
    }
    @media (max-width: 576px) {
      padding: 5px 0;
    }
  }

  &__empty {
    margin-top: 40px;
    h2 {
      @include text--h2;
      color: $text--dark-1;
    }
    .myBtn--primary {
      margin-left: 8px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    // width: 80vw;
    width: 100%;
    max-width: 1080px;
    min-width: 820px;
    margin-left: 70px;
    padding-left: 10px;
    padding-right: 10px;
    @media (max-width: 1280px) {
      max-width: 900px;
      margin-left: 50px;
      margin-right: 20px;
    }
    @media (max-width: 1080px) {
      width: 100%;
      margin-left: 20px;
      max-width: 900px;
      overflow-x: auto;
    }
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  &__search {
    align-items: center;
    color: $dark-75;
    display: flex;
    height: $topbar-height - 2px;
    background-color: transparent;
    position: fixed;
    top: 0;
    width: 440px;
    max-width: 80vw;

    z-index: 5;
    transition: 0.5s;
    &:focus-within {
      z-index: 6;
      width: 600px;
    }
    input {
      outline: none;
      height: 40px;
      border-radius: 16px;
      padding-right: 40px;
      padding-left: 40px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border: thin solid #e8eef1;
    }
    &Icon {
      position: absolute;
      margin-left: 12px;
      text-align: center;
      svg {
        color: $text--light;
      }
    }
    &Clear {
      margin: 28px 8px;
      font-size: 1.1em;
      color: $text--light;
      cursor: pointer;
      position: absolute;
      right: 8px;
      &:hover {
        color: $primary;
        font-weight: bolder;
      }
    }

    @media (max-width: 1679px) {
      width: 360px;
      &:focus-within {
        width: 600px;
      }
    }
    @media (max-width: 1480px) {
      width: 300px;
      &:focus-within {
        width: 520px;
      }
    }
    @media (max-width: 1380px) {
      width: 200px;
      input {
        padding-right: 20px;
      }
      &:focus-within {
        width: 480px;
      }
    }
    @media (max-width: 1080px) {
      width: 120px;
      left: 140px;

      input {
        padding-right: 12px;
      }
      &:focus-within {
        width: 380px;
      }
    }
    @media (max-width: 560px) {
      width: 80px;
      left: 120px;
      &:focus-within {
        width: 320px;
      }
    }
  }
  &__nav {
    width: fit-content;
    display: flex;
    gap: 12px;
    position: relative;
    margin-top: 52px;
    margin-bottom: -20px;
  }
  &__navHeader {
    position: absolute;
    @include text--h2;
    color: $text--dark-1;
    font-weight: 500;
    top: -24px;
  }
  &__navLink {
    padding: 12px 24px;
    background-color: $white;
    border: thin solid $border;
    border-radius: 4px;
    box-shadow: $shadow__table;
    height: fit-content;
    min-width: 100px;
    text-align: center;
    h4 {
      @include text--h4;
    }
    &--selected {
      background-color: $bg--status;
      h4 {
        color: $white;
      }
    }
  }

  &__pageHeader {
    @include text--h1;
    font-size: 24px;
    margin: 40px 0 -40px;
    overflow: visible;
    @media (max-width: 1280px) {
      margin-top: 20px;
    }
    // @media (max-width: 1080px) {
    //   margin-left: 20px;
    // }
    // @media (max-width: 825x) {
    //   margin-left: 10px;
    // }
  }

  &__header {
    display: flex;
    transition: 0.05s all linear;
    align-items: center;
    margin-top: 8px;
    width: fit-content;
    padding-right: 60px;
    border-bottom: 2px solid $link;
    @media (max-width: 876px) {
      font-size: 1.5em;
    }
    @media (max-width: 768px) {
      margin-left: 40px;
    }
    h2 {
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 2em;
      color: $dark-85;
    }
    svg {
      font-size: 1.5em;
      margin-right: 4px;
    }
  }

  &__subheader {
    text-decoration: underline;
    margin: 0;
    font-size: 1em;
    color: $dark-60;
    font-weight: bold;
  }

  &__fetching {
    width: 200px;
    text-align: center;
  }
  &__notice {
    color: $mint;
  }
  &__warning {
    color: $red;
  }
  &__section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 800px;
    padding: 0;
    margin-top: 10px;
    &-header {
      display: flex;
      width: fit-content;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      transition: 0.05s all linear;
      margin: 4px 0;
      align-items: center;
      .highlight {
        width: 32px;
        background-color: $mint;
        border-radius: 16px;
        color: $white;
        text-align: center;
        font-weight: 500;
        margin: 0;
        margin-right: 4px;
        font-size: 1.1em;
        &.warning {
          background-color: $red;
        }
      }

      .recommended {
        background-color: $mint;
      }
      .sectionLabel {
        font-size: 1.3em;
        color: $dark-80;
        font-weight: 500;
        margin: 0;
      }
      .toggleSection {
        font-size: 1.3em;
        color: $link;
        font-weight: 500;
        margin: 0 4px;
      }
      svg {
        font-size: 1.25em;
        margin-top: 4px;
        margin-right: 5px;
        color: $link;
      }
      &:hover {
        border-bottom: 2px solid $link--light;
        .sectionLabel {
          color: $dark-85;
        }
        .section-header {
          .highlight {
            color: $mint--dark;
          }
        }
        .toggleSection {
          color: $link--light;
        }
        svg {
          color: $link--light;
        }
      }
    }

    @media (max-width: 850px) {
      width: 90vw;
    }
    @media (max-width: 1250px) {
      &-header {
        font-size: 1.3em;
        color: $dark-75;
        font-weight: 500;
        margin: 4px 20px 4px 0;
      }
    }
    @media (max-width: 768px) {
      &-header {
        line-height: 16px;
        font-size: 14px;
      }
    }
  }

  /***  PENDING DOCS ***/
  &__info-card {
    padding: 4px 4px 4px 0;
    background-color: $white;
    max-height: 325px;
    overflow-y: auto;
    border-bottom: thin solid $border;
    background-color: $white;
    h4 {
      margin: 0 auto;
    }
    @media (max-width: 1250px) {
      padding: 0 20px 20px;
    }
    @media (max-width: 768px) {
      padding: 0;
      h1 {
        font-size: 20px;
      }
    }
  }
  &__doc-card {
    display: flex;
    margin-bottom: 12px;
    padding-bottom: 4px;
    border-bottom: thin solid $border;
  }
  &__pre {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark-50;
    margin-right: 10px;
    margin-top: 5px;
    width: 20px;
  }
  &__body {
    width: 100%;
    h4 {
      color: $dark-60;
      margin: 0 0 5px;
      font-size: 1.1em;
    }
    p {
      color: $dark-50;
      margin: 4px 0;
      font-size: 1em;
      svg {
        margin-right: 3px;
      }
    }
  }
  &__docsHeader {
    display: flex;
    justify-content: space-between;
    a {
      color: $white;
      background-color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      border: thin solid $primary;
      box-shadow: 2px 6px 4px #ccc;
      transition: all 0.2s smooth;
      width: 85px;
      height: 28px;
      cursor: pointer;
      &:hover {
        background-color: $primary--light;
        border: thin solid $primary--light;
      }
      &:active {
        box-shadow: none;
      }
    }
  }
  &__link {
    color: $blue;
    text-decoration: underline;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 1px 0;
    font-size: 0.9em;
    cursor: pointer;
    &:hover {
      color: $blue--light;
    }
    &:focus {
      outline: thin solid $powder-blue;
    }
  }
  /*** PENDING DOCS END ***/

  &__userReports {
    min-width: 800px;
    width: 100%;
    max-width: 1080px;
    .dataTable__wrapper {
      min-width: 800px;
    }
  }

  &__tableHeader {
    @include text--h1;
    margin: 40px 0 16px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(5, auto);
    // grid-auto-rows: 3fr 2fr 2fr 2fr auto;
    grid-row-gap: 2px;
    grid-column-gap: 0;
    background-color: $light-95;
    overflow-y: auto;
    -webkit-overflow-scrolling: 'touch';
    max-height: 325px;
    justify-content: stretch;
    &-header {
      display: flex;
      align-items: center;
      background-color: $white;
    }

    &-header-label {
      background-color: $white;
      cursor: pointer;
      text-decoration: underline;
      margin: 0;
      padding-right: 8px;
      display: flex;
      align-items: center;
      svg {
        color: $link;
        margin-left: 2px;
      }
      &:hover {
        color: $dark-75;
        svg {
          color: $link--dark;
        }
      }
    }

    &-task {
      background-color: $white;
      margin: 0;
      min-width: 180px;
      min-height: 40px;
      display: flex;
      align-items: center;
      padding: 4px 4px 4px 0;
    }

    &-project {
      background-color: $white;
      min-width: 180px;
      margin: 0;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        color: $link;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: $link--light;
        }
        &:active {
          color: $link--dark;
          font-weight: bold;
        }
      }
    }

    &-item {
      background-color: $white;
      margin: 0;
      min-width: 100px;
      min-height: 40px;
      display: flex;
      align-items: center;
      padding: 4px 4px 4px 0;
    }

    &-placeholder {
      background-color: $white;
      width: 15px;
    }

    &-actions {
      display: flex;
      align-items: center;
      padding: 0 4px 0 30px;
      min-height: 40px;
      background-color: $white;
      a {
        width: 85px;
        text-align: center;
      }
    }

    &-btn {
      color: $white;
      background-color: $primary;
      border: thin solid $primary;
      box-shadow: 2px 6px 4px #ccc;
      transition: all 0.2s smooth;
      cursor: pointer;
      &:hover {
        background-color: $primary--light;
        border: thin solid $primary--light;
      }
      &:active {
        box-shadow: none;
      }
    }

    &-remove {
      color: $orange;
      text-decoration: underline;
      margin: 0 5px;
      border: none;
      background-color: transparent;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: $orange--light;
      }
      &:focus {
        outline: thin solid $powder-blue;
      }
    }

    &-remove-fill {
      width: 26px;
      height: 100%;
    }

    &-help-icon {
      font-size: 16px;
      color: $mint;
      padding-left: 5px;
      border: none;
      background: transparent;
      cursor: help;
      &:hover {
        color: $mint--light;
        font-weight: bold;
      }
    }
    &-help {
      .dd-menu-items {
        .dd-items-left {
          padding: 20px;
          font-size: 12px;
          color: $dark-85;
          .item {
            padding: 0;
            width: 250px;
            color: $dark-85;
          }
        }
      }
    }
  }
}
