@import 'navigation/navigation';
@import 'color/colors';
@import 'color/color-variables';

.TaskCard {
  box-sizing: border-box;
  box-shadow: $shadow__table;
  background-color: $white;
  transition: all 0.15s;
  border-radius: 16px;
  padding: 24px 32px 0 40px;
  margin-top: 8px;
  margin-bottom: 12px;

  &__top {
    display: flex;
    align-items: center;
    height: 80px;
    padding-bottom: 24px;
    &Icon {
      margin-right: 26px;
      font-size: 64px;
    }
    &Label {
      h2 {
        position: relative;
        @include text--h1;
        font-size: 20px;
        margin: 0 4px 0 0;
      }
      h4 {
        @include text--h4;
        margin-top: 4px;
      }
    }
    &Action {
      position: relative;
      width: fit-content;
      .dropdown {
        top: 50px;
        right: -15px;
      }
    }
    &Right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: fit-content;
      flex: 1;
    }
    &Status {
      background-color: $bg--status;
      height: 28px;
      line-height: 28px;
      border-radius: 14px;
      font-size: 12px;
      font-weight: 500;
      margin-right: 20px;
      color: $white;
      padding: 0 12px;
      &.low {
        background-color: $low;
      }
      &.med {
        background-color: $med;
      }
      &.high {
        background-color: $warning;
      }
    }
  }

  &__toggle {
    margin-right: 4px;
    margin-left: -8px;
    svg {
      transition: all 0.2s ease-in-out;
      color: $text--dark;
      &:hover {
        color: $new-primary;
      }
    }
    &:hover svg {
      transform: rotate(-90deg);
    }
    &.collapsed {
      svg {
        transform: rotate(-90deg);
      }
      &:hover svg {
        transform: rotate(0);
      }
    }
    &.isFocused {
      &:hover svg {
        transform: rotate(0);
      }
      &.collapsed {
        &:hover svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__body {
    padding-top: 12px;
    border-top: thin solid $border;
    display: flex;
    padding-bottom: 32px;
  }
  &__left {
    flex: 1.2;
    margin-right: 40px;
    @media (max-width: 1500px) {
      margin-right: 20px;
      flex: 1;
    }
  }
  &__right {
    flex: 1;
  }
  &__section {
    margin-bottom: 12px;
    h4 {
      @include text--h4;
    }
    p {
      @include text--body1;
      font-weight: 500;
      strong {
        font-weight: 700;
      }
      span {
        // margin-left: 8px;
        &.unpaid {
          color: $med;
        }
        &.paid {
          color: $low;
        }
      }
    }
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 12px 14px;
  }

  &__title {
    flex: 1;
    font-weight: 500;
    color: $dark-60;
    margin: 0 0 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $dark-80;
    &.long {
      font-size: 1.4em;
      white-space: inherit;
      overflow: auto;
      text-overflow: inherit;
    }
    @media (max-width: 825px) {
      margin: 0 0 0 8px;
      font-size: 22px;
    }
  }

  &__icons {
    display: flex;
    justify-content: flex-end;
  }

  &__popup {
    .dd-menu-items {
      margin-left: 20px;
      margin-top: 0px;
      width: 125px;
      font-size: 14px;
      font-weight: 300;
      ul {
        color: $dark-50;
        border-radius: 2px;
        li {
          padding: 8px;
          cursor: pointer;
          transition: all 0.25s;
          &:hover {
            background-color: $light-90;
          }
          &:active {
            background-color: $light;
          }
        }
        .disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
  }

  &__credits {
    height: 45px;
    width: 45px;
    transition: 0.05s all linear;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $mint;
      font-size: 24px;
    }
    h4 {
      position: absolute;
      margin: 0;
      color: $mint;
    }
    &:hover {
      svg {
        font-size: 26px;
        color: $mint--light;
      }
    }
    &:active {
      svg {
        font-size: 24px;
        color: $mint--dark;
      }
    }
  }

  &__delete {
    height: 45px;
    width: 45px;
    transition: 0.05s all linear;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $orange;
      font-size: 20px;
    }
    &:hover {
      svg {
        font-size: 22px;
        color: $orange--light;
      }
    }
    &:active {
      svg {
        font-size: 20px;
        color: $orange--dark;
      }
    }
  }

  &__action-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__action {
    font-size: 14px;
    background: $white;
    color: $link;
    border: thin solid $link;
    height: 30px;
    padding: 8px;
    min-width: 115px;
    transition: 0.05s all linear;
    outline: none;
    box-sizing: border-box;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    cursor: pointer;
    margin-right: 14px;
    box-shadow: 2px 6px 4px 0px #ccc;
    border-radius: 4px;

    &:hover {
      color: $link--light;
      border: thin solid $link--light;
      box-shadow: 0px 8px 4px 0px #ccc;
    }
    &:active {
      transition: 0.2s smooth all;
      border: thin solid $link--light;
      background-color: $link--light;
      box-shadow: none;
    }
    &.warning {
      color: $orange;
      border: thin solid $orange;
      box-shadow: none;
      &:hover {
        color: $orange--light;
        border: thin solid $orange--light;
        background-color: $white;
      }
    }
    &.primary {
      border: thin solid $link;
      text-decoration: none;
      background-color: $link;
      color: $white;
      &:hover {
        border: thin solid $link--light;
        background-color: $link--light;
      }
      &:active {
        box-shadow: none;
      }
    }
    &.disabled {
      pointer-events: none;
      background-color: $light;
      border: thin solid $light-110;
      box-shadow: none;
      color: $dark-60;
      cursor: not-allowed;
    }
    &--disabled {
      pointer-events: none;
      background-color: $light;
      border: thin solid $light;
      color: $dark-60;
    }
    &.secondary {
      border: none;
      text-decoration: underline;
      box-shadow: none;
      min-width: 50px;
      &.disabled {
        border: none;
        background-color: transparent;
      }
      &:hover {
        border: none;
        background-color: $white;
        box-shadow: none;
      }
    }
  }

  &__list {
    margin: 0 12px;
    padding: 0;
    list-style: none;
    li {
      color: $dark-75;
      padding: 8px 0 4px;
      // border-bottom: thin solid $border;
      display: flex;
      align-items: center;
      &:disabled {
        opacity: 0.3;
      }
    }
  }

  // &__deliverable-header-row {
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-end;
  // }

  // &__reopen {
  //   width: 132px;
  //   border: none;
  //   outline: none;
  //   background: transparent;
  //   text-align: center;
  //   transition: 0.05s all linear;
  //   cursor: pointer;
  //   color: $orange;
  //   text-decoration: underline;
  //   margin-top: 8px;
  //   margin-right: 4px;

  //   &:hover {
  //     transform: translateY(-1px);
  //     color: $orange--light;
  //   }
  //   &:active {
  //     color: $orange--dark;
  //     font-weight: bold;
  //   }
  // }

  // &__deliverable-header {
  //   // margin: 5px 8px;
  //   margin: 4px 8px;
  //   text-decoration: underline;
  //   color: $dark-75;
  //   font-size: 1.1em;
  //   font-weight: 400;
  // }

  &__status-labels {
    display: flex;
    align-items: center;
    padding: 6px 12px 4px;
    border-bottom: thin solid $border;
  }

  &__status-label {
    color: $text--light;
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    margin: 4px 0;
    &.deliverable-name {
      flex: 2;
    }
    &.deliverable-type {
      flex: 2;
    }
  }

  &__reviewers {
    display: flex;
    align-items: center;
    margin-left: 14px;
    h4 {
      margin-right: 12px;
    }
    p {
      margin-right: 12px;
    }
  }

  &__type {
    display: flex;
    align-items: center;
    flex: 2;
    padding-left: 12px;
  }

  &__type-label {
    color: $dark-80;
    font-size: 0.8em;
    margin-right: 8px;
  }

  &__actions {
    color: $dark-75;
    transition: all 0.25s;
    margin: 4px 8px 8px;
    align-items: center;

    display: flex;
    transition: 0.2s all linear;
    flex: 1;
    h4 {
      margin: 0 4px 0 0;
      font-size: 0.8em;
    }
    .styled-checkbox {
      margin-bottom: 0;
      font-size: 0.75em;
      .styled-checkbox__input {
        margin-top: 0;
      }
      .styled-checkbox__label {
        padding: 0 4px;
        margin-right: 8px;
      }
    }
  }

  &__card {
    max-width: 450px;
    min-width: 350px;
    background-color: $white;
    display: flex;
    // flex-direction: column;
    margin-bottom: 5px;
    padding: 8px;
  }
  &__pre {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark-50;
    margin-top: 5px;
    width: 20px;
    svg {
      color: $mint;
    }
  }

  &__body {
    h4 {
      @include text--h4;
      // margin-right: 8px;
      p {
        margin: 2px 0 0 2px;
      }
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
      a {
        margin: 0;
        padding: 0;
        min-width: 0;
        color: $link;
        &:hover {
          color: $link--light;
          text-decoration: underline;
        }
      }
      svg {
        margin-right: 3px;
      }
    }
  }

  &__deliverables {
    top: 2px;
  }

  &__deliverableToggle {
    color: $mint;
    transition: all 0.25s;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s all linear;
    margin-left: 4px;
    cursor: pointer;

    svg {
      font-size: 1em;
    }
    &.selected {
      transition: all 0.25s;
      background-color: $light-95;
      box-shadow: 3px 2px 3px 0px $light-110;
      color: $mint;
    }
    &:hover {
      background-color: $light-95;
      color: $mint;
      box-shadow: 3px 2px 3px 0px $light-110;
    }
    &:active {
      background-color: $light-95;
      color: $mint;
      box-shadow: none;
    }
  }

  &__label {
    color: $text--dark;
    font-size: 14px;
    flex: 2;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    .myBtn--link {
      color: $text--dark;
      &:hover {
        color: $link;
      }
      .myBtn__tooltip {
        bottom: 20px;
      }
    }
    span {
      color: $red;
    }
    &.indented {
      padding-left: 8px;
    }
  }

  &__status {
    color: $dark-80;
    font-size: 12px;
    flex: 1;
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
    .DocumentCredits__popup .DocumentCredits__toggle {
      height: 20px;
      width: 20px;
      h4 {
        font-size: 12px;
      }
      svg {
        font-size: 20px;
      }
    }
  }
  &__typeActions {
    color: $mint;
    transition: all 0.25s;
    border-radius: 9px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s all linear;
    margin-left: 2px;
    cursor: pointer;

    svg {
      font-weight: bold;
      font-size: 1.5em;
    }
    &.selected {
      transition: all 0.25s;
      background-color: $light-95;
      box-shadow: 3px 2px 3px 0px $light-110;
      color: $mint;
    }
    &:hover {
      background-color: $light-95;
      color: $mint;
      box-shadow: 3px 2px 3px 0px $light-110;
    }
    &:active {
      background-color: $light-95;
      color: $mint;
      font-size: 1.6em;
      box-shadow: none;
    }
  }
}
