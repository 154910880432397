@import 'navigation/navigation';
@import 'color/colors';

.termsOfServiceModal {
  h4 {
    @include text--h4;
  }
  p {
    @include text--body1;
    margin-bottom: 20px;
  }
  position: relative;
  outline: none;
  margin: 80px auto auto;
  border-radius: 8px;
  border: none;
  box-shadow: 3px 3px 3px 3px #33373859;
  display: flex;
  flex-direction: column;
  background-color: $white;
  min-height: 220px;
  overflow-y: scroll;
  width: 800px;
  max-height: 800px;
  max-width: 95vw;
  padding: 30px 40px 20px 60px;
  overflow-y: scroll;
  @media (max-height: 1080px) {
    margin-top: 40px;
    max-height: 85vh;
  }
  @media (max-width: 520px) {
    padding: 20px 30px 20px 40px;
  }
  @media (max-width: 1080px) {
    width: 680px;
  }
  @media (min-height: 1080px) {
    height: 80vh;
    max-height: 1400px;
  }
  &__overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    -webkit-overflow-scrolling-: 'touch';
    .myBtn--link {
      align-self: flex-end;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    h1 {
      @include text--h1;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  &__exit {
    // position: absolute;
    // align-self: flex-end;
    // margin-top: -3px;
    height: 30px;
    // color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
}
