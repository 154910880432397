@import 'color/colors';
@import 'navigation/navigation';

.notices {
  position: fixed;
  align-self: center;
  bottom: 5vh;
  display: flex;
  flex-direction: column;
  transition: all 0.1s;
  z-index: $toast-notice-z-index;
}

.notice__close {
  font-size: 18px;
  color: $white;
  transition: all 0.1s linear;
  align-self: flex-start;
  margin: -14px -10px 0 0;
  &:hover {
    cursor: pointer;
    color: $primary;
    font-weight: bold;
  }
}

.notice {
  min-width: 225px;
  padding: 15px;
  vertical-align: middle;
  color: $white;
  background-color: $low--light;
  padding: 18px;
  font-size: 16px;
  border-radius: 8px;
  display: flex;
  margin: 5px;
  align-items: center;
  opacity: 1;
  transition: 1s all;
  display: flex;
  box-shadow: -3px 3px 3px 1px #dae0e359;
  justify-content: space-between;
  .myBtn--link {
    color: $white;
    // text-decoration: none;
    svg {
      color: $white;
      margin: 0 4px 0 8px;
    }
    &:hover {
      color: $link--light;
      text-decoration: underline;
      svg {
        color: $link--light;
      }
    }
  }
  &.hide {
    opacity: 0;
  }
  &.error {
    background-color: $red--light;
    color: $white;
  }
  &.warning {
    background-color: $orange--light;
    color: $white;
  }
}
