@import 'navigation/navigation';
@import 'color/colors';
@import 'color/color-variables';

.register-confirm {
  box-sizing: border-box;
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
  @media (max-width: 960px) {
    width: 100vw;
  }
}
.form__confirm-email {
  display: table;
  border-spacing: 0;
  clear: both;
  margin-bottom: 32px;
  .digit__group {
    display: inline-block;
    .digit__wrapper {
      display: table-cell;
      border: 1px solid $light;
      background: white;
      .input__digit {
        width: 65px;
        height: 80px;
        padding: 12px;
        border: none;
        font-size: 40px;
        text-align: center;
        margin-bottom: 8px;
        -webkit-appearance: none;
        appearance: none;
        color: $type--medium;
        :focus {
          outline: none;
        }
        @media (max-width: 960px) {
          width: 50px;
          height: 55px;
        }
      }
      :not(:first-child) {
        border-left: none;
      }
      :first-child {
        border-radius: 3px 0 0 3px;
      }
      :last-child {
        border-radius: 0 3px 3px 0;
      }
    }
  }
  .digit__span {
    display: table-cell;
    font-weight: 700;
    font-size: 40px;
    vertical-align: middle;
    padding: 0 8px;
  }
  @media (max-width: 960px) {
    margin: 0 auto 32px;
  }
}

.confirm-email__label {
  padding-bottom: 6px;
  font-size: 17px;
  display: block;
  color: $type--dark;
}

.confirm-email__loading {
  font-size: 24px;
  color: $primary;
  text-align: center;
  margin: 1em;
  clear: both;
}

.confirm-email__content {
  width: 480px;
  padding: 0 16px;
  margin: 0 auto;
  @media (max-width: 960px) {
    width: 100%;
  }
}

.confirm-email__info {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: $warning;
}

.confirm-email__instructions {
  font-size: 20px;
  font-weight: normal;
  color: $type--light;
  span {
    font-weight: bold;
  }
}

.confirm-email__link {
  border: none;
  background-color: transparent;
  color: $blue;
  font-size: 15px;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
    color: $blue--light;
    cursor: pointer;
  }
  &:focus {
    outline: none;
    text-decoration: underline;
    color: $blue--light;
  }
}
