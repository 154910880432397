.text-field__salary {
  display: flex;
  width: 100%;
  max-width: 600px;
  .styled-text-field__container {
    flex: 1.5;
    margin-right: 20px;
    @media (max-width: 876px) {
      margin-right: 8px;
    }
  }
  .input-select__container {
    flex: 1;
  }
}
