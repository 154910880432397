@import 'color/colors';

.userReportsModal {
  position: relative;
  max-width: 90%;
  min-height: 260px;
  min-width: 540px;
  width: 600px;
  padding: 40px 70px;
  background-color: $bg--page;
  outline: none;
  margin: auto;
  border: none;
  border-radius: 4px;
  box-shadow: 3px 3px 3px 3px #33373859;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__overlay {
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    padding: 20px 0;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling-: 'touch';
  }
  &__top {
    // padding: 0 40px 20px 70px;
    position: relative;
    h1 {
      @include text--h1;
      margin: 0;
      display: flex;
      align-items: center;
      span {
        color: $red;
      }
      .myBtn--icon {
        margin-left: 8px;
      }
    }
  }
  &__exit {
    position: absolute;
    align-self: flex-end;
    top: -16px;
    right: -28px;
    font-size: 20px;
    color: $primary;
    cursor: pointer;
    &:hover {
      color: $primary--light;
    }
    &:active {
      color: $primary--dark;
    }
    &:focus {
      color: $primary--dark;
    }
  }
  &__checkboxHeader {
    margin-bottom: 12px;
    .styled-checkbox__label {
      @include text--h2;
      font-size: 18px;
      margin-left: 4px;
      font-weight: 500;
    }
  }
  &__body {
    padding: 8px 0 0;
    .multiToggle__label {
      @include text--h2;
      font-size: 18px;
      margin-left: 0;
      font-weight: 500;
    }
  }
  &__formLink {
    padding-left: 0;
  }
  &__formHeader {
    @include text--h2;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
}
