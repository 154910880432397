@import 'color/colors';

.entitySelect {
  &__header {
    margin: 40px 0 5px;
    max-width: 1080px;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    h1 {
      margin: 5px 0;
      color: $text--dark;
      font-size: 24px;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        @include text--body1;
      }
    }
    h2 {
      color: $dark-75;
      margin: 8px;
    }
    .myBtn {
      margin: 4px 0 8px 20px;
      min-width: 154px;
      text-decoration: none;
      svg {
        position: relative;
        margin-right: 4px;
        // top: 2px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    &--empty {
      width: fit-content;
    }
    @media (max-width: 1280px) {
      width: 80vw;
      min-width: 768px;
      max-width: 100%;
      margin-top: 20px;
    }
    @media (max-width: 1080px) {
      width: 90vw;
    }
    @media (max-width: 876px) {
      min-width: 500px;
      width: 100%;
    }
    @media (max-width: 768px) {
      padding-left: 0;
    }
    @media (max-width: 576px) {
      padding: 40px 0 5px;
    }
  }
  &__divider {
    width: 100%;
    height: 1px;
    background-color: $border;
  }
  &__groups {
    max-width: 1080px;
  }
  &__group {
    max-width: 1080px;
  }
  &__groupHeader {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    h2 {
      @include text--h1;
      margin: 0;
      font-weight: 600;
    }
  }
  &__groupList {
    max-width: 1080px;
    margin: 4px 12px 12px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
    box-shadow: $shadow__table;
    background-color: $white;
    border-radius: 16px;
    min-width: 800px;
    padding: 8px 20px 20px;

    @media (max-width: 876px) {
      min-width: 662px;
      .entitySelect__groupListHeader,
      .entitySelect__groupListItem {
        min-width: unset;
      }
    }

    -webkit-overflow-scrolling: 'touch';
    &--ungrouped {
      margin-top: 60px;
    }
    &.fourCols {
      grid-template-columns: 500px 180px 180px 180px;
    }
    &.fiveCols {
      grid-template-columns: 408px 140px 140px 180px 168px;
    }
    h4 {
      margin: 0;
      font-size: 14px;
    }

    &-none {
      grid-column-start: 1;
      grid-column-end: 4;
      h2 {
        @include text--h2;
        color: $text--dark-1;
        margin: 12px 0 8px;
        .myBtn--link {
          font: inherit;
        }
      }
      .myBtn {
        margin-left: 4px;
        margin-right: 4px;
      }
    }

    @media (max-width: 1400px) {
      &.fourCols {
        grid-template-columns: 400px 160px 160px 168px;
      }
      &.fiveCols {
        grid-template-columns: 360px 130px 130px 172px 168px;
      }
    }

    @media (max-width: 1080px) {
      &.fourCols {
        grid-template-columns: 360px 130px 130px 168px;
      }
      &.fiveCols {
        grid-template-columns: 300px 130px 130px 160px 168px;
      }
    }

    /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
    &::-webkit-scrollbar-track {
      visibility: hidden;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
    &:hover {
      &::-webkit-scrollbar-track {
        visibility: visible;
      }
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
    /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
  }

  &__groupListHeaders {
    display: contents;
    h4:first-child {
      padding-left: 30px;
    }
    h4:last-child {
      padding-right: 16px;
    }
  }

  &__groupListHeader {
    color: $medDark;
    font-size: 14px;
    font-weight: 400;
    padding: 16px 28px 16px 16px;
    margin-top: 4px;
    position: relative;
    min-width: 130px;
    background-color: $white;
    border-radius: 12px;
    cursor: pointer;
    svg {
      color: $medDark;
      margin-left: 2px;
      position: absolute;
      margin-left: 4px;
    }
    &--active {
      font-weight: 700;
      color: $text--dark-2;
    }
    &:hover {
      color: $dark-80;
      text-decoration: underline;

      background-color: $light-95;
      svg {
        color: $blue--dark;
      }
    }
    @media (max-width: 1400px) {
      padding-right: 24px;
    }
  }

  &__groupListRow {
    display: contents;
    position: relative;
    &:hover {
      .entitySelect__groupListItem {
        border-top: thin solid $link;
        border-bottom: thin solid $link;
      }
      .entitySelect__groupListItem--first {
        border-left: thin solid $link;
      }
      .entitySelect__groupListItem--last {
        border-right: thin solid $link;
      }
    }
  }

  &__groupListItem {
    margin: 0;
    min-width: 130px;
    padding: 10px 10px 10px 20px;
    border-top: thin solid transparent;
    border-bottom: thin solid transparent;
    color: $dark-50;
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    min-height: 68px;
    cursor: pointer;

    .entitySelect__dropdown {
      position: absolute;
      right: 20px;
    }

    &--first {
      grid-column: 1;
      padding-left: 30px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      border-left: thin solid transparent;
    }
    &--last {
      padding-right: 30px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      border-right: thin solid transparent;
    }
    &-company-name {
      color: $text--dark;
      font-size: 16px;
      font-weight: bold;
    }
    &-label {
      color: $text--dark-2;
      font-size: 14px;
      font-weight: normal;
    }
  }
  &__dropdown {
    .dropdown {
      width: 220px;
      padding-bottom: 20px;
      .myBtn--secondary {
        justify-content: flex-start;
        border: none;
        padding: 8px 8px;
        margin: 4px 12px;
        width: 356px;
        border-radius: 8px;
        transition: 0.25s;
        font-size: 14px;

        &:not(&:disabled):hover {
          background-color: $hover;
          color: $text--dark-2;
          text-decoration: none;
          &.isWarning {
            color: $warning--light;
          }
        }
        &:disabled:hover {
          background-color: inherit;
          color: $light-110;
        }
      }
    }
  }
  &__dropdownHeader {
    display: flex;
    align-items: center;
    margin: 20px 20px 12px;
    h4 {
      @include text--h4;
      border: none;
      padding: 0 4px 0 0;
    }
  }
  &__dropdownDivider {
    height: 1px;
    background-color: $border;
    margin-left: 4px;
    flex: 1;
  }

  &__modal {
    position: relative;
    min-height: 400px;
    outline: none;
    border: none;
    box-shadow: 3px 3px 3px 3px #33373859;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    background-color: $bg--page;
    overflow: visible;
    margin: 20px auto;
    width: 480px;
    height: 340px;
    max-width: 95vw;
    max-height: 95vh;
    padding: 24px 30px 24px 40px;
    border-radius: 16px;
    &Overlay {
      z-index: 9;
      background-color: rgba(0, 0, 0, 0.66);
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      display: flex;
      overflow-y: auto;
      -webkit-overflow-scrolling-: 'touch';
    }
    &Header {
      display: flex;
      h2 {
        @include plans__h2;
        font-size: 24px;
        font-weight: 700;
        color: $text--dark;
        margin-top: 0;
        margin-bottom: 8px;
      }
    }
    &Subheader {
      @include plans__h3;
      font-size: 16px;
      margin-bottom: 12px;
    }
    &Exit {
      position: absolute;
      align-self: flex-end;
      right: 30px;
      top: 28px;
      font-size: 24px;
      color: $text--dark;
      cursor: pointer;
      &:hover {
        color: $primary--light;
      }
      &:active {
        color: $primary--dark;
      }
      &:focus {
        color: $primary--dark;
      }
    }
    &Actions {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}
