@import 'color/colors';
@import 'navigation/navigation';

.sidebar {
  &__container-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba($dark-85, 0.5);
    z-index: $sidebar-z-index + 2;
    @media (max-width: 876px) {
      display: block;
    }
  }
  &__container {
    // &.dataRoom {
    //   .sidebar__arrow {
    //     left: 220px;
    //   }
    // }
    background-color: $bg--nav;
    // box-shadow: 2px 0px 5px 0px $light;
    min-width: $sidebar-width;
    width: $sidebar-width;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $border;
    margin-right: 4px;
    z-index: $sidebar-z-index + 1;
    @media (max-width: 876px) {
      z-index: 7;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
    }
    transition: all 0.2s ease-in-out;

    &.is-overlay {
      position: relative;
      transform: translateX(0);
      top: 0px;
      padding: 0;
      z-index: $sidebar-z-index + 2;
      box-shadow: none;
      border-right-color: $border--dark;
      animation: animateRight 0.3s;
      &:focus {
        outline: none;
      }
      .sidebar__nav {
        border-top-color: $border--dark;
      }
    }
    @keyframes animateRight {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0);
      }
    }
    @media (min-width: 2300px) {
      &:not(.isWorkbench) {
        position: fixed;
        top: 0;
        bottom: 0;
        .sidebar__logo,
        .sidebar__arrow {
          position: fixed;
          width: 220px;
        }
        .sidebar__arrow.is-overlay {
          width: unset;
        }
        .sidebar__nav {
          position: relative;
        }
      }
    }
  }

  &__logo {
    height: $topbar-height - 1px;
    // border-bottom: thin solid $border--light-1;
    display: flex;
    align-items: center;
    // padding-left: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    transition: 0.2s;
    cursor: pointer;
    position: sticky;
    top: 0;
    &:hover {
      .sidebar__logoImg {
        color: $new-primary;
        filter: $theme-filter;
      }
      img {
        filter: $theme-filter;
      }
    }
    &.isDisabled {
      pointer-events: none;
    }
    img {
      transition: width 0.2s, height 0.1s, margin 0.1s;
      // width: 100px;
      max-width: 140px;
      max-height: 48px;
    }
    &--bottom {
      top: unset;
      bottom: 40px;
      left: 0;
      position: fixed;
      height: fit-content;
      min-height: 40px;
      width: $sidebar-width - 8px;
      backdrop-filter: blur(1px);
      background-color: transparentize($bg--nav, 0.8);
      z-index: $sidebar-z-index + 1;
      img {
        filter: none;
      }
      @media (max-width: 576px) {
        bottom: 20px;
      }
    }
  }

  &__logoImg--white {
    filter: brightness(0) invert(1);
  }

  &__paperOS {
    position: absolute;
    top: unset;
    left: 54px;
    text-align: right;
    display: flex;
    h4 {
      font-size: 10px;
      color: $white;
      margin: 0;
      width: 60px;
      margin-top: 2px;
    }
    img {
      width: 50px;
    }
  }

  &__arrow {
    z-index: $sidebar-z-index + 1;
    position: fixed;
    top: $topbar-height;
    left: $sidebar-width - 50px;
    width: 32px;
    background-color: transparent;
    display: flex;
    height: 50px;
    padding: 5px 10px;
    align-items: center;
    color: $white;
    font-size: 20px;
    transition: 0.1s linear;
    cursor: pointer;
    border: none;
    backdrop-filter: blur(1px);
    background-color: transparentize($bg--nav, 0.8);
    z-index: $sidebar-z-index + 1;
    &.is-overlay {
      left: $sidebar-width - 42px;
      top: 0;
      width: 40px;
      font-size: 26px;
      z-index: $sidebar-z-index + 3;
      color: $text--light;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 18px;
      }
    }
    &:hover {
      cursor: pointer;
      color: $new-primary;
    }
    display: none;
    @media (max-height: 876px) {
      display: flex;
    }
    @media (max-width: 1200px) {
      display: flex;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    padding: 60px 0 56px;
    height: 650px;
    max-height: calc(100vh - $topbar-height);
    overflow-y: auto;
    overflow-x: hidden;
    position: sticky;
    top: $topbar-height - 1px;
    border-top: thin solid $border--dark;

    &--roomnav {
      top: 40px;
      border-top: none;
      .sidebar__exit {
        padding-right: 0px;
      }
    }
    &-goto-transaction {
      height: 60px;
      .myBtn {
        color: $text--dark;
      }
    }
    @media (max-width: 1080px) {
      padding-top: 50px;
    }
    @media (max-height: 876px) {
      padding-top: 50px;
      padding-bottom: 84px;
    }
  }

  &__divider {
    width: calc(100% - 40px);
    height: 2px;
    background: transparentize($bg--page, 0.8);
    z-index: $sidebar-z-index + 1;
    margin: 15px auto;
    transition: 0.2s;

    @media (max-width: 825px) {
      // display: none;
      margin: 8px auto;
    }
  }

  &__toggle {
    padding-left: 48px;
    transition: 0.2s;
    height: 56px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 650px + $topbar-height;
    cursor: pointer;
    width: $sidebar-width;

    @media (max-height: 840px) {
      top: unset;
      bottom: 80px;
    }
    @media (max-height: 786px) {
      display: none;
    }
    @media (max-width: 1200px) {
      display: none;
    }
    &Icons {
      transition: all 0.2s ease-in-out;
      display: flex;
      svg {
        color: $text--light;
        transition: transform 0.2s ease-in-out 0.2s;
      }
      .left-icon {
        margin-right: 4px;
      }
    }
    h4 {
      margin-left: 12px;
      color: $text--light;
      font-size: 12px;
    }
    &:hover {
      svg {
        color: $theme-light--lighter;
      }
      h4 {
        color: $theme-light--lighter;
      }
    }
    &.isCollapsed {
      .left-icon {
        transform: rotate(180deg);
      }
      .right-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__toggleOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 59px;
    width: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: $bg--nav;
    z-index: 7;
    cursor: pointer;
    transition: 0.2s;
    background-color: $white;
    svg {
      color: $text--dark-2;
      font-size: 26px;
    }
    &:hover {
      svg {
        color: $new-primary;
      }
    }
    &.isDisabled {
      opacity: 0.8;
      cursor: default;
      svg {
        color: $text--light;
      }
      &:hover {
        svg {
          color: $text--light;
        }
      }
    }
  }

  &__overlay {
    z-index: $sidebar-z-index + 4;
    background-color: rgba(0, 0, 0, 0.66);
    overflow-y: auto;
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    top: 0;
    left: 0;
    -webkit-overflow-scrolling: touch;
  }

  /** Collapsed Sidebar **/
  &__container.hide {
    width: 68px;
    min-width: 68px;
    position: relative;
    &.isWorkbench {
      position: fixed;
    }
    .sidebar__logo {
      width: 68px;
      padding-left: 0;
      justify-content: center;
      img {
        max-width: 40px;
      }
    }
    .sidebar__arrow {
      border-top: none;
      left: 20px;
    }
    .project-header {
      max-width: 0;
    }
    .sidebarNavLink {
      padding-left: 0;
      justify-content: center;
      &__text {
        display: none;
      }
      &__company {
        &Wrapper {
          padding-left: 0;
          justify-content: center;
          width: 100%;
          svg {
            display: none;
          }
        }
        &Text {
          display: none;
        }
        &Menu {
          left: 8px;
          &Arrow {
            left: 20px;
          }
        }
      }
      .savvi-tooltip {
        left: 0;
        min-width: fit-content;
        max-width: 68px;
        bottom: 30px;
        padding: 8px;
        font-size: 11px;
      }
    }
    .sidebar__toggle {
      padding-left: 0;
      width: 68px;
      justify-content: center;
      h4 {
        display: none;
      }
    }
    .sidebar__exit {
      left: 126px;
      &--inner {
        left: 80px;
        @media (max-width: 524px) {
          left: 60px;
          padding-left: 12px;
        }
      }
      &--warning,
      &--portalWarning {
        left: 212px;
        @media (max-width: 867px) {
          background-color: transparentize($white, 0.5);
          backdrop-filter: blur(1px);
          top: 55px;
          left: 68px;
          max-width: unset;
          font-size: 16px;
          padding-left: 12px;
        }
      }
    }
    .workbench-nav__content {
      width: 0;
      overflow-y: hidden;
    }
    .workbench-nav__exit {
      width: unset;
      left: 176px;
    }
    .sidebar__exit--portal {
      top: 0;
      color: $text--dark;
      left: 68px;
      svg {
        margin-right: 4px;
      }
    }
    .sidebar__logo--bottom {
      bottom: 48px;
      width: 68px;
      min-height: 40px;
      @media (max-width: 576px) {
        bottom: 20px;
      }
    }
    .sidebar__paperOS {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      left: 2px;
      top: unset;
      h4 {
        font-size: 9px;
      }
    }
  }

  /** Workflow & Teams Nav **/
  &__container.white {
    background-color: $white;
    border-right-color: #eae9e9;
    margin-right: 0;

    .sidebar__logo {
      // border-bottom: thin solid $border--dark;
      padding-left: 68px;
      justify-content: flex-start;
      img {
        filter: brightness(0);
      }
      .sidebar__paperOS img {
        filter: none;
      }
      &:hover img {
        filter: $theme-filter;
      }
      &--center {
        padding-left: unset;
        justify-content: center;
      }
      &--bottom {
        background-color: transparentize($white, 0.8);
        @media (max-width: 576px) {
          bottom: 0px;
          width: 140px;
          height: 30px;
          min-height: 30px;
          .sidebar__paperOS {
            flex-direction: row;
          }
        }
      }
    }
    .sidebar__paperOS h4 {
      color: $text--dark;
    }

    .sidebar__arrow {
      color: $text--dark-1;
      background-color: transparentize($white, 0.8);
      z-index: 7;
      &:hover {
        color: $new-primary;
      }
    }

    .sidebar__nav {
      top: 60px;
    }

    .sidebarNavLink {
      &__icon {
        color: $text--dark-1;
        border-color: $text--dark-1;
      }
      &__text {
        color: $text--dark-1;
      }
      &--selected {
        .sidebarNavLink__icon {
          color: $text--dark;
          font-weight: 800;
        }
        .sidebarNavLink__text {
          color: $text--dark;
          font-weight: 800;
        }
      }
      &:hover {
        .sidebarNavLink__icon {
          color: $new-primary;
          border-color: $new-primary;
        }
        .sidebarNavLink__text {
          color: $new-primary;
        }
      }
      &__company {
        &Wrapper {
          svg {
            color: $text--dark;
          }
        }
        &Icon {
          color: $text--dark;
          border-color: $text--dark-1;
        }
        &Text {
          color: $text--dark;
        }
      }
    }
    .sidebar__toggle {
      svg,
      h4 {
        color: $text--dark-1;
      }
      &:hover {
        svg,
        h4 {
          color: $new-primary--light;
        }
      }
    }
    .project-header {
      background-color: $white;
      &__help {
        a {
          color: $text--dark;
        }
        &:hover a {
          color: $link;
        }
      }
      &__title {
        color: $text--dark;
      }
    }
    .workbench-nav__group-header h2 {
      color: $text--dark-1;
    }
    .workbench-nav__fade {
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 30%, $white 100%);
    }
    .workbench-nav__link {
      color: $text--dark-1;
      svg {
        color: $text--dark-1;
      }
      &-items {
        h4 {
          color: $text--dark-1;
        }
        p {
          color: $text--dark-1;
        }
      }
      &--selected {
        .workbench-nav__link-items {
          h4 {
            color: $text--dark;
          }
        }
      }
      &:hover {
        h4 {
          color: $new-primary;
        }
        svg {
          color: $new-primary;
        }
        .workbench-nav__link-items {
          h4 {
            color: $new-primary;
          }
        }
      }
    }
    &.isTeamView .workbench-nav__exit {
      left: 100px;
      @media (max-width: 576px) {
        top: 60px;
      }
    }
    .sidebar__paperOS h4 {
      color: $text--dark;
    }
    &.hide {
      width: 0;
      min-width: 0;
      .sidebar__logo {
        padding-left: 0px;
        justify-content: center;
      }
      &.isTeamView {
        width: 70px;
        min-width: 70px;
      }
      &.isDataRoom {
        width: 70px;
        min-width: 70px;
      }
    }
  }
  // &__container.dataRoom {
  //   .sidebar__logo {
  //     padding-left: 53px;
  //   }
  // }
  &__exit {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    height: $topbar-height - 2px;
    font-weight: normal;
    color: $text--dark;
    position: fixed;
    background-color: transparent;
    top: 0;
    left: 272px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    color: $text--dark;

    svg {
      font-size: 16px;
      margin: 0 4px;
    }

    &:hover {
      color: $primary--light;
      text-decoration: underline;
    }
    &--inner {
      left: 55px;
    }
    &--portal {
      top: 0;
      left: 278px;
      svg {
        margin-right: 4px;
      }
    }
    &--warning {
      color: $orange;
      // left: 420px;
      width: unset;
      &:hover {
        color: $orange--light;
      }
      @media (max-width: 876px) {
        top: 55px;
        left: 0px;
        max-width: 174px;
        font-size: 14px;
      }
    }
    &--portalWarning {
      color: $orange;
      left: 420px;
      width: unset;
      &:hover {
        color: $orange--light;
      }
    }
  }
  &__container.isToggleOverlayShowing {
    .sidebar__paperOS {
      left: 92px;
    }
  }
  &__container.isToggleOverlayShowing.hide .sidebar__paperOS {
    left: 2px;
  }
}
