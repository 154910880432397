@import 'color/colors';

.multiToggle {
  margin: 16px 0;
  &--disabled {
    .multiToggle__option {
      pointer-events: none;
      background-color: $border--light;
      color: $white;
      &--checked {
        background-color: $border--dark;
      }
    }
  }
  &__label {
    @include text--label;
  }
  &__options {
    display: flex;
    width: 260px;
    margin-top: 8px;
    :first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    :last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  &__option {
    @include text--body2;
    font-weight: 500;
    flex: 1;
    height: 26px;
    cursor: pointer;
    text-align: center;
    line-height: 26px;
    border: thin solid $border;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: $bg--status-light;
      color: $white;
    }
    &:active {
      background-color: $bg--status;
    }
    &:focus {
      background-color: $bg--status;
    }
    &--checked {
      background-color: $bg--status;
      color: $white;
      &:hover {
        background-color: $bg--status-light;
        color: $white;
      }
    }
    &--disabled {
      pointer-events: none;
      background-color: $border--light;
      color: $white;
      .multiToggle__option--checked {
        background-color: $border--dark;
      }
    }
  }
}
