@import 'color/colors';

.documentsSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 800px;
  margin: 40px 0 0;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
    @media (max-width: 876px) {
      align-items: flex-start;
    }
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
  &__headerLabel {
    @include text--h1;
    margin: 0;
    svg {
      font-size: 1.25em;
      margin-top: 4px;
      margin-right: 5px;
      color: inherit;
    }
    @media (max-width: 576px) {
      font-size: 18px;
    }
  }
  &__headerDivider {
    height: 1px;
    background-color: $border;
    flex: 1;
    margin-left: 4px;
  }
  /** *** LIST START *** **/
  &__list {
    display: grid;
    grid-template-columns: minmax(130px, 160px) minmax(240px, 3fr) minmax(160px, 240px) minmax(
        120px,
        144px
      );
    width: 100%;
    background-color: $white;
    border-radius: 16px;
    box-shadow: $shadow__table;
    justify-content: stretch;
    min-width: 800px;

    @media (max-width: 876px) {
      min-width: 600px;
    }

    &.unsetHeight {
      max-height: none;
      margin-bottom: 20px;
    }
    &.simpleTable {
      grid-template-columns: minmax(130px, 160px) minmax(240px, 3fr) minmax(148px, 180px) minmax(
          128px,
          148px
        );
      .documentsSection__listItem--label {
        grid-column: 2 / 3;
      }
      // .documentsSection__recipient {
      //   margin-top: 24px;
      // }
    }
    &.myTasks {
      grid-template-columns:
        minmax(130px, 160px) minmax(240px, 3fr) minmax(160px, 180px) minmax(232px, 240px)
        minmax(120px, 144px);
      @media (max-width: 968px) {
        grid-template-columns:
          minmax(120px, 160px) minmax(240px, 3fr) minmax(120px, 180px)
          minmax(224px, 240px) minmax(116px, 144px);
        min-width: 800px;
      }
      &.simpleTable {
        grid-template-columns:
          minmax(130px, 160px) minmax(240px, 3fr) minmax(148px, 180px)
          minmax(128px, 148px) minmax(120px, 140px);
        min-width: 780px;

        // .documentsSection__listItem.wide {
        //   grid-column-end: 4;
        // }
      }
      min-width: 880px;
      @media (max-width: 1080px) {
        .documentsSection__listDocumentName {
          max-width: 400px;
        }
      }
    }

    &Row {
      background-color: inherit;
      cursor: pointer;
      display: contents;
      &.firstRow {
        .documentsSection__listItem,
        .documentsSection__recipients,
        .documentsSection__simpleItem {
          border-top: none;
        }
        .documentsSection__listItem.first {
          border-top-left-radius: 16px;
        }
        .documentsSection__listItem--last {
          border-top-right-radius: 16px;
        }
      }
      &:hover {
        background-color: $hover;
        .FilePreviewer__previewSVG {
          opacity: 0.6;
        }
      }
      .PDFViewer__wrapper {
        position: absolute;
        top: 8px;
        left: 4px;
        &.isPdfHiding {
          left: 8px;
          top: unset;
          margin-top: 0px;
          .PDFViewer__action {
            @include text--body1;
          }
        }
      }
      &--upload {
        .PDFViewer__wrapper {
          top: unset;
          margin-top: 40px;
        }
      }
      &--simpleTable:hover,
      &--roomAdmin:hover {
        cursor: default;
        background-color: inherit;
        .FilePreviewer__previewSVG {
          opacity: unset;
        }
      }
      // &--simple {
      //   .documentsSection__listItem--label {
      //     grid-column: 2 / 3;
      //     // margin-right: 40px;
      //   }
      //   .documentsSection__recipients {
      //     align-items: center;
      //     // margin-left: -40px;
      //     // width: 140px;
      //   }
      // }
    }
    &Item {
      align-items: flex-start;
      flex-direction: column;
      background-color: inherit;
      border-top: 2px solid $light-95;
      display: flex;
      padding: 28px 8px 24px 0;
      margin: 0;
      margin-bottom: 0;
      overflow: visible;
      padding-right: 8px;
      // position: relative;
      h2 {
        @include text--h2;
      }
      &.first {
        grid-column: 1;
        // @include text--h2;
        padding-right: 20px;
        padding-left: 30px;
        border-bottom-left-radius: 16px;
        // padding-bottom: 20px;
      }
      &.wide {
        grid-column-start: 2;
        grid-column-end: 3;
      }
      &--last {
        align-items: flex-end;
        text-align: end;
        padding-right: 40px;
        border-bottom-right-radius: 16px;
      }
      p {
        @include text--body1;
        justify-self: flex-start;
        width: 100%;
        position: relative;
      }
      h4 {
        @include text--h4;
      }
      &--company {
        p {
          width: fit-content;
        }
      }
      &.noLabel {
        p {
          @include text--h2;
        }
      }
    }
    &ToggleActions {
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: flex-end;
      grid-column-start: 1;
      grid-column-end: 6;
      margin-bottom: 12px;
    }
    &Toggle {
      // grid-column-start: 1;
      // grid-column-end: 6;
      margin: 4px 0;
      color: $text--dark-1;
      width: 170px;
      display: flex;
      justify-content: space-between;
      svg {
        margin-left: 4px;
        margin-top: 2px;
      }
      // &:hover {
      //   color: $link--light;
      // }
    }
    &Actions {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-top: 4px;
      &--open {
        display: flex;
        .myBtn--icon {
          border-color: $border--light;
          &:disabled {
            border-color: $light-110;
            cursor: not-allowed;
            pointer-events: unset;
          }
          &:hover {
            border-color: $link--light;
            color: $link--light;
            &:disabled {
              border-color: $light-110;
              color: $light-110;
            }
            &.isWarning {
              color: $warning--light;
              border-color: $warning--light;
            }
          }
        }
      }
      &--float {
        position: absolute;
        right: 40px;
        margin-top: 44px;
      }
      .myBtn {
        margin: 0 0 0 12px;
        opacity: 1;
        background-color: $white;
      }
    }
  }
  &__docLabel {
    display: flex;
    width: fit-content;
    position: relative;
    .styled-text-field__container {
      min-width: 400px;
    }
  }
  &__docEditActions {
    position: absolute;
    left: 100%;
    top: -16px;
    backdrop-filter: blur(1px);
    background-color: transparent;
    display: flex;
    .myBtn--icon {
      background-color: $white;
      color: $new-link;
      opacity: 0.8;
    }
  }
  &__recipients {
    align-items: flex-start;
    flex-direction: column;
    background-color: inherit;
    border-top: 2px solid $light-95;
    display: flex;
    padding: 28px 8px 8px 0;
    margin: 0;
    overflow: visible;
    padding-right: 8px;
    // position: relative;
    h4 {
      @include text--h4;
    }
  }
  &__recipient {
    display: flex;
    align-items: center;
    margin: 0 0 4px;
    white-space: pre;
    // &--nonAdmin {
    //   margin-top: 24px;
    // }
    &Action {
      min-width: 140px;
      padding: 4px 24px;
    }
    &Label {
      @include text--body2;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      width: 90px;
      margin-right: 8px;
    }
    svg {
      margin: 0 8px;
    }
    &Status {
      @include text--body2;
      position: relative;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
      // width: 140px;
      p {
        @include text--body2;
        margin-right: 8px;
        display: flex;
        align-items: center;
        text-decoration: underline;
        display: flex;
        span {
          flex: 1;
        }
        svg {
          transition: 0.3s;
          flex: 0.2;
        }
      }
      .dropdown {
        right: -4px;
      }
      &:hover {
        p {
          color: $link--light;
        }
      }
      &--hideActions {
        pointer-events: none;
      }
    }
    &Warning {
      color: $orange;
      font-size: 14px;
    }
    &Chevron {
      font-weight: bolder;
    }
    // &--simple {
    //   align-self: flex-end;
    // }
  }
  /** *** LIST END *** **/
  &__simpleItem {
    align-items: flex-end;
    flex-direction: column;
    background-color: inherit;
    border-top: 2px solid $light-95;
    display: flex;
    padding: 28px 8px 8px 0;
    margin: 0;
    overflow: visible;
    padding-right: 8px;
    // position: relative;
    h4 {
      @include text--h4;
    }
  }
  &__simpleAction {
    padding: 4px 8px;
  }

  &--room {
    min-width: unset;
    padding: 40px 40px 0;
    margin: 0;
    .documentsSection {
      &__header {
        align-items: center;
        padding: 0;
      }
      &__headerLabel {
        @include text--h4;
      }
      &__list {
        min-width: unset;
        box-shadow: none;
        grid-template-columns: 0 minmax(240px, 3fr) minmax(160px, 240px) minmax(
            120px,
            144px
          );
        &.simpleTable {
          grid-template-columns: 0 minmax(240px, 3fr) minmax(160px, 240px) minmax(
              120px,
              144px
            );
        }
      }
      &__listItem {
        padding: 12px 8px 12px 0;
        &.first {
          display: none;
        }
      }
      &__recipients {
        padding-top: 12px;
      }
    }
  }
}
