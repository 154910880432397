@import 'color/colors';
@import 'navigation/navigation';

.consistentTopbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 100vw;
  width: 100%;
  background-color: $white;
  // box-shadow: 5px 0px 5px 0px $light;
  border-bottom: thin solid $border--light;
  height: $topbar-height;
  display: flex;
  justify-content: stretch;
  z-index: $bars-z-index - 1;
}

.consistentTopbar__nav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: auto;
  margin: 5px 0;
  padding: 0 15px;
}

.consistentTopbar__back {
  @include text--h2;
  color: $text--dark-1;
  position: absolute;
  top: 0;
  line-height: 60px;
  left: 200px;
  color: $blue;
  cursor: pointer;
  svg {
    margin-right: 5px;
    font-size: 1em;
  }
  &:hover {
    color: $new-primary;
  }
  @media (max-width: 1400px) {
    left: 100px;
  }
  @media (max-width: 1080px) {
    left: 60px;
  }
  @media (max-width: 876px) {
    left: 20px;
  }
}

.consistentTopbar__secondary-nav {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  .searchBar {
    margin-right: 12px;
  }
  @media (max-width: 876) {
    width: auto;
  }
}

.consistentTopbar__navListItem {
  color: $dark-60;
  list-style: none;
  // flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    text-decoration: underline;
    font-size: 1.25em;
  }
  span {
    color: $link;
    text-decoration-color: $link;
  }
  &.right {
    justify-content: flex-start;
    padding-left: 4px;
    padding-right: 4px;
    margin-right: 8px;
    min-width: 140px;
    @media (max-width: 1200px) {
      min-width: unset;
      .consistentTopbar__credits-upgrade {
        display: none;
      }
      // position: absolute;
      // right: 30px;
      // top: 68px;
      // border-radius: 18px;
      // background-color: $white;
      // box-shadow: -2px 4px 4px 0px #ccc;
      // padding: 4px 12px 4px 4px;
    }
    // flex: 0;

    // @media (max-width: 1000px) {
    //   min-width: 60px;
    // }
    // @media (max-width: 678px) {
    //   padding-left: 6px;
    // }
  }
  &.middle {
    flex: 3;

    &.isCreditsShowing {
      .consistentTopbar__companyWrapper {
        margin-left: -160px;
        @media (max-width: 1080px) {
          margin-left: -132px;
        }
      }
    }

    @media (max-width: 768px) {
      padding-left: 20px;
      flex: 2;
      align-self: flex-end;
      h2 {
        font-size: 1.2em;
      }
    }
    @media (max-width: 568px) {
      flex: 1.5;
    }
  }
}

.consistentTopbar__credits {
  display: flex;
  cursor: help;
  &:hover {
    .consistentTopbar__credits-icon {
      svg {
        color: $mint--light;
      }
      h4 {
        color: $mint--light;
      }
    }
    .consistentTopbar__credits-help {
      svg {
        color: $red--light-1;
      }
    }
  }
  &-icon {
    height: 30px;
    width: 30px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: $mint;
      font-size: 22px;
    }
    h4 {
      position: absolute;
      font-size: 0.8em;
      margin: 0;
      color: $mint;
    }
  }
  &-popup {
    .dd-menu-items {
      margin-left: 20px;
      margin-top: 0px;
      width: 175px;
      font-size: 14px;
      font-weight: 300;
      ul {
        color: $dark-50;
        border-radius: 2px;
        li {
          padding: 8px;
          cursor: pointer;
          transition: all 0.25s;
          .myBtn--link {
            padding: 0;
            font-size: inherit;
          }
          span {
            color: $red--light;
            font-weight: bolder;
            margin: 0 4px;
          }
          &:hover {
            background-color: $light-90;
          }
          &:active {
            background-color: $light;
          }
        }
        .disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
  }
  &-upgrade {
    padding: 0;
    z-index: 5;
    // @media (max-width: 1000px) {
    //   display: none;
    // }
  }
  &-help {
    transition: 0.05s all linear;
    position: relative;
    top: -4px;
    left: -6px;
    svg {
      position: relative;
      color: $red--light;
      font-size: 12px;
      margin-left: -6px;
      margin-right: 4px;
      top: -4px;
    }
    &:active {
      svg {
        font-size: 16px;
        color: $red--dark;
      }
    }
  }
  @media (max-width: 876px) {
    margin-right: -12px;
  }
}

.consistentTopbar__item {
  list-style: none;
  align-self: center;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  &--borderLeft {
    border-left: thin solid $border;
  }
  @media (max-width: 1450px) {
    padding: 0;
  }
}

.consistentTopbar__logo-left {
  flex: 1;
  text-align: start;
  img {
    width: 36px;
    margin-left: 10px;
    transition: 0.1s all linear;
    cursor: pointer;
    filter: brightness(100%);
    &:hover {
      filter: brightness(0) invert(67%) sepia(66%) saturate(5284%) hue-rotate(161deg)
        brightness(95%) contrast(85%);
    }
  }
}
.consistentTopbar__logo {
  text-align: center;
}
