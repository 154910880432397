@import 'navigation/navigation';
@import 'color/colors';

.FilePreviewer {
  overflow: visible;
  position: relative;
  margin: 0 auto;
  width: 156px;
  height: 80px;
  text-align: center;
  &__wrapper {
    display: flex;
    flex-direction: column;
    // min-width: 80px;
    // min-height: 80px;
    width: fit-content;
    border-radius: 4px;
    align-self: center;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    &.isLinkOnHover {
      cursor: pointer;
      &:hover {
        .FilePreviewer__action.myBtn--link {
          color: $new-primary;
        }
      }
    }
    &.isThumbnailShowing {
      align-self: center;
      margin-top: -4px;
      min-width: 80px;
      min-height: 80px;
      border: thin solid $border;
      &.isLinkOnHover {
        background-color: $white;
        &:hover {
          border: thin solid $link;
          .FilePreviewer__action {
            color: $new-primary;
          }
        }
      }
      .FilePreviewer__action {
        text-align: center;
      }
    }
  }

  &__previewPNG {
    height: 90%;
    max-height: 100px;
    margin-top: 4px;
  }
  &__previewSVG {
    height: 90%;
    max-height: 100px;
    min-width: 60px;
  }

  .bcpr {
    border-radius: 4px;
  }

  .bcpr-PreviewHeader-content {
    background-color: $bg--page;
  }

  .bp-ControlsRoot {
    opacity: 0;
    display: none;
  }
  .bp-container {
    min-width: 80px;
    min-height: 80px;
    .page {
      transform: scale(1.5);
    }
  }

  &__action {
    &.myBtn--link {
      @include text--h4;
      text-align: start;
      padding-top: 2px;
      padding-left: 0;
      cursor: pointer;
      margin: 0 auto;
      &:hover {
        color: $new-primary;
      }
    }
    &.myBtn--primary {
      min-width: 140px;
    }
  }
  &__label {
    @include text--h4;
    padding-bottom: 4px;
  }

  &__modal {
    position: relative;
    min-height: 400px;
    outline: none;
    border: none;
    box-shadow: 3px 3px 3px 3px #33373859;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    background-color: $bg--page;
    overflow: visible;
    margin: 20px auto;
    width: 604px;
    height: 800px;
    max-width: 95vw;
    max-height: 95vh;
    &.isError {
      height: 400px;
    }
    &Overlay {
      z-index: 9;
      background-color: rgba(0, 0, 0, 0.66);
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      display: flex;
      overflow-y: auto;
      -webkit-overflow-scrolling-: 'touch';
    }
    &Error {
      @include text--h2;
      margin: 20px 0;
      text-align: center;
      svg {
        color: $warning;
        margin-right: 4px;
      }
    }
    .react-pdf__message--error {
      @include text--h2;
      margin: 0 30px;
    }
    &Header {
      @include text--h2;
      color: $text--light;
      max-width: 380px;
      margin: 0 auto;
      padding: 8px 24px;
      text-align: center;
    }
    &Exit {
      position: absolute;
      align-self: flex-end;
      right: 16px;
      top: 8px;
      font-size: 1.25em;
      color: $primary;
      cursor: pointer;
      &:hover {
        color: $primary--light;
      }
      &:active {
        color: $primary--dark;
      }
      &:focus {
        color: $primary--dark;
      }
    }
    &--readOnly {
      .bp-error [data-testid='preview-error-download-btn'] {
        display: none;
      }
    }
  }
  &--readOnly {
    .bp-error [data-testid='preview-error-download-btn'] {
      display: none;
    }
  }
}

.ReactPDF {
  overflow: visible;
  position: relative;
  margin: 0 auto;
  // width: 156px;
  // height: 80px;

  &__wrapper {
    display: flex;
    justify-content: center;
    background-color: $bg--page;
    border-radius: 4px;
    align-items: center;
    height: 100%;
    .bp-ControlsRoot {
      opacity: 0;
      display: none;
    }
    .bp-container {
      min-width: unset;
    }
    &:hover {
      .ReactPDF__pageControls {
        opacity: 1;
      }
    }
  }
  &__fade {
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 8px;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, $white);
  }

  &__action {
    @include text--h4;
    text-align: center;
    padding-top: 2px;
    padding-left: 0;
    cursor: pointer;
    margin: 0 auto;
    &:hover {
      color: $new-primary;
    }
  }
  &__label {
    @include text--h4;
    padding-bottom: 4px;
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 800px;
    max-width: 600px;
    margin: 12px;
    h3 {
      @include text--h1;
      color: $text--dark-1;
    }
    svg {
      font-size: 20px;
      margin-right: 8px;
    }
    &--thumbnail {
      max-width: 80px;
      h3 {
        @include text--h4;
      }
      svg {
        font-size: 14px;
      }
    }
  }

  .bcpr-PreviewHeader-content {
    background-color: $bg--page;
  }

  .react-pdf {
    &__Document {
      border: thin solid $border--light;
      background-color: $bg--page;
      overflow: auto;
      height: 100%;
      width: 98%;
      transition: max-height 2s ease-in-out 1s;
      position: relative;
      .react-pdf__message--error {
        height: 100%;
        width: 98%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include text--h3;
        color: $text--dark-1;
      }
      /*** Start HIDE SCROLLBAR UNTIL HOVER ***/
      &::-webkit-scrollbar-track {
        visibility: hidden;
      }
      &::-webkit-scrollbar-thumb {
        visibility: hidden;
      }
      &:hover {
        &::-webkit-scrollbar-track {
          visibility: visible;
        }
        &::-webkit-scrollbar-thumb {
          visibility: visible;
        }
      }
      /*** END OF HIDE SCROLLBAR UNTIL HOVER ***/
    }

    &__Page {
      margin: 8px;
      width: fit-content;
      height: fit-content;
    }
  }

  &__pageControls {
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    background: white;
    max-width: 96%;
    opacity: 0;
    width: 300px;
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 4px 10px 0 transparentize(#000000, 0.8);
    border-radius: 4px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $text--dark-1;

    @media (max-height: 840px) {
      position: sticky;
    }

    button {
      width: 52px;
      height: 52px;
      position: relative;
      background: white;
      border: 0;
      font: inherit;
      font-size: 24px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text--dark-1;
      svg {
        font-size: 26px;
      }
      .savvi-tooltip {
        bottom: unset;
        top: -40px;
      }

      &:hover {
        cursor: pointer;
        background-color: #e6e6e6;
        color: $new-primary;
      }
      &:disabled {
        cursor: default;
        background-color: #e6e6e6;
      }

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  &__pageNumbers {
    font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
    flex-grow: 2;
    text-align: center;
  }
}
