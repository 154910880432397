@import 'color/colors';

.address-group {
  &__container {
    width: 100%;
    max-width: 600px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    border-bottom: thin solid $border;
    &--error {
      .address-group__label {
        color: $red;
      }
    }
    .styled-text-field__container,
    .input-select__container {
      margin-top: 0;
    }
  }
  &__label {
    @include text--label;
    margin-bottom: 8px;
    display: flex;
    position: relative;
  }
  &__labelSpan {
    color: $red;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    @media (max-width: 825px) {
      flex-direction: column;
    }
  }

  &__city {
    margin-right: 6px;
    flex: 4.25;
  }

  &__state {
    margin-right: 6px;
    flex: 3.25;
  }

  &__zip {
    flex: 2.25;
  }

  &__country {
    flex: 4;
  }
}
